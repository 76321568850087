import { useContext } from 'react';
import { servContratoCrear, servContratoGenerarPDF, servPNaturalesConsultar, servProveedorAdjuntoConsultar } from '../../utils/servicios';
import { IEndpoint, IContratoCrear, IPNaturalesConsultar } from '../../interfaces/iEndpoints';
import { useForm } from 'react-hook-form'
import {
    Box,
    Button,
    Container,
    Heading,
    useColorModeValue,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Divider,
    VStack,
    Text,
    FormErrorMessage,
    Textarea,
    HStack,
    NumberInput,
    NumberInputField,
    Tooltip,
    Icon,
    Select,
    useToast,
  } from '@chakra-ui/react'
import { consistenciaFechas, esRUTValido, formatRUT, fParametros, limpiarRut, nombrePersona, pctjeMayusculas, podarCGestion, rutFirmaTContrato, tipoPnPjTContrato, tooltipAyuda, validaAdjuntoDeclJuradaProv, validaFechaContrato, validarDatosPersonasPN } from '../../utils/utilidades';
import { useState } from 'react';
import { Stack } from '@chakra-ui/react';
import { K, KApps, KContratos, KV } from '../../utils/constantes';
import { fetchTx } from '../../utils/fetchTx';
import { AuthContext } from '../../context';
import { fetchQS } from '../../utils/fetchQS';
import { TablasContext } from '../../context/TablasContext';
import { fetchQSAdjuntosProv } from '../../utils/fetchQSAdjuntosProv';

const ContratoCrear = ( ) => {

  const { usuario, ambiente } = useContext( AuthContext );
  const { tCentrosGestion, tMonedas, tAyuda, tTContrato, tAplicacionesPermisos, tParametros } = useContext( TablasContext );
  const tCentrosGestionAut = podarCGestion (tCentrosGestion, tAplicacionesPermisos, KApps._PANEL_CONTROL);

  const toast = useToast();
  const [ hayErrorRut, setHayErrorRut ] = useState(false);
  const [ mostrarCrear, setMostrarCrear ] = useState(true);
  
  const { register, handleSubmit, setValue, getValues, formState: { errors, isSubmitting } } = useForm<IContratoCrear>();

  const [ mostrarMensajeError, setMostrarMensajeError ] = useState(false);
  const [ mostrarMensajeOK, setMostrarMensajeOK ] = useState(false);
  const [ mensajeActualizar, setMensajeActualizar ] = useState('');

  const vParametros = fParametros(tParametros);

  const colorLabel        = useColorModeValue("blue.500", "whiteAlpha.800");
  const colorBG           = useColorModeValue("gray.50", "gray.700");
  const colorBG2          = useColorModeValue("gray.200", "gray.900")
  const colorBGErrores    = useColorModeValue("pink.100", "pink.200");
  const colorBGok         = useColorModeValue("green.100", "green.100");
  const colorTextMensajes = useColorModeValue("gray.800", "gray.800");
  const bgInput           = useColorModeValue("white", "gray.600");
  const bgInputRead       = useColorModeValue("blue.50", "gray.700");
  const widthInput        = "40"; 
  const colorTitulo       = useColorModeValue(K._COLOR_TITULO_L, K._COLOR_TITULO_D);
  const colorSeccion      = useColorModeValue(K._COLOR_SECCION_L, K._COLOR_SECCION_D);
  const colorDivider      = useColorModeValue(K._COLOR_DIVIDER_L, K._COLOR_DIVIDER_D);

  let datos:IPNaturalesConsultar|null = null;

  const onSubmit = async ( data: IContratoCrear ) => {

    servPNaturalesConsultar.params = {
        ...servPNaturalesConsultar.params,
        pnat_rut : limpiarRut( data.pnat_rut ),
        pnat_nombre: '',
        pnat_vigente: K._SI,
        cges_id_default_list: '',
      }

    const respPN:IEndpoint = await fetchQS (servPNaturalesConsultar, null, ambiente );

    if (respPN.code === 0) {

      if (respPN.data.length <= 0) {
        setValue('pnat_nombre', 'ERROR' );
        setHayErrorRut(true);

        toast({
          title: KV._CTTO_TITULO_CONSULTA_RUT,
          description: 'ERROR ' + data.pnat_rut,
          status: 'error',
          duration: K._DURACION_TOAST_DEFAULT,
          isClosable: true,
        })
      }
      else {

        const datos = respPN.data[0];

        const msjeErrorFechas = consistenciaFechas (data.ctto_fecha_ppal, data.ctto_fecha_ini, data.ctto_fecha_fin);
        const msjeErrorPersonas = validarDatosPersonasPN (datos);
        const msjeErrores = msjeErrorPersonas + ((msjeErrorPersonas.length > 0)?'\n':'') + msjeErrorFechas;

        const respAdj:IEndpoint = await fetchQSAdjuntosProv ( servProveedorAdjuntoConsultar, ambiente, K._PNPJ_ID_DEFAULT, limpiarRut( data.pnat_rut ), null)
        var bDeclJurada = false;
        if (respAdj.code === 0) {
          const vTContrato = tTContrato.filter (tipocontrato => ( tipocontrato.tcon_tcon_id === data.tcon_id ) );
          const reglasJson = vTContrato[0].tcon_tcon_reglas;
          const reglas = JSON.parse(reglasJson);

          const datosAdj:any = respAdj.data;
          bDeclJurada = validaAdjuntoDeclJuradaProv (limpiarRut(data.pnat_rut), reglas, datosAdj, data.ctto_fecha_ppal);
        }

        if (!bDeclJurada) {
          toast({
            title: KContratos._F_MSJE_TITULO_AVISO,
            description: KContratos._F_MSJE_PNAT_SIN_DECL_JURADA,
            status: 'warning',
            duration: K._DURACION_TOAST_DEFAULT_LARGA,
            isClosable: true,
          })
        }

        const nroInterno = Math.floor(Math.random() * 999999);

        if (msjeErrores.length > 0 ) {
          setMensajeActualizar(`${K._MSJE_ERRORES}\n${msjeErrores}`);
          setMostrarMensajeOK(false);
          setMostrarMensajeError(true);

          toast({
            title: KContratos._F_MSJE_TITULO_FRACASO,
            description: msjeErrores,
            status: 'error',
            duration: K._DURACION_TOAST_DEFAULT,
            isClosable: true,
          })
        }
        else {
          servContratoCrear.params = {
            tcon_id: data.tcon_id,
            pnpj_id: tipoPnPjTContrato (data.tcon_id, tTContrato),
            pnat_rut: limpiarRut (data.pnat_rut),
            pjur_rut: null,
            ctto_fecha_ppal: data.ctto_fecha_ppal,
            cges_id: data.cges_id,
            ctto_nro_interno: nroInterno,
            ctto_nombre: data.ctto_nombre,
            ctto_activ_requerida: null,
            ctto_activ_fecha: null,
            ctto_descripcion: data.ctto_descripcion,
            ctto_observaciones: data.ctto_observaciones,
            ctto_objetivo: data.ctto_objetivo,
            ctto_alcance: null,
            ctto_texto1: null,
            ctto_texto2: null,
            ctto_texto3: null,
            ctto_texto4: null,
            ctto_texto5: null,
            ctto_monto_total: parseFloat (data.ctto_monto_total),
            mone_id: data.mone_id,
            ctto_fecha_texto_sn: K._NO,
            ctto_fecha_descripcion: null,
            ctto_fecha_ini: data.ctto_fecha_ini,
            ctto_fecha_fin: data.ctto_fecha_fin,
            ctto_pago_texto_sn: data.ctto_pago_texto_sn ? K._SI : K._NO,
            ctto_pago_descripcion: data.ctto_pago_descripcion,
            ctto_cantidad_cuotas: parseInt (data.ctto_cantidad_cuotas),
            ctto_unidad_cuotas: data.ctto_unidad_cuotas,
            ctto_monto_cuotas: parseFloat (data.ctto_monto_cuotas),
            ctto_es_renovable: K._NO,
            ctto_es_indefinido: K._NO,
            ctto_msje_interno: data.ctto_msje_interno,
            ctto_msje_a_proveedor: data.ctto_msje_a_proveedor,
            pnat_rut_firma1: null,
            pnat_rut_firma2: null,
            usua_rut_firma1: rutFirmaTContrato (data.tcon_id, 1, tTContrato),
            usua_rut_firma2: rutFirmaTContrato (data.tcon_id, 2, tTContrato),
            usua_id: usuario ? usuario.usua_id : '',
          }

          const resp:IEndpoint = await fetchTx (servContratoCrear, null, ambiente );

          if (resp.code === 0) {

            const d = new Date();
            setMensajeActualizar(`${KContratos._F_MSJE_CREAR_EXITO}${d.toLocaleString()}`);
            setMostrarMensajeOK(true);
            setMostrarMensajeError(false);
            setMostrarCrear(false);

            const ctto_id = resp.data.ctto_id;

            servContratoGenerarPDF.params = {
              nombre_archivo: `${limpiarRut(data.pnat_rut)}_${ctto_id}_${nroInterno}.pdf`,
            }
    
            const respPDF:IEndpoint = await fetchTx (servContratoGenerarPDF, ctto_id, ambiente);
    
            if (respPDF.code !== 0) {
    
              toast({
                title: KContratos._F_MSJE_TITULO_FRACASO,
                description: KContratos._F_MSJE_GENERAR_PDF,
                status: 'error',
                duration: K._DURACION_TOAST_DEFAULT,
                isClosable: true,
              })
            }

            toast({
              title: KContratos._F_MSJE_TITULO_EXITO,
              description: KContratos._F_MSJE_CREAR_EXITO,
              status: 'success',
              duration: K._DURACION_TOAST_DEFAULT,
              isClosable: true,
            })
          }
          else {
            setMensajeActualizar(`${KContratos._F_MSJE_CREAR_FRACASO}\n\n${resp.data}`);
            setMostrarMensajeOK(false);
            setMostrarMensajeError(true);

            toast({
              title: KContratos._F_MSJE_TITULO_FRACASO,
              description: KContratos._F_MSJE_CREAR_FRACASO,
              status: 'error',
              duration: K._DURACION_TOAST_DEFAULT,
              isClosable: true,
            })
          }
        }
      }
    }
    // console.log('______SUBMIT__FormPNaturalEditar___', resp)    
  }

  const handleRUT = (e: React.SyntheticEvent):void => { setValue('pnat_rut', formatRUT(getValues('pnat_rut'))) }

  const onBlurRUT = async (e: React.SyntheticEvent) => { 

    const rut = limpiarRut(getValues('pnat_rut'));
    const msjeError  = KV._CTTO_MSJE_RUT_NO_EXISTE;
    const msjeError2 = KV._CTTO_MSJE_RUT_ERROR;
    const msjeError3 = KV._CTTO_MSJE_RUT_VACIO;

    if ((!rut) || (rut.length <= 0)) {
      setValue('pnat_nombre', msjeError3)
      setHayErrorRut(true);

      toast({
        title: KV._CTTO_TITULO_CONSULTA_RUT,
        description: msjeError3,
        status: 'error',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })
    }
    else {
      servPNaturalesConsultar.params = {
        ...servPNaturalesConsultar.params,
        pnat_rut : rut,
        pnat_nombre: '',
        pnat_vigente: K._SI,
        cges_id_default_list: '',
      }

      const resp:IEndpoint = await fetchQS (servPNaturalesConsultar, null, ambiente );

      if (resp.code === 0) {
        if (resp.data.length <= 0) {
          setValue('pnat_nombre', msjeError );
          setHayErrorRut(true);

          toast({
            title: KV._CTTO_TITULO_CONSULTA_RUT,
            description: msjeError,
            status: 'error',
            duration: K._DURACION_TOAST_DEFAULT,
            isClosable: true,
          })

        }
        else {
          datos = resp.data[0];
          setValue('pnat_nombre', nombrePersona( (datos && datos.pnat_pnat_nombre1), (datos && datos.pnat_pnat_nombre2), (datos && datos.pnat_pnat_apellido1), (datos && datos.pnat_pnat_apellido2) ) );

          const msjeErrorPersonas = validarDatosPersonasPN (datos);
          if (msjeErrorPersonas.length > 0 ) {
            toast({
              title: KV._CTTO_TITULO_CONSULTA_RUT,
              description: msjeErrorPersonas,
              status: 'error',
              duration: K._DURACION_TOAST_DEFAULT,
              isClosable: true,
            })
            setHayErrorRut(true);
          }
          else {
            setHayErrorRut(false);
          }

        }
      }
      else {
        setValue('pnat_nombre', msjeError2)
        setHayErrorRut(true);

        toast({
          title: KV._CTTO_TITULO_CONSULTA_RUT,
          description: msjeError2,
          status: 'error',
          duration: K._DURACION_TOAST_DEFAULT,
          isClosable: true,
        })
      }
    }
  }

  return (

  <Container 
    py={{ base: '1', md: '2' }} 
    px={{ base: '1', md: '2' }} 
    maxW='6xl' 
    bg={colorBG2}
  >
    <Flex 
      direction={{ base: 'column', md: 'row' }}
      overflowY="auto"
      bg={colorBG}
      py={{ base: '0', md: '0' }} 
      borderRadius={{ base: 'none', sm: 'xl' }}
    >
      <Box
        bg={colorBG}
        w='4xl' 
        p='6'
      >
        <VStack>
          <Heading size='md' p={0} color={colorTitulo}>{KContratos._F_TITULO_NUEVO}</Heading>

          <form onSubmit={ handleSubmit(onSubmit) } >

            <Stack bg={colorBG} spacing='1'>

              { /* ---------------------------------------------------------------------------------------- */ }
              { /* _SECCION_I ----------------------------------------------------------------------------- */ }
              { /* ---------------------------------------------------------------------------------------- */ }

              <HStack p="2">
                <Divider bg={colorDivider} />
                  <Text fontSize="lg" fontWeight="medium" whiteSpace="nowrap" color={colorSeccion} >
                    {KContratos._SECCION_I}
                  </Text>
                <Divider bg={colorDivider} />
              </HStack>

              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing={{ base: '1.5', md: '8' }}
                isInline={true}
              >
                { /* pnat_rut ----------------------------------------------------------- */ }
                <FormControl isInvalid={errors.pnat_rut?true:false}>
                  <FormLabel fontSize="xs" color={colorLabel}>{KContratos._F_RUT_PROV}</FormLabel>
                  <Input 
                    bg={bgInput}
                    w={widthInput}
                    defaultValue={''} 
                    { ...register('pnat_rut', 
                      { 
                        required: KV._CAMPO_OBL, 
                        minLength: { value: vParametros.ctto_rut_lmin, message: `${KV._LARGO_MIN} ${vParametros.ctto_rut_lmin}` },
                        maxLength: { value: vParametros.ctto_rut_lmax, message: `${KV._LARGO_MAX} ${vParametros.ctto_rut_lmax}` },
                        onChange: handleRUT,
                        onBlur: onBlurRUT,
                        validate: {
                          digito: (v) => esRUTValido(v) || KV._RUT_INVALIDO
                        },
                      })
                    } 
                  />
                  <FormErrorMessage fontSize="10">
                    {errors.pnat_rut && errors.pnat_rut.message}
                  </FormErrorMessage>
                </FormControl>

                { /* pnat_pnat_nombre1... ----------------------------------------------------------- */ }
                <FormControl>
                  <FormLabel color={colorLabel} fontSize="xs">{KContratos._F_NOMBRE_PROV}</FormLabel>
                  <Input 
                    bg={ hayErrorRut ? colorBGErrores : bgInputRead }
                    w="xl"
                    readOnly={true}
                    { ...register('pnat_nombre', )} 
                  />
                </FormControl>
              </Stack>


              { /* C: GESTION + TIPO_CONTRATO + NRO INTERNO */ }
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing={{ base: '1.5', md: '8' }}

              >
                { /* cges_id ----------------------------------------------------------- */ }
                <FormControl isInvalid={errors.cges_id?true:false}>
                  <FormLabel fontSize="xs" color={colorLabel}>{KContratos._C_CGESTION}</FormLabel>
                  <Select 
                    defaultValue={K._SELE_ID} 
                    bg={bgInput}
                    {...register('cges_id', {
                      validate: {
                        seleccionar: (c) => (c !== K._SELE_ID) || `${KV._MSJE_SELECCIONE} ${KContratos._C_CGESTION} `
                      }})
                    }
                  >
                    <option key={K._SELE_ID} value={K._SELE_ID}>{`${K._SELE_TEXTO}`}</option>
                    {
                      tCentrosGestionAut.map( cgestion => (
                        <option key={cgestion.cges_cges_id} value={cgestion.cges_cges_id} >{cgestion.cges_cges_nombre}</option>
                      ))
                    }  
                  </Select>
                  <FormErrorMessage fontSize="10">
                    {errors.cges_id && errors.cges_id.message}
                  </FormErrorMessage>
                </FormControl>

                { /* tcon_id ----------------------------------------------------------- */ }
                <FormControl isInvalid={errors.tcon_id?true:false}>
                  <FormLabel fontSize="xs" color={colorLabel}>{KContratos._F_TIPO_CTTO}</FormLabel>
                  <Select 
                    defaultValue={K._SELE_ID} 
                    bg={bgInput}
                    {...register('tcon_id', {
                      validate: {
                        seleccionar: (c) => (c !== K._SELE_ID) || `${KV._MSJE_SELECCIONE} ${KContratos._F_TIPO_CTTO}`
                      }})
                    }
                  >
                    <option key={K._SELE_ID} value={K._SELE_ID}>{`${K._SELE_TEXTO}`}</option>
                    {
                      tTContrato.map( tipo => (
                        <option key={tipo.tcon_tcon_id} value={tipo.tcon_tcon_id} >{tipo.tcon_tcon_nombre}</option>
                      ))
                    }  
                  </Select>
                  <FormErrorMessage fontSize="10">
                    {errors.tcon_id && errors.tcon_id.message}
                  </FormErrorMessage>
                </FormControl>

              </Stack>

            { /* ---------------------------------------------------------------------------------------- */ }
            { /* _SECCION_II ---------------------------------------------------------------------------- */ }
            { /* ---------------------------------------------------------------------------------------- */ }

            <HStack p="5">
              <Divider bg={colorDivider} />
                <Text fontSize="lg" fontWeight="medium" whiteSpace="nowrap" color={colorSeccion} >
                  {KContratos._SECCION_II}
                </Text>
              <Divider bg={colorDivider} />
            </HStack>

            { /* ctto_nombre ----------------------------------------------------------- */ }
            <FormControl isInvalid={errors.ctto_nombre?true:false}>
              <FormLabel fontSize="xs" color={colorLabel}>
                <HStack>
                  <Text>{KContratos._F_NOMBRE}</Text>
                  <Tooltip label={tooltipAyuda('ctto_nombre', tAyuda)}><Icon /></Tooltip>
                </HStack>
              </FormLabel>
              <Input 
                bg={bgInput}
                { ...register('ctto_nombre', 
                  { 
                    required: KV._CAMPO_OBL, 
                    minLength: { value: vParametros.ctto_nombre_lmin, message: `${KV._LARGO_MIN} ${vParametros.ctto_nombre_lmin}` },
                    maxLength: { value: vParametros.ctto_nombre_lmax, message: `${KV._LARGO_MAX} ${vParametros.ctto_nombre_lmax}` },
                    validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                  })
                } 
              />
              <FormErrorMessage fontSize="10">
                {errors.ctto_nombre && errors.ctto_nombre.message}
              </FormErrorMessage>
            </FormControl>

            { /* ctto_objetivo ----------------------------------------------------------- */ }
            <FormControl isInvalid={errors.ctto_objetivo?true:false}>
              <FormLabel fontSize="xs" color={colorLabel}>
                <HStack>
                  <Text>{KContratos._F_OBJETIVO}</Text>
                  <Tooltip label={tooltipAyuda('ctto_objetivo', tAyuda)} w={600}><Icon /></Tooltip>
                </HStack>
              </FormLabel>
              <Textarea 
                bg={bgInput}
                { ...register('ctto_objetivo', 
                  { 
                    required: KV._CAMPO_OBL, 
                    minLength: { value: vParametros.ctto_objetivo_lmin, message: `${KV._LARGO_MIN} ${vParametros.ctto_objetivo_lmin}` },
                    maxLength: { value: vParametros.ctto_objetivo_lmax, message: `${KV._LARGO_MAX} ${vParametros.ctto_objetivo_lmax}` },
                    validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                  })
                } 
              />
              <FormErrorMessage fontSize="10">
                {errors.ctto_objetivo && errors.ctto_objetivo.message}
              </FormErrorMessage>
            </FormControl>

            { /* ctto_descripcion ----------------------------------------------------------- */ }
            <FormControl isInvalid={errors.ctto_descripcion?true:false}>
              <FormLabel fontSize="xs" color={colorLabel}>
                <HStack>
                  <Text>{KContratos._F_DESCRIPCION}</Text>
                  <Tooltip label={tooltipAyuda('ctto_descripcion', tAyuda)}><Icon /></Tooltip>
                </HStack>
              </FormLabel>
              <Textarea 
                bg={bgInput} 
                h={"52"}
                { ...register('ctto_descripcion', 
                  { 
                    required: KV._CAMPO_OBL, 
                    minLength: { value: vParametros.ctto_descripcion_lmin, message: `${KV._LARGO_MIN} ${vParametros.ctto_descripcion_lmin}` },
                    maxLength: { value: vParametros.ctto_descripcion_lmax, message: `${KV._LARGO_MAX} ${vParametros.ctto_descripcion_lmax}` },
                    validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                  })
                } 
              />
              <FormErrorMessage fontSize="10">
                {errors.ctto_descripcion && errors.ctto_descripcion.message}
              </FormErrorMessage>
            </FormControl>
            

            { /* ---------------------------------------------------------------------------------------- */ }
            { /* SECCION_III ---------------------------------------------------------------------------- */ }
            { /* ---------------------------------------------------------------------------------------- */ }

            <HStack p="5">
              <Divider bg={colorDivider} />
                <Text fontSize="lg" fontWeight="medium" whiteSpace="nowrap" color={colorSeccion} >
                  {KContratos._SECCION_III}
                </Text>
              <Divider bg={colorDivider} />
            </HStack>

            { /* MONEDA + MONTO + FECHA_INICIO + FECHA_TERMINO */ }
            <Stack
              direction={{ base: 'column', md: 'row' }}
              spacing={{ base: '1.5', md: '8' }}
              isInline={true}
            >

              { /* ctto_fecha_ppal ----------------------------------------------------------- */ }
              <FormControl isInvalid={errors.ctto_fecha_ppal?true:false}>
                <FormLabel fontSize="xs" color={colorLabel}>
                  <HStack>
                    <Text>{KContratos._F_FECHA_PPAL}</Text>
                    <Tooltip label={tooltipAyuda('ctto_fecha_ppal', tAyuda)}><Icon /></Tooltip>
                  </HStack>
                </FormLabel>
                <Input 
                  bg={bgInput}
                  w={widthInput}
                  type='date'
                  { ...register('ctto_fecha_ppal', 
                    { 
                      required: KV._CAMPO_OBL, 
                      minLength: { value: KV._CTTO_FECHA_LMIN, message: `${KV._LARGO_MIN} ${KV._CTTO_FECHA_LMIN}` },
                      maxLength: { value: KV._CTTO_FECHA_LMAX, message: `${KV._LARGO_MAX} ${KV._CTTO_FECHA_LMAX}` },
                      validate: {
                        fechaContrato: (v) => validaFechaContrato(v, vParametros.ctto_fecha_ppal_dias_atras, vParametros.ctto_fecha_ppal_dias_adelante) || `${KV._CTTO_MSJE_FECHA_PPAL} [${vParametros.ctto_fecha_ppal_dias_atras}/${vParametros.ctto_fecha_ppal_dias_adelante}]`
                      }
                    })
                  } 
                />
                <FormErrorMessage fontSize="10">
                  {errors.ctto_fecha_ppal && errors.ctto_fecha_ppal.message}
                </FormErrorMessage>                
              </FormControl>

              { /* mone_id ----------------------------------------------------------- */ }
              <FormControl isInvalid={errors.mone_id?true:false}>
                <FormLabel fontSize="xs" color={colorLabel}>{KContratos._F_MONEDA}</FormLabel>
                <Select 
                  bg={bgInput}
                  {...register('mone_id', {
                    validate: {
                      seleccionar: (c) => (c !== K._SELE_ID) || `${KV._MSJE_SELECCIONE} ${KContratos._F_MONEDA}`
                  },})} 
                >
                  {
                    tMonedas.map( moneda => (
                      <option key={moneda.tabl_tabl_elemento} value={moneda.tabl_tabl_elemento} >{moneda.tabl_tabl_nombre}</option>
                    ))
                  }  
                </Select>
                <FormErrorMessage fontSize="10">
                  {errors.mone_id && errors.mone_id.message}
                </FormErrorMessage>
              </FormControl>

              { /* ctto_monto_total ----------------------------------------------------------- */ }
              <FormControl isInvalid={errors.ctto_monto_total?true:false}>
                <FormLabel fontSize="xs" color={colorLabel}>
                  <HStack>
                    <Text>{KContratos._F_MONTO}</Text>
                    <Tooltip label={tooltipAyuda('ctto_monto_total', tAyuda)}><Icon /></Tooltip>
                  </HStack>
                </FormLabel>
                <NumberInput  
                  min={1} 
                  bg={bgInput}
                  w={widthInput}
                  clampValueOnBlur={false}
                >
                  <NumberInputField 
                    { ...register('ctto_monto_total', 
                      { 
                        required: KV._CAMPO_OBL, 
                        validate: {
                          mayorCero: (v) => (parseFloat(v) > 0) || KV._CTTO_MSJE_MONTO_TOTAL_M0
                        }
                      })
                    } 
                  />
                </NumberInput>
                <FormErrorMessage fontSize="10">
                  {errors.ctto_monto_total && errors.ctto_monto_total.message}
                </FormErrorMessage>                
              </FormControl>
              
              { /* ctto_fecha_ini ----------------------------------------------------------- */ }
              <FormControl isInvalid={errors.ctto_fecha_ini?true:false}>
                <FormLabel fontSize="xs" color={colorLabel}>
                  <HStack>
                    <Text>{KContratos._F_FECHA_INI}</Text>
                    <Tooltip label={tooltipAyuda('ctto_fecha_ini', tAyuda)}><Icon /></Tooltip>
                  </HStack>
                </FormLabel>
                <Input 
                  bg={bgInput}
                  w={widthInput}
                  type='date'
                  { ...register('ctto_fecha_ini', 
                    { 
                      required: KV._CAMPO_OBL, 
                      minLength: { value: KV._CTTO_FECHA_LMIN, message: `${KV._LARGO_MIN} ${KV._CTTO_FECHA_LMIN}` },
                      maxLength: { value: KV._CTTO_FECHA_LMAX, message: `${KV._LARGO_MAX} ${KV._CTTO_FECHA_LMAX}` },
                      validate: {
                        fechaIni: (v) => validaFechaContrato(v, vParametros.ctto_fecha_ini_dias_atras, vParametros.ctto_fecha_ini_dias_adelante) || KV._CTTO_MSJE_FECHA_INI
                      }
                    })
                  }
                  />
                <FormErrorMessage fontSize="10">
                  {errors.ctto_fecha_ini && errors.ctto_fecha_ini.message}
                </FormErrorMessage>                
              </FormControl>
              
              { /* ctto_fecha_fin ----------------------------------------------------------- */ }
              <FormControl isInvalid={errors.ctto_fecha_fin?true:false}>
                <FormLabel fontSize="xs" color={colorLabel}>
                  <HStack>
                    <Text>{KContratos._F_FECHA_FIN}</Text>
                    <Tooltip label={tooltipAyuda('ctto_fecha_fin', tAyuda)}><Icon /></Tooltip>
                  </HStack>
                </FormLabel>
                <Input 
                  bg={bgInput}
                  w={widthInput}
                  type='date'
                  { ...register('ctto_fecha_fin', 
                    { 
                      required: KV._CAMPO_OBL, 
                      minLength: { value: KV._CTTO_FECHA_LMIN, message: `${KV._LARGO_MIN} ${KV._CTTO_FECHA_LMIN}` },
                      maxLength: { value: KV._CTTO_FECHA_LMAX, message: `${KV._LARGO_MAX} ${KV._CTTO_FECHA_LMAX}` },
                      validate: {
                        fechaFin: (v) => validaFechaContrato(v, vParametros.ctto_fecha_fin_dias_atras, vParametros.ctto_fecha_fin_dias_adelante) || KV._CTTO_MSJE_FECHA_FIN
                      }
                    })
                  }
                />
                <FormErrorMessage fontSize="10">
                  {errors.ctto_fecha_fin && errors.ctto_fecha_fin.message}
                </FormErrorMessage>                
              </FormControl>

            </Stack>

            <Stack
              direction={{ base: 'column', md: 'row' }}
              spacing={{ base: '1.5', md: '8' }}
              isInline={true}
            >

              { /* ctto_pago_descripcion  ----------------------------------------------------------- */ }
              <FormControl isInvalid={errors.ctto_pago_descripcion?true:false}>
                <FormLabel fontSize="xs" color={colorLabel}>
                  <HStack>
                    <Text>{KContratos._F_PAGO_GLOSA}</Text>
                    <Tooltip label={tooltipAyuda('ctto_pago_descripcion', tAyuda)}><Icon /></Tooltip>
                  </HStack>
                </FormLabel>
                <Textarea 
                  bg={bgInput} 
                  { ...register('ctto_pago_descripcion', 
                    { 
                      // required: KV._CAMPO_OBL, 
                      // minLength: { value: KV._CTTO_DESC_PAGO_LMIN, message: `${KV._LARGO_MIN} ${KV._CTTO_DESC_PAGO_LMIN}` },
                      maxLength: { value: vParametros.ctto_desc_pago_lmax, message: `${KV._LARGO_MAX} ${vParametros.ctto_desc_pago_lmax}` },
                      validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                    })
                  }
                />
                <FormErrorMessage fontSize="10">
                  {errors.ctto_pago_descripcion && errors.ctto_pago_descripcion.message}
                </FormErrorMessage>                
              </FormControl>
            </Stack>

            { /* ---------------------------------------------------------------------------------------- */ }
            { /* ---------------------------------------------------------------------------------------- */ }
            { /* ---------------------------------------------------------------------------------------- */ }

            <HStack p="5">
              <Divider bg={colorDivider} />
                <Text fontSize="lg" fontWeight="medium" whiteSpace="nowrap" color={colorSeccion} >
                  {KContratos._SECCION_IV}
                </Text>
              <Divider bg={colorDivider} />
            </HStack>

            <Stack
              direction={{ base: 'column', md: 'row' }}
              spacing={{ base: '1.5', md: '8' }}
              isInline={true}
            >
              { /* ctto_observaciones ----------------------------------------------------------- */ }
              <FormControl isInvalid={errors.ctto_observaciones?true:false}>
                <FormLabel fontSize="xs" color={colorLabel} >
                  <HStack>
                    <Text>{KContratos._F_OBSERVACIONES}</Text>
                    <Tooltip label={tooltipAyuda('ctto_observaciones', tAyuda)}><Icon /></Tooltip>
                  </HStack>
                </FormLabel>
                <Textarea 
                  bg={bgInput} 
                  size='sm' 
                  { ...register('ctto_observaciones', 
                    { 
                      maxLength: { value: vParametros.ctto_observaciones_lmax, message: `${KV._LARGO_MAX} ${vParametros.ctto_observaciones_lmax}` },
                      validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                    })
                  }
                />
                <FormErrorMessage fontSize="10">
                  {errors.ctto_observaciones && errors.ctto_observaciones.message}
                </FormErrorMessage>                
              </FormControl>
            
              { /* ctto_msje_interno ----------------------------------------------------------- */ }
              <FormControl isInvalid={errors.ctto_msje_interno?true:false}>
                <FormLabel fontSize="xs" color={colorLabel} >
                  <HStack>
                    <Text>{KContratos._F_MSJE_INT_CCV}</Text>
                    <Tooltip label={tooltipAyuda('ctto_msje_interno', tAyuda)}><Icon /></Tooltip>
                  </HStack>
                </FormLabel>
                <Textarea 
                  bg={bgInput} 
                  size='sm' 
                  { ...register('ctto_msje_interno', 
                    { 
                      maxLength: { value: vParametros.ctto_mensajes_lmax, message: `${KV._LARGO_MAX} ${vParametros.ctto_mensajes_lmax}` },
                      validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                    })
                  }
                />
                <FormErrorMessage fontSize="10">
                  {errors.ctto_msje_interno && errors.ctto_msje_interno.message}
                </FormErrorMessage>                
              </FormControl>

              { /* ctto_msje_a_proveedor ----------------------------------------------------------- */ }
              <FormControl isInvalid={errors.ctto_msje_a_proveedor?true:false}>
                <FormLabel fontSize="xs" color={colorLabel} >
                  <HStack>
                    <Text>{KContratos._F_MSJE_A_PROV}</Text>
                    <Tooltip label={tooltipAyuda('ctto_msje_a_proveedor', tAyuda)}><Icon /></Tooltip>
                  </HStack>
                </FormLabel>
                <Textarea 
                  bg={bgInput} 
                  size='sm' 
                  { ...register('ctto_msje_a_proveedor', 
                    { 
                      maxLength: { value: vParametros.ctto_mensajes_lmax, message: `${KV._LARGO_MAX} ${vParametros.ctto_mensajes_lmax}` },
                      validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                    })
                  }
                />
                <FormErrorMessage fontSize="10">
                  {errors.ctto_msje_a_proveedor && errors.ctto_msje_a_proveedor.message}
                </FormErrorMessage>                
              </FormControl>
              

            </Stack>

              {
                mostrarCrear
                ?
                <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
                  <Button colorScheme='green' variant='outline' type="submit" isLoading={isSubmitting}>
                  {KContratos._F_BOTON_CREAR}
                  </Button>
                </Flex>
                :
                null
              }

              {
                <>
                  { mostrarMensajeOK && <Textarea readOnly={true} bg={colorBGok} color={colorTextMensajes} value={`${mensajeActualizar}`} /> }
                  { mostrarMensajeError && <Textarea isInvalid readOnly={true} bg={colorBGErrores} color={colorTextMensajes} value={`${mensajeActualizar}`} h={120}/> }
                </>
              }

            </Stack>

          </form>
      </VStack>
      </Box>
    </Flex>
  </Container>
)
}

export default ContratoCrear;