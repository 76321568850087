import { FC, useState, useContext, useRef } from 'react';
import { IContratoConsultar } from '../../interfaces/iEndpoints'
import {
  AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react'
import { difDias, formatFecha, formatMonto, formatRUT, nombrePersonaNatural, ordenarMatriz, truncarTexto } from '../../utils/utilidades';
import { FiEdit } from 'react-icons/fi';
import { Link as LinkRouter } from 'react-router-dom';
import { K, KContratos } from '../../utils/constantes';
import { IconDescargar4, IconUpDown } from '../../utils/Icons';
import MisPendientesDescargar from './MisPendientesDescargar';
import { AuthContext } from '../../context';
import { fetchDescargarContrato } from '../../utils/fetchDescargarContrato';
import { servContratoDescargar } from '../../utils/servicios';

interface Props {
  data: any,
}

const MisPendientesRspta: FC<Props> = ( {data} ) => {

  const { ambiente } = useContext( AuthContext );
  const cancelRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [ asc, setAsc ] = useState (false);
  const [ descargando, setDescargando ] = useState ('');
  const [ descarga, setDescarga ] = useState (1);
  
  const [ orden, setOrden ] = useState (KContratos._MP_ORDEN_FECHA_PDTE);
  const colorLink = useColorModeValue(K._COLOR_LINK_L, K._COLOR_LINK_D);
  const colorTH = useColorModeValue(K._COLOR_TH_L, K._COLOR_TH_D);

  const datos = ordenarMatriz (data, orden, asc);

  const onCLickOrden = (e: React.SyntheticEvent):void => {
    setAsc(!asc);
    setOrden(e.currentTarget.id);
  }

  const onClickDescargarBoton  = (e: React.SyntheticEvent):void => {
    setDescargando(`¿Deseas descargar ${data.length} contratos?`);
    onOpen()
  }

  const onClickDescargarMasiva  = (e: React.SyntheticEvent):void => {

    const datos:IContratoConsultar[] = data;
    
    datos.forEach((element, index) => {

      if (element.bita_id !== '') {
        fetchDescargarContrato (servContratoDescargar, element.ctto_ctto_id, element.bita_id, element.bita_pdf, ambiente)
        .then ( resp => { 
          if (resp.code !== 0) { 
            setDescargando(`Error en ${index}... ${element.bita_pdf}`);
          }
          else {
            setDescarga(descarga+1)
            setDescargando(`Descargando contrato ${element.ctto_ctto_id}... ${element.bita_pdf}`);
          }
        })
        .catch ( (error) => {
          setDescargando(`Error en ${index}... ${element.bita_pdf}`);
        })
      }
      else {
        setDescargando(`No hay contratos pendientes para descargar`);
      }
    });
  }
  
  return(
    <TableContainer  >
      <Table colorScheme='teal' size="sm">
          <Thead>
            <Tr bg={colorTH} >
              <Th colSpan={2}></Th>
              <Th>
                <Button onClick={onClickDescargarBoton} colorScheme='teal' variant='link' leftIcon={<IconDescargar4/>} />
              </Th>
              <Th>{<Button onClick={onCLickOrden} variant='outline' rightIcon={<IconUpDown/>} size="md" id={KContratos._MP_ORDEN_CTTO_ID}>{KContratos._MP_ID}</Button>}</Th>
              <Th>{<Button onClick={onCLickOrden} variant='outline' rightIcon={<IconUpDown/>} size="md" id={KContratos._MP_ORDEN_FECHA_PDTE}>{KContratos._MP_FECHA_PDTE}</Button>}</Th>
              <Th>{KContratos._MP_PLAZO}</Th>
              <Th>{<Button onClick={onCLickOrden} variant='outline' rightIcon={<IconUpDown/>} size="md" id={KContratos._MP_ORDEN_CGESTION}>{KContratos._MP_CGESTION}</Button>}</Th>
              <Th>{<Button onClick={onCLickOrden} variant='outline' rightIcon={<IconUpDown/>} size="md" id={KContratos._MP_ORDEN_RUT}>{KContratos._MP_RUT_PROV}</Button>}</Th>
              <Th>{<Button onClick={onCLickOrden} variant='outline' rightIcon={<IconUpDown/>} size="md" id={KContratos._MP_ORDEN_NOMBRE}>{KContratos._MP_NOMBRE_PROV}</Button>}</Th>
              <Th>{KContratos._MP_NOMBRE_CTTO}</Th>
              <Th>{KContratos._MP_MONTO}</Th>
              <Th>{KContratos._MP_ESTADO}</Th>
            </Tr>
          </Thead>
          <Tbody>
          {
            (
              ( (!datos) || (datos.length <= 0) )
              ?
              (<Tr key={1}>
                <Td colSpan={5}>
                  <h1>{K._NOHAYPDTES}</h1>
                </Td>
              </Tr>)
              :
              (
                datos.map((fila:IContratoConsultar, index:number) => (
                  (
                    <Tr key={index}>
                        <Td>{index+1}</Td>
                        <Td><LinkRouter to={`/form-contrato/${fila.ctto_ctto_id}`}><FiEdit fontSize="1.0rem" color={colorLink}/></LinkRouter></Td>
                        <Td><MisPendientesDescargar id={fila.ctto_ctto_id} datos={fila} ambiente={ambiente}/></Td>
                        <Td>{fila.ctto_ctto_id}</Td>
                        <Td>{formatFecha(fila.ctto_ctto_fecha_estado_actual, true)}</Td>
                        <Td>{difDias(fila.ctto_ctto_fecha_estado_actual)}</Td>
                        <Td>{fila.cges_cges_nombre}</Td>
                        <Td isNumeric={true}>{formatRUT( fila.pnat_pnat_rut)}</Td>
                        <Td>{nombrePersonaNatural(fila.pnat_pnat_nombre1, fila.pnat_pnat_apellido1, fila.pnat_pnat_apellido2)}</Td>
                        <Td>{truncarTexto(fila.ctto_ctto_nombre, K._LARGO_TEXT_CONSULTAS)}</Td>
                        <Td isNumeric={true}>{formatMonto( fila.ctto_mone_id, parseFloat(fila.ctto_ctto_monto_total), 2)}</Td>
                        <Td>{fila.esta_esta_nombre}</Td>
                    </Tr>
                  )
                )
              )
              )
            )}
          </Tbody>
        </Table>

        <AlertDialog
          motionPreset='slideInBottom'
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isOpen={isOpen}
          isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>
            {`Confirmación descarga masiva`}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            {descargando}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              {KContratos._F_BOTON_CONFIRMAR_CERRAR}
            </Button>
            <Button 
              colorScheme='green' 
              ml={3} 
              onClick={onClickDescargarMasiva}
              >
              {KContratos._F_BOTON_CONFIRMAR_ACEPTAR}
            </Button>
            </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>  
      </TableContainer>
    )
}

export default MisPendientesRspta;