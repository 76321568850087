import { FC, useState } from 'react';
import { IContratoConsultar } from '../../interfaces/iEndpoints'
import {
    Button,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from '@chakra-ui/react'
import { difDias, formatFecha, formatMonto, formatRUT, nombrePersonaNatural, ordenarMatriz, truncarTexto } from '../../utils/utilidades';
import { FiEdit } from 'react-icons/fi';
import { Link as LinkRouter } from 'react-router-dom';
import { K, KContratos } from '../../utils/constantes';
import { IconUpDown } from '../../utils/Icons';

interface Props {
  data: any,
}

const PendientesFirmaRspta: FC<Props> = ( {data} ) => {

  const [ asc, setAsc ] = useState (false);
  const [ orden, setOrden ] = useState (KContratos._MP_ORDEN_FECHA_PDTE);
  const colorLink = useColorModeValue(K._COLOR_LINK_L, K._COLOR_LINK_D);
  const colorNroLinea = useColorModeValue(K._COLOR_NROLINEA_L, K._COLOR_NROLINEA_D);
  const colorTH = useColorModeValue(K._COLOR_TH_L, K._COLOR_TH_D);

  const datos = ordenarMatriz (data, orden, asc);

  const onCLickOrden = (e: React.SyntheticEvent):void => {
    setAsc(!asc);
    setOrden(e.currentTarget.id);
  }

  return(
    <TableContainer  >
      <Table colorScheme='teal' size="sm">
          <Thead>
            <Tr bg={colorTH} >
              <Th colSpan={2}></Th>
              <Th>{<Button onClick={onCLickOrden} variant='outline' rightIcon={<IconUpDown/>} size="md" id={KContratos._MP_ORDEN_CTTO_ID}>{KContratos._PF_ID}</Button>}</Th>
              <Th>{<Button onClick={onCLickOrden} variant='outline' rightIcon={<IconUpDown/>} size="md" id={KContratos._MP_ORDEN_FECHA_PDTE}>{KContratos._PF_FECHA_PDTE}</Button>}</Th>
              <Th>{KContratos._PF_PLAZO}</Th>
              <Th>{<Button onClick={onCLickOrden} variant='outline' rightIcon={<IconUpDown/>} size="md" id={KContratos._MP_ORDEN_CGESTION}>{KContratos._PF_CGESTION}</Button>}</Th>
              <Th>{<Button onClick={onCLickOrden} variant='outline' rightIcon={<IconUpDown/>} size="md" id={KContratos._MP_ORDEN_RUT}>{KContratos._PF_RUT_PROV}</Button>}</Th>
              <Th>{<Button onClick={onCLickOrden} variant='outline' rightIcon={<IconUpDown/>} size="md" id={KContratos._MP_ORDEN_NOMBRE}>{KContratos._PF_NOMBRE_PROV}</Button>}</Th>
              <Th>{KContratos._PF_NOMBRE_CTTO}</Th>
              <Th>{KContratos._PF_FECHA_CTTO}</Th>
              <Th>{KContratos._PF_MONTO}</Th>
              <Th>{KContratos._PF_ESTADO}</Th>
            </Tr>
          </Thead>
          <Tbody>
          {
            (
              ( (!datos) || (datos.length <= 0) )
              ?
              (<Tr key={1}>
                <Td colSpan={5}>
                  <h1>{K._NOHAYDATOS}</h1>
                </Td>
              </Tr>)
              :
              (
                datos.map((fila:IContratoConsultar, index:number) => (
                  (
                    <Tr key={index}>
                        <Td color={colorNroLinea}>{index+1}</Td>
                        <Td><LinkRouter to={`/form-contrato/${fila.ctto_ctto_id}`}><FiEdit fontSize="1.0rem" color={colorLink}/></LinkRouter></Td>
                        <Td>{fila.ctto_ctto_id}</Td>
                        <Td>{formatFecha(fila.ctto_ctto_fecha_estado_actual, true)}</Td>
                        <Td>{difDias(fila.ctto_ctto_fecha_estado_actual)}</Td>
                        <Td>{fila.cges_cges_nombre}</Td>
                        <Td isNumeric={true}>{formatRUT( fila.pnat_pnat_rut)}</Td>
                        <Td>{nombrePersonaNatural(fila.pnat_pnat_nombre1, fila.pnat_pnat_apellido1, fila.pnat_pnat_apellido2)}</Td>
                        <Td>{truncarTexto(fila.ctto_ctto_nombre, K._LARGO_TEXT_CONSULTAS)}</Td>
                        <Td>{formatFecha(fila.ctto_ctto_fecha_ppal, true)}</Td>
                        <Td isNumeric={true}>{formatMonto( fila.ctto_mone_id, parseFloat(fila.ctto_ctto_monto_total), 2)}</Td>
                        <Td>{fila.esta_esta_nombre}</Td>
                    </Tr>
                  )
                )
              )
              )
            )}
          </Tbody>
        </Table>
      </TableContainer>
    )
}

export default PendientesFirmaRspta;