import { FC, useState, useEffect } from 'react';
import { useColorModeValue, Tr, Td, TableContainer, Table, Thead, Th, Box, Tbody, Button, Text, useToast } from '@chakra-ui/react';
import { IContratoAdjuntos } from '../../interfaces/iEndpoints';
import { K, KContratos, KErroresDescargarContratoAdjunto } from '../../utils/constantes';
import { fetchQS } from '../../utils/fetchQS';
import { servContratoAdjuntoConsultar, servContratoAdjuntoDescargar } from '../../utils/servicios';
import { formatFecha } from '../../utils/utilidades';
import { fetchDescargarAdjunto } from '../../utils/fetchDescargarAdjunto';

interface Props {
    id: number;
    ambiente: string | undefined;
    actualizar: number;
    setActualizar: React.Dispatch<React.SetStateAction<number>>;
}

const ContratoAdjuntosConsulta: FC<Props> = ({ id, ambiente, actualizar, setActualizar }) => {

  const [ errorDetectado, setErrorDetectado ] = useState('');
  const [ cargando, setCargando ] = useState(false);
  const [ datos, setDatos ] = useState([]);

  const toast       = useToast();

  useEffect(() => {

    fetchQS (servContratoAdjuntoConsultar, id, ambiente)
    .then ( resp => {
      if (resp.code !== 0) {
        setErrorDetectado(KContratos._F_MSJE_ERROR_BITACORA);
      }
      else if (resp.data.length > 0) {
        const datosR:any = resp.data;
        setDatos(datosR);
      } 
      setCargando(false);
    })

    .catch ( (error) => {
        const { message } = JSON.parse(error.message);
        setErrorDetectado(message);
        setCargando(false);
    })    

  }, [actualizar, ambiente, id])
  
  const colorBG = useColorModeValue("white", "gray.700");
  const colorBG2  = useColorModeValue("gray.100", "gray.600")

  const handleOnClick = (e: React.SyntheticEvent):void => {

    fetchDescargarAdjunto (servContratoAdjuntoDescargar, id, e.currentTarget.id, ambiente)
    .then ( resp => {
      if (resp.code !== 0) {
        const errorDescargar = KErroresDescargarContratoAdjunto.filter (elemento => (elemento.codigo ===  resp.code) );
        const errorMensaje = (errorDescargar.length > 0) ? errorDescargar[0].mensaje : `[${resp.code} // ${resp.data}]`;

        toast({
          title: KContratos._AC_MSJE_TITULO_FRACASO,
          description: errorMensaje,
          status: 'error',
          duration: K._DURACION_TOAST_DEFAULT,
          isClosable: true,
        })

      }
      else {
        toast({
          title: KContratos._AC_MSJE_TITULO_EXITO,
          description: KContratos._AC_MSJE_DESCARGAR_EXITO,
          status: 'success',
          duration: K._DURACION_TOAST_DEFAULT,
          isClosable: true,
        })

      }
    })

    .catch ( (error) => {
      setErrorDetectado(error);
      toast({
        title: KContratos._AC_MSJE_TITULO_FRACASO,
        description: KContratos._AC_MSJE_DESCARGAR_FRACASO,
        status: 'error',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })

    })    

  }

  if (errorDetectado) {
    return (    <Text>{errorDetectado}</Text>    )
  }
  else if (cargando) {
    return (    <Button isLoading={cargando} loadingText={K._CARGANDO} colorScheme='teal' variant='outline' />    )
  }
  else {
    return(
      <Box
        bg={colorBG}
        w='3xl' 
        p='0'
      >
        <TableContainer>
          <Table colorScheme='teal' size="sm">
              <Thead>
                <Tr bg={colorBG2} >
                  <Th></Th>
                  <Th>{KContratos._AR_FECHA}</Th>
                  <Th>{KContratos._AR_TIPO}</Th>
                  <Th>{KContratos._AR_DESCRIPCION}</Th>
                  <Th>{KContratos._AR_DESCARGAR}</Th>
                </Tr>
              </Thead>
              <Tbody>
              {
                ( (!datos) || (datos.length <= 0) )
                ?
                (<Tr key={1}>
                  <Td colSpan={5}>
                    <h1>{K._NOHAYDATOS}</h1>
                  </Td>
                </Tr>)
                :
                (
                  datos.map((fila:IContratoAdjuntos, index:number) => (
                    (
                      <Tr key={index}>
                          <Td>{index+1}</Td>
                          <Td>{formatFecha(fila.adju_adju_fecha_creacion, true, true)}</Td>
                          <Td>{fila.tadj_tabl_nombre}</Td>
                          <Td>{fila.adju_adju_descripcion}</Td>
                          <Td><Button 
                                variant='outline' 
                                onClick={handleOnClick} 
                                size={'xs'} 
                                id={`${fila.adju_adju_id}|${fila.adju_adju_nombre}`} 
                                value={fila.adju_adju_id} 
                                title={fila.adju_adju_id}
                              >
                                {KContratos._AR_BOTON_DESCARGAR}
                              </Button>
                          </Td>
                      </Tr>
                    )
                  ))
                )
              }
              </Tbody>
          </Table>
        </TableContainer>
      </Box>
    )
  }
}

export default ContratoAdjuntosConsulta;
