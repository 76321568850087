import Cookies from "js-cookie";

export const fetchTx = async ( servicio, id, ambiente ) => {

    const body = JSON.stringify ( servicio.params );

    const url = (id) ? `${ambiente}${servicio.endPoint}/${id}` : `${ambiente}${servicio.endPoint}`;

    const headers = new Headers();

    headers.append ( 'content-type', servicio.contentType);

    var respServicio = {
        code: 0,
        data: ''
    } 

    if ( servicio.usaToken ) {
        const jwt = Cookies.get ("jwt");
        if (!jwt) {
// console.log('_____!jwt_____')            
            respServicio = {
                code: -1,
                data: '!JWT'
            } 
            return respServicio;
        }
        headers.append ( 'Authorization', `Bearer ${jwt}`);
    }

    const header = {
        method: servicio.metodo,
        body,
        headers
    }

    await fetch ( url, header )

    .then ( async (resp) => {
        if (resp.ok) {
            const data = await resp.json();
// console.log('_____resp_data______', data)
            respServicio = {
                code: 0,
                data: data.data
            } 
        } else {
            return await resp.text().then ( text => { throw new Error(text) })
        }
    })

    .catch ( (error) => {
        const { code, message } = JSON.parse(error.message);
        respServicio = {
            code:code,
            data:message,
        } 
    })

    return respServicio;
} 