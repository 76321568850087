import * as React from 'react'
import { useContext, useState } from 'react';
import { AuthContext } from '../../context/auth/AuthContext';
import { Link as LinkRouter, useNavigate  } from 'react-router-dom'
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Image,
  Link,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import ColorModeSwitcher from './ColorModeSwitcher'
import { 
  // IconConfiguracion, 
  IconContratos, 
  IconCorreo, 
  IconNuevoContrato, 
  IconNuevoProveedor, 
  IconPendientes, 
  IconProveedores, 
  IconResumen, 
  IconSalir, 
  IconSubirT
} from '../../utils/Icons';
import { KPerfiles } from '../../utils/constantes';

const Sidebar = () => {

  const { usuario, logout } = useContext( AuthContext );
  const [opcion, setOpcion] = useState('b_panelcontrol');

  const history = useNavigate();
  const bgColor = useColorModeValue('gray.200', 'gray.600');

  const onclickMenu = (e: React.SyntheticEvent): void => {
    setOpcion(e.currentTarget.id);
// console.log('___elemento_id__',e.currentTarget.id)
  }
  
  const opActiva = (id:string, color:string):string => {
    const colorBG = (opcion === id) ? color : '';
    return(colorBG);
  }

  const onLogout = () => {
    logout();
    history('/conexion');
  }

  const logo = useColorModeValue("/logo_contratos_light.png", "/logo_contratos_dark.png");

  return(
    <Flex as="section" minH="100vh" bg={useColorModeValue("bg-surface", "blevas")} border="borderRight">
      <Flex
        flex="1"
        bg="bg-surface"
        overflowY="auto"
        boxShadow={useColorModeValue('md', 'md-dark')}
        maxW={{ base: 'full', sm: 'xs' }}
        py={{ base: '2', sm: '2' }}
        px={{ base: '4', sm: '4' }}
      >
        <Stack >
          <Stack spacing={{ base: '1', sm: '1' }} >

            <Stack>
              <Image src={logo} alt="Contratos" p={2} w="200px;"/>
            </Stack>
        
            <Divider />

            <VStack spacing={{ base: '0', sm: '1' }} align="left" p="1">
              <LinkRouter to="/resumen">
                <Box bg={opActiva("x_panelcontrol", bgColor)} rounded="md" p="1" id="x_panelcontrol" onClick={onclickMenu}>
                  <Button leftIcon={<IconResumen/>} variant="link" justifyContent="start" id="b_panelcontrol" >Panel de Control</Button>
                </Box> 
              </LinkRouter>

              {
              ( (usuario?.perf_id !== KPerfiles._PERF_GERENTE_FINANZAS) && (usuario?.perf_id !== KPerfiles._PERF_DIRECCION_EJECUTIVA) )
              ?
              null
              :
              (
                <>
                  <LinkRouter to="/mis-pendientes">
                    <Box bg={opActiva("x_mispendientes", bgColor)} rounded="md" p="1" id="x_mispendientes" onClick={onclickMenu}>
                      <Button leftIcon={<IconPendientes/>} variant="link" justifyContent="start" id="b_mispendientes" >Mis Pendientes</Button>
                    </Box> 
                  </LinkRouter>

                  <LinkRouter to="/subir-firmados">
                    <Box bg={opActiva("x_misfirmados", bgColor)} rounded="md" p="1" id="x_misfirmados" onClick={onclickMenu}>
                      <Button leftIcon={<IconSubirT/>} variant="link" justifyContent="start" id="b_misfirmados" >Subir Firmados</Button>
                    </Box> 
                  </LinkRouter>
                </>
              )
              }

              <LinkRouter to="/contratos/.|p">
                <Box bg={opActiva("x_contratos", bgColor)} rounded="md" p="1" id="x_contratos" onClick={onclickMenu}>
                  <Button leftIcon={<IconContratos/>} variant="link" justifyContent="start" id="b_contratos">Contratos</Button>
                </Box> 
              </LinkRouter>

              <LinkRouter to="/proveedores">
                <Box bg={opActiva("x_proveedores", bgColor)} rounded="md" p="1" id="x_proveedores" onClick={onclickMenu}>
                  <Button leftIcon={<IconProveedores/>} variant="link" justifyContent="start" id="xb_proveedores" >Proveedores</Button>
                </Box> 
              </LinkRouter>

              {
              ( (usuario?.perf_id === KPerfiles._PERF_ADMIN_SUPERUSUARIO) )
              ?
              (
                <>
                  <LinkRouter to="/mis-pendientes-f1">
                    <Box bg={opActiva("x_mispendientes_f1", bgColor)} rounded="md" p="1" id="x_mispendientes_f1" onClick={onclickMenu}>
                      <Button leftIcon={<IconPendientes/>} variant="link" justifyContent="start" id="b_mispendientes" >Pendientes Firma #1</Button>
                    </Box> 
                  </LinkRouter>

                  <LinkRouter to="/subir-firmados-f1">
                    <Box bg={opActiva("x_misfirmados_f1", bgColor)} rounded="md" p="1" id="x_misfirmados_f1" onClick={onclickMenu}>
                      <Button leftIcon={<IconSubirT/>} variant="link" justifyContent="start" id="b_misfirmados" >Subir Firmados #1</Button>
                    </Box> 
                  </LinkRouter>

                  <LinkRouter to="/mis-pendientes-f2">
                    <Box bg={opActiva("x_mispendientes_f2", bgColor)} rounded="md" p="1" id="x_mispendientes_f2" onClick={onclickMenu}>
                      <Button leftIcon={<IconPendientes/>} variant="link" justifyContent="start" id="b_mispendientes" >Pendientes Firma #2</Button>
                    </Box> 
                  </LinkRouter>

                  <LinkRouter to="/subir-firmados-f2">
                    <Box bg={opActiva("x_misfirmados_f2", bgColor)} rounded="md" p="1" id="x_misfirmados_f2" onClick={onclickMenu}>
                      <Button leftIcon={<IconSubirT/>} variant="link" justifyContent="start" id="b_misfirmados" >Subir Firmados #2</Button>
                    </Box> 
                  </LinkRouter>

                </>
              )
              :
                null
              }

            </VStack>

            <Divider />

            <VStack spacing={{ base: '0', sm: '0' }} align="left">
              <LinkRouter to="/form-contrato/nuevo">
                <Box bg={opActiva("x_nvocontrato", bgColor)} rounded="md" p="1" id="x_nvocontrato" onClick={onclickMenu}>
                  <Button leftIcon={<IconNuevoContrato/>} variant="link" justifyContent="start" id="b_nvocontrato" >Nuevo Contrato</Button>
                </Box> 
              </LinkRouter>
        
              <LinkRouter to="/form-proveedor-pn/nuevo">
                <Box bg={opActiva("x_nvoproveedorpn", bgColor)} rounded="md" p="1" id="x_nvoproveedorpn" onClick={onclickMenu}>
                  <Button leftIcon={<IconNuevoProveedor/>} variant="link" justifyContent="start" id="b_nvoproveedorpn" >Nuevo Proveedor</Button>
                </Box> 
              </LinkRouter>
            </VStack>

          </Stack>
          
          <VStack spacing={{ base: '0', sm: '0' }} align="left">

            <LinkRouter to="/soporte">
              <Box bg={opActiva("x_soporte", bgColor)} rounded="md" p="1" id="x_soporte" onClick={onclickMenu}>
                <Button leftIcon={<IconCorreo/>} variant="link" justifyContent="start" id="b_soporte" >Soporte</Button>
              </Box> 
            </LinkRouter>

          </VStack>

          <Box bg="bg-subtle" px="4" py="5" borderRadius="lg" alignItems={"flex-start"}>
            <Stack spacing="3">
                <Text fontSize="sm" fontWeight="medium">{`${usuario?.usua_nombre}`}</Text>
                {/* <Link size="xs" fontSize="xs" colorScheme="blue" >Actualizar mis datos</Link> */}
                <HStack>
                <IconSalir/>
                <Link size="xs" fontSize="xs" colorScheme="blue" onClick={onLogout}>Salir</Link>
                </HStack>
            </Stack>
          </Box>

          <Divider />
          <ColorModeSwitcher/>
            
        </Stack>
      </Flex>
    </Flex>
  )
}

export default Sidebar;