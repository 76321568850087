import { FC, useContext, useState } from 'react';
import { useFetch } from '../../hooks/useFetch';
import { servContratosConsultar } from '../../utils/servicios';
import { IUseFetch, IContratoConsultar } from '../../interfaces/iEndpoints';
import {
    Box,
    Button,
    Container,
    Heading,
    useColorModeValue,
    Flex,
    VStack,
    Text,
    HStack,
    TabList,
    Tab,
    TabPanels,
    Tabs,
    TabPanel,
  } from '@chakra-ui/react'
import { K, KContratos } from '../../utils/constantes';
import { AuthContext } from '../../context';
import ContratoPreliminar from './ContratoPreliminar';
import ContratoBitacora from './ContratoBitacora';
import ContratoAdjuntos from './ContratoAdjuntos';
import ContratoEditarForm from './ContratoEditarForm';
import ContratoFirmarForm from './ContratoFirmarForm';

interface Props {
  id: number,
}

const ContratoEditarTabs: FC<Props> = ({ id }) => {

  const { usuario, ambiente } = useContext( AuthContext );
  const [ primeraVez, setPrimeravez ] = useState(true);

  const colorBG           = useColorModeValue("gray.50", "gray.700");
  const colorBG2          = useColorModeValue("gray.200", "gray.900")
  const colorTitulo       = useColorModeValue(K._COLOR_TITULO_L, K._COLOR_TITULO_D);

  if (primeraVez) {
    servContratosConsultar.params = {
      ...servContratosConsultar.params,
      pnat_rut: '',
      ctto_nro_interno: '',
      ctto_nombre: '',
      cges_id_list: '',
      esta_id_list: '',
      ctto_fecha_ini: '',
      ctto_fecha_fin: '',
      ctto_id: id.toString(),
      random: Math.random(),    
    }
    setPrimeravez(false);
  }

  const contratoServ:IUseFetch = useFetch (servContratosConsultar, JSON.stringify ( servContratosConsultar.params ), 'ContratoEditar', false);
  const { data } = contratoServ;
  const datos:IContratoConsultar = data.data ? data.data[0] : null;

  if (contratoServ.cargando)
    return (<Button isLoading={contratoServ.cargando} loadingText={K._CARGANDO} colorScheme='teal' variant='outline' />)
  else {

    return (
      (!datos)
      ?
      <Container 
        py={{ base: '1', md: '2' }} 
        px={{ base: '1', md: '2' }} 
        maxW='6xl' 
        bg={colorBG2}
      >
        <Box
          bg={colorBG}
          w='4xl' 
          p='6'
        >
          <h1>{K._CTTONOEXISTE}</h1>
        </Box>
      </Container>
      :
      <Container 
        py={{ base: '1', md: '2' }} 
        px={{ base: '1', md: '2' }} 
        maxW='6xl' 
        bg={colorBG2}
      >
        <Flex 
          direction={{ base: 'column', md: 'row' }}
          overflowY="auto"
          bg={colorBG}
          py={{ base: '0', md: '0' }} 
          borderRadius={{ base: 'none', sm: 'xl' }}
        >
          <Box
            bg={colorBG}
            w='4xl' 
            p='6'
          >
            <VStack align='left'>
              <HStack p={'4'}>
                <Heading size='md' p={0} color={colorTitulo}>{KContratos._F_TITULO}</Heading>
                <Text fontSize={"xs"}>{`[id:${id}]`}</Text>
              </HStack>

              <Tabs variant='enclosed' isFitted>
                <TabList>
                  <Tab _selected={{ color: 'white', bg: 'teal.400' }}>{KContratos._TAB_FORMULARIO}</Tab>
                  <Tab _selected={{ color: 'white', bg: 'teal.400' }}>{KContratos._TAB_PRELIMINAR}</Tab>
                  <Tab _selected={{ color: 'white', bg: 'teal.400' }}>{KContratos._TAB_ADJUNTOS}</Tab>
                  <Tab _selected={{ color: 'white', bg: 'teal.400' }}>{KContratos._TAB_BITACORA}</Tab>
                  <Tab _selected={{ color: 'white', bg: 'teal.400' }}>{KContratos._TAB_FIRMAR}</Tab>
                </TabList>

                <TabPanels>
                  { /* ---------------------------------------------------------------------------------------- */ }
                  { /* FORM EDITAR----------------------------------------------------------------------------- */ }
                  { /* ---------------------------------------------------------------------------------------- */ }
                  <TabPanel>
                    <ContratoEditarForm id={id} datos={datos} setPrimeraVez={setPrimeravez} />
                  </TabPanel>

                  { /* ---------------------------------------------------------------------------------------- */ }
                  { /* PRELIMINAR ----------------------------------------------------------------------------- */ }
                  { /* ---------------------------------------------------------------------------------------- */ }
                  <TabPanel>
                    <ContratoPreliminar datos={datos} />
                  </TabPanel>

                  { /* ---------------------------------------------------------------------------------------- */ }
                  { /* ADJUNTOS ------------------------------------------------------------------------------- */ }
                  { /* ---------------------------------------------------------------------------------------- */ }
                  <TabPanel>
                      <ContratoAdjuntos id={id} datos={datos} ambiente={ambiente} usua_id={usuario?.usua_id} />
                  </TabPanel>

                  { /* ---------------------------------------------------------------------------------------- */ }
                  { /* BITACORA ------------------------------------------------------------------------------- */ }
                  { /* ---------------------------------------------------------------------------------------- */ }
                  <TabPanel>
                    <ContratoBitacora id={id} ambiente={ambiente} />
                  </TabPanel>

                  { /* ---------------------------------------------------------------------------------------- */ }
                  { /* FORM FIRMA----------------------------------------------------------------------------- */ }
                  { /* ---------------------------------------------------------------------------------------- */ }
                  <TabPanel>
                    <ContratoFirmarForm id={id} datos={datos} ambiente={ambiente} usuario={usuario} setPrimeraVez={setPrimeravez} />
                  </TabPanel>

                </TabPanels>
              </Tabs>

            </VStack>
          </Box>
        </Flex>
      </Container>
    )

  }
}

export default ContratoEditarTabs;
