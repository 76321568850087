import { FC } from 'react';
import { useState } from 'react';
import { useFetch } from '../../hooks/useFetch';
import { servContratosConsultar } from '../../utils/servicios';
import { IAplicacionesPermisos, IUseFetch } from '../../interfaces/iEndpoints';
import RechazadosRspta from './RechazadosRspta';
import {
    Box,
    Button,
    Container,
    Heading,
    HStack,
    Stack,
    VStack,
    useColorModeValue,
    Text,
  } from '@chakra-ui/react'
import { IconRefrescar } from '../../utils/Icons';
import { K, KApps, KContratos, KEstado } from '../../utils/constantes';
import { listaCGestionAut } from '../../utils/utilidades';

interface Props {
  tAplicacionesPermisos: IAplicacionesPermisos[],
}

const Rechazados: FC<Props> = ({ tAplicacionesPermisos }) => {

  const [ consultar, setConsultar ] = useState (1);
  const colorTitulo = useColorModeValue(K._COLOR_TITULO_L, K._COLOR_TITULO_D);
   
  servContratosConsultar.params = {
    ...servContratosConsultar.params,
    esta_id_list: KEstado._RECHAZADO,
    pnat_rut: '',
    ctto_id: '',
    ctto_nro_interno: '',
    ctto_nombre: '',
    cges_id_list: listaCGestionAut(tAplicacionesPermisos, KApps._PANEL_CONTROL),
    ctto_fecha_ini: '',
    ctto_fecha_fin: '',
  }
 
  const onClickConsultar = (e: React.SyntheticEvent) => {
    setConsultar(consultar+1);
    servContratosConsultar.params = {
      ...servContratosConsultar.params,
      random: Math.random(),
    }
  }

  const contratosServ:IUseFetch = useFetch (servContratosConsultar, JSON.stringify ( servContratosConsultar.params ), 'Rechazados');
 

    return (
      <Container 
        py={{ base: '1', md: '2' }} 
        px={{ base: '1', md: '2' }} 
        maxW='6xl' 
        p="3" 
        bg={useColorModeValue("white", "gray.700")}
      >

        <Stack direction={'column'} spacing={5}>

            <Box overflowX="auto" bg={useColorModeValue("white", "gray.700") } p={5} >
              <VStack direction={{ base: 'column', md: 'row' }} spacing={6}>

                <HStack spacing={3}>
                  <Heading size='md' color={colorTitulo}>{KContratos._PR_TITULO1}</Heading>
                  <Text fontSize='md'>{KContratos._PR_TITULO2}</Text>
                  <Button onClick={onClickConsultar} colorScheme='green' variant='ghost' leftIcon={<IconRefrescar/>} />
                </HStack>

                <RechazadosRspta respuesta={contratosServ} />

              </VStack>              
            </Box>

        </Stack>              


      </Container>
    )
  }

  export default Rechazados;