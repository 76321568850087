import { FC, useContext, useRef, useState } from 'react';
import { servContratosConsultar } from '../../utils/servicios';
import { IContratoModificar, ITCTransiciones, IContratoConsultar } from '../../interfaces/iEndpoints';
import { useForm } from 'react-hook-form'
import {
    useColorModeValue,
    FormControl,
    FormLabel,
    Input,
    Divider,
    VStack,
    Text,
    FormErrorMessage,
    Textarea,
    HStack,
    NumberInput,
    NumberInputField,
    Tooltip,
    Icon,
    Select,
    Stack,
    useToast,
    useDisclosure,
  } from '@chakra-ui/react'
import { formatFecha, formatRUT, fParametros, nombrePersona, pctjeMayusculas, tooltipAyuda, validaFechaContrato } from '../../utils/utilidades';
import { K, KEstado, KContratos, KV } from '../../utils/constantes';
import { AuthContext } from '../../context';
import { TablasContext } from '../../context/TablasContext';
import { ContratoFlujoAcciones } from './ContratoFlujoAcciones';
import { fAutorizar, fEliminar, fFirmarOK, fGrabar, fRechABorrador, fRechazar } from './contratoFlujoFunciones';

interface Props {
  id: number;
  datos: IContratoConsultar;
  setPrimeraVez: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContratoEditarForm: FC<Props> = ({ id, datos, setPrimeraVez }) => {

  const { usuario, ambiente } = useContext( AuthContext );

  const { tMonedas, tAyuda, tParametros, tTCTransicionesPerfiles, tTContrato } = useContext( TablasContext );

  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const cancelRef = useRef(null);

  const { setValue, register, handleSubmit, formState: { errors, isSubmitting, isValid } } = useForm<IContratoModificar>();

  const [ mostrarMensajeError, setMostrarMensajeError ] = useState(false);
  const [ mostrarMensajeOK, setMostrarMensajeOK ] = useState(false);
  const [ accion, setAccion ] = useState('');
  const [ mensajeActualizar, setMensajeActualizar ] = useState('');

  let tTCTranscionesCtto:ITCTransiciones[] =  [];
  if (datos) {
    tTCTranscionesCtto =  tTCTransicionesPerfiles.filter (transicion => ( (transicion.trsn_tcon_id === datos.ctto_tcon_id) && (transicion.trsn_esta_id_ini === datos.ctto_esta_id_actual) && ( transicion.trpf_perf_id === usuario?.perf_id ) ) );
    setValue('esta_id_actual', datos.ctto_esta_id_actual)
  }    

  const vParametros = fParametros(tParametros);

  const colorLabel        = useColorModeValue("blue.500", "whiteAlpha.800");
  const colorBG           = useColorModeValue("gray.50", "gray.700");
  const colorBGErrores    = useColorModeValue("pink.100", "pink.200");
  const colorBGok         = useColorModeValue("green.100", "green.100");
  const colorTextMensajes = useColorModeValue("gray.800", "gray.800");
  const bgInput           = useColorModeValue("white", "gray.600");
  const bgInputRead       = useColorModeValue("blue.50", "gray.700");
  const widthInput        = "40"; 
  const colorSeccion      = useColorModeValue(K._COLOR_SECCION_L, K._COLOR_SECCION_D);
  const colorDivider      = useColorModeValue(K._COLOR_DIVIDER_L, K._COLOR_DIVIDER_D);

  const onClickEliminar   = () => { setAccion(KContratos._F_ACCION_ELIMINAR);  onOpen();  }
  const onClickFirmar1ok  = () => { setAccion(KContratos._F_ACCION_FIRMAR1OK); onOpen(); }
  const onClickFirmarPok  = () => { setAccion(KContratos._F_ACCION_FIRMARPOK); onOpen(); }
  const onClickFirmar2ok  = () => { setAccion(KContratos._F_ACCION_FIRMAR2OK); onOpen(); }
  const onClickRechazar   = () => { setAccion(KContratos._F_ACCION_RECHAZAR);  onOpen(); }
  const onClickAutorizar  = () => { setAccion(KContratos._F_ACCION_AUTORIZAR); onOpen(); }
  const onClickRechABorrador  = () => { setAccion(KContratos._F_ACCION_RECHBORR); onOpen(); }
  
  const onGrabar = async ( data: IContratoModificar ) => {
    await fGrabar ( datos, usuario, data, id, setMensajeActualizar, setMostrarMensajeOK, setMostrarMensajeError, servContratosConsultar, toast, ambiente, setPrimeraVez, tTContrato );
  }

  const onEliminar = async ( data: IContratoModificar ) => {
    await fEliminar ( usuario, data, id, setMensajeActualizar, setMostrarMensajeOK, setMostrarMensajeError, servContratosConsultar, toast, ambiente, setPrimeraVez );
    onClose();
  }

  const onAutorizar = async ( data: IContratoModificar ) => {
      await fAutorizar ( usuario, data, id, setMensajeActualizar, setMostrarMensajeOK, setMostrarMensajeError, servContratosConsultar, toast, ambiente, setPrimeraVez, datos, vParametros, tTContrato );
      onClose();
  }

  const onRechazar = async ( data: IContratoModificar ) => {
    await fRechazar ( usuario, data, id, setMensajeActualizar, setMostrarMensajeOK, setMostrarMensajeError, servContratosConsultar, toast, ambiente );
    onClose();
  }

  const onRechABorrador = async ( data: IContratoModificar ) => {
    await fRechABorrador ( usuario, data, id, setMensajeActualizar, setMostrarMensajeOK, setMostrarMensajeError, servContratosConsultar, toast, ambiente, setPrimeraVez );
    onClose();
  }

  const onFirmar1ok = async ( data: IContratoModificar ) => {
    await fFirmarOK ( usuario, KEstado._FIRMA_1_OK,  data, id, setMensajeActualizar, setMostrarMensajeOK, setMostrarMensajeError, servContratosConsultar, toast, ambiente );
    onClose();
  }

  const onFirmarPok = async ( data: IContratoModificar ) => {
    await fFirmarOK ( usuario, KEstado._FIRMA_PROVEEDOR_OK,  data, id, setMensajeActualizar, setMostrarMensajeOK, setMostrarMensajeError, servContratosConsultar, toast, ambiente );
    onClose();
  }

  const onFirmar2ok = async ( data: IContratoModificar ) => {
    await fFirmarOK ( usuario, KEstado._FIRMA_2_OK,  data, id, setMensajeActualizar, setMostrarMensajeOK, setMostrarMensajeError, servContratosConsultar, toast, ambiente );
    onClose();
  }

  const readOnlyInput:boolean         = (!datos || ( (datos.ctto_esta_id_actual !== KEstado._BORRADOR) && (datos.ctto_esta_id_actual !== KEstado._RECHAZADO) ) ) ? true : false;
  const readOnlyMsjeDeFirma1:boolean  = (!datos || (datos.ctto_esta_id_actual !== KEstado._ENVIADO_A_FIRMA_1) )  ? true : false;
  const readOnlyMsjeDeProv:boolean    = (!datos || (datos.ctto_esta_id_actual !== KEstado._FIRMA_1_OK) )         ? true : false;
  const readOnlyMsjeDeFirma2:boolean  = (!datos || (datos.ctto_esta_id_actual !== KEstado._FIRMA_PROVEEDOR_OK) ) ? true : false;

  return (
      <form onSubmit={ handleSubmit(onGrabar) } >

        <Stack bg={colorBG} spacing='1'>

          { /* ---------------------------------------------------------------------------------------- */ }
          { /* _SECCION_I: ESTRUCTURA ----------------------------------------------------------------- */ }
          { /* ---------------------------------------------------------------------------------------- */ }

          <HStack p="2">
            <Divider bg={colorDivider} />
              <Text fontSize="lg" fontWeight="medium" whiteSpace="nowrap" color={colorSeccion} >
                {KContratos._SECCION_I}
              </Text>
            <Divider bg={colorDivider} />
          </HStack>

          <Stack
            direction={{ base: 'column', md: 'row' }}
            spacing={{ base: '1.5', md: '8' }}
            isInline={true}
          >
            { /* ctto_pnat_rut ----------------------------------------------------------- */ }
            <FormControl>
              <FormLabel color={colorLabel} fontSize="xs">{KContratos._F_RUT_PROV}</FormLabel>
              <Input 
                bg={bgInputRead}
                w={40}
                defaultValue={formatRUT(datos.ctto_pnat_rut)} 
                readOnly={true}
                { ...register('pnat_rut', { }) } 
              />         
            </FormControl>

            { /* pnat_pnat_nombre1... ----------------------------------------------------------- */ }
            <FormControl>
              <FormLabel color={colorLabel} fontSize="xs">{KContratos._F_NOMBRE_PROV}</FormLabel>
              <Input 
                bg={bgInputRead}
                w="xl"
                defaultValue={ nombrePersona( datos.pnat_pnat_nombre1, datos.pnat_pnat_nombre2, datos.pnat_pnat_apellido1, datos.pnat_pnat_apellido2 ) } 
                readOnly={true}
              />
            </FormControl>
          </Stack>


          { /* C: GESTION + TIPO_CONTRATO + NRO INTERNO */ }
          <Stack
            direction={{ base: 'column', md: 'row' }}
            spacing={{ base: '1.5', md: '8' }}

          >
            { /* cges_cges_nombre ----------------------------------------------------------- */ }
            <FormControl>
              <FormLabel variant="inline" color={colorLabel} fontSize="xs">{KContratos._F_CGESTION}</FormLabel>
              <Input 
                bg={bgInputRead}
                defaultValue={(datos.cges_cges_nombre)} 
                readOnly={true}
              />
            </FormControl>

            { /* tcon_tcon_nombre ----------------------------------------------------------- */ }
            <FormControl>
                <FormLabel color={colorLabel} fontSize="xs">{KContratos._F_TIPO_CTTO}</FormLabel>
                <Input 
                  bg={bgInputRead}
                  defaultValue={(datos.tcon_tcon_nombre)} 
                  readOnly={true}
                  w="md"
                />
            </FormControl>

            { /* ctto_nro_interno ----------------------------------------------------------- */ }
            <FormControl>
                <Input 
                  bg={bgInputRead}
                  defaultValue={(datos.ctto_ctto_nro_interno)} 
                  type="hidden"
                  { ...register('ctto_nro_interno',{ } )} 
                />
            </FormControl>

            { /* tcon_id ----------------------------------------------------------- */ }
            <FormControl>
                <Input 
                  bg={bgInputRead}
                  defaultValue={(datos.ctto_tcon_id)} 
                  type="hidden"
                  { ...register('tcon_id',{ } )} 
                />
            </FormControl>
          </Stack>

          { /* ESTADO ACTUAL + FECHA_ESTADO */ }
          <Stack
            direction={{ base: 'column', md: 'row' }}
            spacing={{ base: '1.5', md: '10' }}
            isInline={true}
          >
            { /* esta_esta_nombre ----------------------------------------------------------- */ }
            <FormControl>
              <FormLabel color={colorLabel} fontSize="xs">{KContratos._F_ESTADO} {datos.ctto_esta_id_actual}</FormLabel>
              <Input 
                bg={bgInputRead}
                defaultValue={datos.esta_esta_nombre} 
                readOnly={true}
                w="xl"
              />
            </FormControl>
              

            { /* ctto_fecha_estado_actual ----------------------------------------------------------- */ }
            <FormControl>
              <FormLabel color={colorLabel} fontSize="xs">{KContratos._F_FECHA_ESTADO}</FormLabel>
              <Input 
                bg={bgInputRead}
                defaultValue={formatFecha(datos.ctto_ctto_fecha_estado_actual, true)} 
                w="50"
              />
          </FormControl>
        </Stack>

        <FormControl>
          <Input 
            bg={bgInputRead}
            defaultValue={datos.ctto_esta_id_actual} 
            w="xl"
            type="hidden"
            { ...register('esta_id_actual',{ } )} 
          />
        </FormControl>

        { /* ---------------------------------------------------------------------------------------- */ }
        { /* _SECCION_II: CONTENIDO ----------------------------------------------------------------- */ }
        { /* ---------------------------------------------------------------------------------------- */ }

        <HStack p="5">
          <Divider bg={colorDivider} />
            <Text fontSize="lg" fontWeight="medium" whiteSpace="nowrap" color={colorSeccion} >
              {KContratos._SECCION_II}
            </Text>
          <Divider bg={colorDivider} />
        </HStack>

        { /* ctto_nombre ----------------------------------------------------------- */ }
        <FormControl isInvalid={errors.ctto_nombre?true:false}>
          <FormLabel fontSize="xs" color={colorLabel}>
            <HStack>
              <Text>{KContratos._F_NOMBRE}</Text>
              <Tooltip label={tooltipAyuda('ctto_nombre', tAyuda)}><Icon /></Tooltip>
            </HStack>
          </FormLabel>
          <Input 
            bg={bgInput}
            defaultValue={(datos.ctto_ctto_nombre)} 
            readOnly={readOnlyInput}
            { ...register('ctto_nombre', 
              { 
                required: KV._CAMPO_OBL, 
                minLength: { value: vParametros.ctto_nombre_lmin, message: `${KV._LARGO_MIN} ${vParametros.ctto_nombre_lmin}` },
                maxLength: { value: vParametros.ctto_nombre_lmax, message: `${KV._LARGO_MAX} ${vParametros.ctto_nombre_lmax}` },
                validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
              })
            } 
          />
          <FormErrorMessage fontSize="10">
            {errors.ctto_nombre && errors.ctto_nombre.message}
          </FormErrorMessage>
        </FormControl>

        { /* ctto_objetivo ----------------------------------------------------------- */ }
        <FormControl isInvalid={errors.ctto_objetivo?true:false}>
          <FormLabel fontSize="xs" color={colorLabel}>
            <HStack>
              <Text>{KContratos._F_OBJETIVO}</Text>
              <Tooltip label={tooltipAyuda('ctto_objetivo', tAyuda)} w={600}><Icon /></Tooltip>
            </HStack>
          </FormLabel>
          <Textarea 
            bg={bgInput}
            defaultValue={datos.ctto_ctto_objetivo}
            readOnly={readOnlyInput}
            { ...register('ctto_objetivo', 
              { 
                required: KV._CAMPO_OBL, 
                minLength: { value: vParametros.ctto_objetivo_lmin, message: `${KV._LARGO_MIN} ${vParametros.ctto_objetivo_lmin}` },
                maxLength: { value: vParametros.ctto_objetivo_lmax, message: `${KV._LARGO_MAX} ${vParametros.ctto_objetivo_lmax}` },
                validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
              })
            } 
          />
          <FormErrorMessage fontSize="10">
            {errors.ctto_objetivo && errors.ctto_objetivo.message}
          </FormErrorMessage>
        </FormControl>

        { /* ctto_descripcion ----------------------------------------------------------- */ }
        <FormControl isInvalid={errors.ctto_descripcion?true:false}>
          <FormLabel fontSize="xs" color={colorLabel}>
            <HStack>
              <Text>{KContratos._F_DESCRIPCION}</Text>
              <Tooltip label={tooltipAyuda('ctto_descripcion', tAyuda)}><Icon /></Tooltip>
            </HStack>
          </FormLabel>
          <Textarea 
            bg={bgInput} 
            h={"52"}
            defaultValue={datos.ctto_ctto_descripcion}
            readOnly={readOnlyInput}
            { ...register('ctto_descripcion', 
              { 
                required: KV._CAMPO_OBL, 
                minLength: { value: vParametros.ctto_descripcion_lmin, message: `${KV._LARGO_MIN} ${vParametros.ctto_descripcion_lmin}` },
                maxLength: { value: vParametros.ctto_descripcion_lmax, message: `${KV._LARGO_MAX} ${vParametros.ctto_descripcion_lmax}` },
                validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
              })
            } 
          />
          <FormErrorMessage fontSize="10">
            {errors.ctto_descripcion && errors.ctto_descripcion.message}
          </FormErrorMessage>
        </FormControl>


        { /* ---------------------------------------------------------------------------------------- */ }
        { /* SECCION_III: FECHAS Y VALORES ---------------------------------------------------------- */ }
        { /* ---------------------------------------------------------------------------------------- */ }

        <HStack p="5">
          <Divider bg={colorDivider} />
            <Text fontSize="lg" fontWeight="medium" whiteSpace="nowrap" color={colorSeccion} >
              {KContratos._SECCION_III}
            </Text>
          <Divider bg={colorDivider} />
        </HStack>

        { /* MONEDA + MONTO + FECHA_INICIO + FECHA_TERMINO */ }
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '1.5', md: '8' }}
          isInline={true}
        >

          { /* ctto_fecha_ppal ----------------------------------------------------------- */ }
          <FormControl isInvalid={errors.ctto_fecha_ppal?true:false}>
            <FormLabel fontSize="xs" color={colorLabel}>
              <HStack>
                <Text>{KContratos._F_FECHA_PPAL}</Text>
                <Tooltip label={tooltipAyuda('ctto_fecha_ppal', tAyuda)}><Icon /></Tooltip>
              </HStack>
            </FormLabel>
            <Input 
              bg={bgInput}
              w={widthInput}
              defaultValue={datos.ctto_ctto_fecha_ppal} 
              type='date'
              readOnly={readOnlyInput}
              { ...register('ctto_fecha_ppal', 
                { 
                  required: KV._CAMPO_OBL, 
                  minLength: { value: KV._CTTO_FECHA_LMIN, message: `${KV._LARGO_MIN} ${KV._CTTO_FECHA_LMIN}` },
                  maxLength: { value: KV._CTTO_FECHA_LMAX, message: `${KV._LARGO_MAX} ${KV._CTTO_FECHA_LMAX}` },
                  validate: {
                    fechaContrato: (v) => validaFechaContrato(v, vParametros.ctto_fecha_ppal_dias_atras, vParametros.ctto_fecha_ppal_dias_adelante) || `${KV._CTTO_MSJE_FECHA_PPAL} [${vParametros.ctto_fecha_ppal_dias_atras}/${vParametros.ctto_fecha_ppal_dias_adelante}]`
                  }
                })
              } 
            />
            <FormErrorMessage fontSize="10">
              {errors.ctto_fecha_ppal && errors.ctto_fecha_ppal.message}
            </FormErrorMessage>                
          </FormControl>

          { /* mone_id ----------------------------------------------------------- */ }
          <FormControl isInvalid={errors.mone_id?true:false}>
            <FormLabel fontSize="xs" color={colorLabel}>{KContratos._F_MONEDA}</FormLabel>
            <Select 
              size={'md'} 
              defaultValue={datos.ctto_mone_id} 
              bg={bgInput}
              disabled={readOnlyInput}
              {...register('mone_id', {
                validate: {
                  seleccionar: (c) => (c !== K._SELE_ID) || `${KV._MSJE_SELECCIONE} ${KContratos._F_MONEDA}`
              },})} 
            >
              <option key='mone_id_s' value={K._SELE_ID}>{`${K._SELE_TEXTO}`}</option>
              {
                tMonedas.map( moneda => (
                  <option key={moneda.tabl_tabl_elemento} value={moneda.tabl_tabl_elemento} >{moneda.tabl_tabl_nombre}</option>
                ))
              }  
            </Select>
            <FormErrorMessage fontSize="10">
              {errors.mone_id && errors.mone_id.message}
            </FormErrorMessage>
          </FormControl>

          { /* ctto_monto_total ----------------------------------------------------------- */ }
          <FormControl isInvalid={errors.ctto_monto_total?true:false}>
            <FormLabel fontSize="xs" color={colorLabel}>
              <HStack>
                <Text>{KContratos._F_MONTO}</Text>
                <Tooltip label={tooltipAyuda('ctto_monto_total', tAyuda)}><Icon /></Tooltip>
              </HStack>
            </FormLabel>
            <NumberInput  
              min={1} 
              bg={bgInput}
              w={widthInput}
              defaultValue={datos.ctto_ctto_monto_total} 
              clampValueOnBlur={false}
            >
              <NumberInputField 
                readOnly={readOnlyInput}
                { ...register('ctto_monto_total', 
                  { 
                    required: KV._CAMPO_OBL, 
                    validate: {
                      mayorCero: (v) => (parseFloat(v) > 0) || 'Monto total debe ser mayor que 0'
                    }
                  })
                } 
              />
            </NumberInput>
            <FormErrorMessage fontSize="10">
              {errors.ctto_monto_total && errors.ctto_monto_total.message}
            </FormErrorMessage>                
          </FormControl>
          
          { /* ctto_fecha_ini ----------------------------------------------------------- */ }
          <FormControl isInvalid={errors.ctto_fecha_ini?true:false}>
            <FormLabel fontSize="xs" color={colorLabel}>
              <HStack>
                <Text>{KContratos._F_FECHA_INI}</Text>
                <Tooltip label={tooltipAyuda('ctto_fecha_ini', tAyuda)}><Icon /></Tooltip>
              </HStack>
            </FormLabel>
            <Input 
              bg={bgInput}
              w={widthInput}
              defaultValue={datos.ctto_ctto_fecha_ini} 
              type='date'
              readOnly={readOnlyInput}
              { ...register('ctto_fecha_ini', 
                { 
                  required: KV._CAMPO_OBL, 
                  minLength: { value: KV._CTTO_FECHA_LMIN, message: `${KV._LARGO_MIN} ${KV._CTTO_FECHA_LMIN}` },
                  maxLength: { value: KV._CTTO_FECHA_LMAX, message: `${KV._LARGO_MAX} ${KV._CTTO_FECHA_LMAX}` },
                  validate: {
                    fechaIni: (v) => validaFechaContrato(v, vParametros.ctto_fecha_ini_dias_atras, vParametros.ctto_fecha_ini_dias_adelante) || KV._CTTO_MSJE_FECHA_INI
                  }
                })
              }
              />
            <FormErrorMessage fontSize="10">
              {errors.ctto_fecha_ini && errors.ctto_fecha_ini.message}
            </FormErrorMessage>                
          </FormControl>
          
          { /* ctto_fecha_fin ----------------------------------------------------------- */ }
          <FormControl isInvalid={errors.ctto_fecha_fin?true:false}>
            <FormLabel fontSize="xs" color={colorLabel}>
              <HStack>
                <Text>{KContratos._F_FECHA_FIN}</Text>
                <Tooltip label={tooltipAyuda('ctto_fecha_fin', tAyuda)}><Icon /></Tooltip>
              </HStack>
            </FormLabel>
            <Input 
              bg={bgInput}
              w={widthInput}
              defaultValue={datos.ctto_ctto_fecha_fin} 
              type='date'
              readOnly={readOnlyInput}
              { ...register('ctto_fecha_fin', 
                { 
                  required: KV._CAMPO_OBL, 
                  minLength: { value: KV._CTTO_FECHA_LMIN, message: `${KV._LARGO_MIN} ${KV._CTTO_FECHA_LMIN}` },
                  maxLength: { value: KV._CTTO_FECHA_LMAX, message: `${KV._LARGO_MAX} ${KV._CTTO_FECHA_LMAX}` },
                  validate: {
                    fechaFin: (v) => validaFechaContrato(v, vParametros.ctto_fecha_fin_dias_atras, vParametros.ctto_fecha_fin_dias_adelante) || KV._CTTO_MSJE_FECHA_FIN
                  }
                })
              }
            />
            <FormErrorMessage fontSize="10">
              {errors.ctto_fecha_fin && errors.ctto_fecha_fin.message}
            </FormErrorMessage>                
          </FormControl>

        </Stack>

        { /* CUOTAS FORMA_PAGO */ }
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '1.5', md: '8' }}
          isInline={true}
        >
          { /* ctto_pago_descripcion ----------------------------------------------------------- */ }
          <FormControl isInvalid={errors.ctto_pago_descripcion?true:false}>
            <FormLabel fontSize="xs" color={colorLabel}>
              <HStack>
                <Text>{KContratos._F_PAGO_GLOSA}</Text>
                <Tooltip label={tooltipAyuda('ctto_pago_descripcion', tAyuda)}><Icon /></Tooltip>
              </HStack>
            </FormLabel>
            <Textarea 
              bg={bgInput} 
              defaultValue={datos.ctto_ctto_pago_descripcion} 
              readOnly={readOnlyInput}
              { ...register('ctto_pago_descripcion', 
                { 
                  // required: KV._CAMPO_OBL, 
                  // minLength: { value: KV._CTTO_DESC_PAGO_LMIN, message: `${KV._LARGO_MIN} ${KV._CTTO_DESC_PAGO_LMIN}` },
                  maxLength: { value: vParametros.ctto_desc_pago_lmax, message: `${KV._LARGO_MAX} ${vParametros.ctto_desc_pago_lmax}` },
                  validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                })
              }
            />
            <FormErrorMessage fontSize="10">
              {errors.ctto_pago_descripcion && errors.ctto_pago_descripcion.message}
            </FormErrorMessage>                
          </FormControl>

        </Stack>

        { /* ---------------------------------------------------------------------------------------- */ }
        { /* SECCION_IV: MENSAJES ------------------------------------------------------------------- */ }
        { /* ---------------------------------------------------------------------------------------- */ }

        <HStack p="5">
          <Divider bg={colorDivider} />
            <Text fontSize="lg" fontWeight="medium" whiteSpace="nowrap" color={colorSeccion} >
              {KContratos._SECCION_IV}
            </Text>
          <Divider bg={colorDivider} />
        </HStack>

        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '1.5', md: '8' }}
          isInline={true}
        >
          { /* ctto_observaciones ----------------------------------------------------------- */ }
          <FormControl isInvalid={errors.ctto_observaciones?true:false}>
            <FormLabel fontSize="xs" color={colorLabel} >
              <HStack>
                <Text>{KContratos._F_OBSERVACIONES}</Text>
                <Tooltip label={tooltipAyuda('ctto_observaciones', tAyuda)}><Icon /></Tooltip>
              </HStack>
            </FormLabel>
            <Textarea 
              bg={bgInput} 
              size='sm' 
              defaultValue={datos.ctto_ctto_observaciones} 
              readOnly={readOnlyInput}
              { ...register('ctto_observaciones', 
                { 
                  maxLength: { value: vParametros.ctto_observaciones_lmax, message: `${KV._LARGO_MAX} ${vParametros.ctto_observaciones_lmax}` },
                  validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                })
              }
            />
            <FormErrorMessage fontSize="10">
              {errors.ctto_observaciones && errors.ctto_observaciones.message}
            </FormErrorMessage>                
          </FormControl>

          { /* ctto_msje_interno ----------------------------------------------------------- */ }
          <FormControl isInvalid={errors.ctto_msje_interno?true:false}>
            <FormLabel fontSize="xs" color={colorLabel} >
              <HStack>
                <Text>{KContratos._F_MSJE_INT_CCV}</Text>
                <Tooltip label={tooltipAyuda('ctto_msje_interno', tAyuda)}><Icon /></Tooltip>
              </HStack>
            </FormLabel>
            <Textarea 
              bg={bgInput} 
              size='sm' 
              defaultValue={datos.ctto_ctto_msje_interno} 
              readOnly={readOnlyInput}
              { ...register('ctto_msje_interno', 
                { 
                  maxLength: { value: vParametros.ctto_mensajes_lmax, message: `${KV._LARGO_MAX} ${vParametros.ctto_mensajes_lmax}` },
                  validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                })
              }
            />
            <FormErrorMessage fontSize="10">
              {errors.ctto_msje_interno && errors.ctto_msje_interno.message}
            </FormErrorMessage>                
          </FormControl>
        </Stack>

        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '1.5', md: '8' }}
          isInline={true}
        >
          { /* ctto_msje_a_proveedor ----------------------------------------------------------- */ }
          <FormControl isInvalid={errors.ctto_msje_a_proveedor?true:false}>
            <FormLabel fontSize="xs" color={colorLabel} >
              <HStack>
                <Text>{KContratos._F_MSJE_A_PROV}</Text>
                <Tooltip label={tooltipAyuda('ctto_msje_a_proveedor', tAyuda)}><Icon /></Tooltip>
              </HStack>
            </FormLabel>
            <Textarea 
              bg={bgInput} 
              size='sm' 
              defaultValue={datos.ctto_ctto_msje_a_proveedor} 
              readOnly={readOnlyInput}
              { ...register('ctto_msje_a_proveedor', 
                { 
                  maxLength: { value: vParametros.ctto_mensajes_lmax, message: `${KV._LARGO_MAX} ${vParametros.ctto_mensajes_lmax}` },
                  validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                })
              }
            />
            <FormErrorMessage fontSize="10">
              {errors.ctto_msje_a_proveedor && errors.ctto_msje_a_proveedor.message}
            </FormErrorMessage>                
          </FormControl>
          
          { /* ctto_msje_de_proveedor ----------------------------------------------------------- */ }
          <FormControl isInvalid={errors.ctto_msje_de_proveedor?true:false}>
            <FormLabel fontSize="xs" color={colorLabel}>
              <HStack>
                <Text>{KContratos._F_MSJE_DE_PROV}</Text>
                <Tooltip label={tooltipAyuda('ctto_msje_de_proveedor', tAyuda)}><Icon /></Tooltip>
              </HStack>
            </FormLabel>
            <Textarea 
              bg={bgInput} 
              size='sm' 
              defaultValue={datos.ctto_ctto_msje_de_proveedor} 
              readOnly={readOnlyMsjeDeProv}
              { ...register('ctto_msje_de_proveedor', 
                { 
                  maxLength: { value: vParametros.ctto_mensajes_lmax, message: `${KV._LARGO_MAX} ${vParametros.ctto_mensajes_lmax}` },
                  validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                })
              }
            />
            <FormErrorMessage fontSize="10">
              {errors.ctto_msje_de_proveedor && errors.ctto_msje_de_proveedor.message}
            </FormErrorMessage>                
          </FormControl>
        </Stack>
        </Stack>

        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '1.5', md: '8' }}
          isInline={true}
        >
          { /* ctto_msje_de_firma1 ----------------------------------------------------------- */ }
          <FormControl isInvalid={errors.ctto_msje_de_firma1?true:false}>
            <FormLabel fontSize="xs" color={colorLabel}>
              <HStack>
                <Text>{KContratos._F_MSJE_DE_FIRMA1}</Text>
                <Tooltip label={tooltipAyuda('ctto_msje_de_firma1', tAyuda)}><Icon /></Tooltip>
              </HStack>
            </FormLabel>
            <Textarea 
              bg={bgInput} 
              size='sm' 
              defaultValue={datos.ctto_ctto_msje_de_firma1} 
              readOnly={readOnlyMsjeDeFirma1}
              { ...register('ctto_msje_de_firma1', 
                { 
                  maxLength: { value: vParametros.ctto_mensajes_lmax, message: `${KV._LARGO_MAX} ${vParametros.ctto_mensajes_lmax}` },
                  validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                })
              }
            />
            <FormErrorMessage fontSize="10">
              {errors.ctto_msje_de_firma1 && errors.ctto_msje_de_firma1.message}
            </FormErrorMessage>
          </FormControl>
          
          { /* ctto_msje_de_firma2 ----------------------------------------------------------- */ }
          <FormControl isInvalid={errors.ctto_msje_de_firma2?true:false}>
            <FormLabel fontSize="xs" color={colorLabel}>
              <HStack>
                <Text>{KContratos._F_MSJE_DE_FIRMA2}</Text>
                <Tooltip label={tooltipAyuda('ctto_msje_de_firma2', tAyuda)}><Icon /></Tooltip>
              </HStack>
            </FormLabel>
            <Textarea 
              bg={bgInput} 
              size='sm' 
              defaultValue={datos.ctto_ctto_msje_de_firma2} 
              readOnly={readOnlyMsjeDeFirma2}
              { ...register('ctto_msje_de_firma2', 
                { 
                  maxLength: { value: vParametros.ctto_mensajes_lmax, message: `${KV._LARGO_MAX} ${vParametros.ctto_mensajes_lmax}` },
                  validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                })
              }
            />
            <FormErrorMessage fontSize="10">
              {errors.ctto_msje_de_firma2 && errors.ctto_msje_de_firma2.message}
            </FormErrorMessage>
          </FormControl>
        </Stack>

        { /* ---------------------------------------------------------------------------------------- */ }
        { /* BOTONES ACCIONES FLUJO ----------------------------------------------------------------- */ }
        { /* ---------------------------------------------------------------------------------------- */ }

        <VStack p="5">

          <Divider bg={colorDivider} />

          <ContratoFlujoAcciones
            accion={accion}
            cancelRef={cancelRef}
            onClose={onClose}
            isOpen={isOpen}
            handleSubmit={handleSubmit}
            onEliminar={onEliminar}
            onAutorizar={onAutorizar}
            onRechazar={onRechazar}
            onRechABorrador={onRechABorrador}
            onFirmar1ok={onFirmar1ok}
            onFirmarPok={onFirmarPok}
            onFirmar2ok={onFirmar2ok}
            onClickEliminar={onClickEliminar}
            onClickAutorizar={onClickAutorizar}
            onClickFirmar1ok={onClickFirmar1ok}
            onClickFirmarPok={onClickFirmarPok}
            onClickFirmar2ok={onClickFirmar2ok}
            onClickRechazar={onClickRechazar} 
            onClickRechABorrador={onClickRechABorrador}
            tTCTranscionesCtto={tTCTranscionesCtto}
            isSubmitting={isSubmitting}
            isValid={isValid}
            esContingencia={vParametros.ctto_contingencia_manual}
          />

          <Stack p="5">
          {
            <>
              { mostrarMensajeOK && <Textarea readOnly={true} bg={colorBGok} color={colorTextMensajes} value={`${mensajeActualizar}`} w='2xl'/> }
              { mostrarMensajeError && <Textarea isInvalid readOnly={true} bg={colorBGErrores} color={colorTextMensajes} value={`${mensajeActualizar}`} w='2xl'/> }
            </>
          }
          </Stack>
          
        </VStack>
      </form>
    )

}

export default ContratoEditarForm;
