import { useParams } from "react-router-dom"
import FormPNaturalEditar from './PNaturalEditar';
import FormPNaturalCrear from './PNaturalCrear';
import { K } from "../../utils/constantes";

const PNaturalMant = () => {

  const params = useParams();
  const esNuevo = ( params && params.rut && (params.rut === K._NUEVO) );

  return (
    (!params || !params.rut)
    ?
      null
    : (
        esNuevo
        ?
        <FormPNaturalCrear />
        :
        <FormPNaturalEditar rut={params.rut}  />
      )
  )
}

export default PNaturalMant;