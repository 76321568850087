import CuadroResumen from './CuadroResumen'
import PendientesFirma from './PendientesFirma'
import Rechazados from './Rechazados';
import Borrador from './Borrador';
import { useContext } from 'react';
import {
    Container,
    useColorModeValue,
    VStack,
  } from '@chakra-ui/react'
import { TablasContext } from '../../context/TablasContext';
// import MisPendientes from '../misPendientes/MisPendientes';

const PanelControl = () => {

  const { tAplicacionesPermisos } = useContext( TablasContext );

    return (
      <Container 
        py={{ base: '1', md: '1' }} 
        px={{ base: '1', md: '1' }} 
        maxW='5xl' 
        bg={useColorModeValue("gray.200", "gray.900")}
        alignItems='left'
      >
        <VStack 
          spacing={2}
          bg={useColorModeValue("gray.200", "gray.900")}
          alignItems='left'
        >
          <CuadroResumen tAplicacionesPermisos={ tAplicacionesPermisos } />
          {/* <MisPendientes tAplicacionesPermisos={ tAplicacionesPermisos } /> */}
          <PendientesFirma tAplicacionesPermisos={ tAplicacionesPermisos } />
          <Rechazados tAplicacionesPermisos={ tAplicacionesPermisos } />
          <Borrador tAplicacionesPermisos={ tAplicacionesPermisos } />
        </VStack>              

      </Container>
    )
  }

export default PanelControl;
