export const _APPS_VERSION = 'v1.6';
export const _APPS_NOMBRE = 'Gestión de Contratos';
export const _APPS_BUILD = 'build 230307';

//${process.env._REACT_APP_BASE_URL_API}
export const _REACT_APP_BASE_URL_API_DESA = 'https://api.qa.contratos.vitacuracultura.cl/api/v1';
export const _REACT_APP_BASE_URL_API_PROD = 'https://api.contratos.vitacuracultura.cl/api/v1';

export const _AMBIENTE_DESA = 'desa';
export const _AMBIENTE_QA = 'qa';
export const _AMBIENTE_PROD = 'prod';

export const _HOSTNAME_LOCALHOST = 'localhost';
export const _HOSTNAME_CONTRATOS_QA = 'contratos.qa.vitacuracultura.cl';
export const _HOSTNAME_CONTRATOS_QA_P = 'pcontratos.qa.vitacuracultura.cl';
export const _HOSTNAME_CONTRATOS_PROD = 'contratos.vitacuracultura.cl';
export const _HOSTNAME_CONTRATOS_PROD_P = 'pcontratos.vitacuracultura.cl';

export const _URL_CONTRATOS_INICIO_PROD = 'https://contratos.vitacuracultura.cl/arch/contratos/inicio/';
export const _URL_CONTRATOS_INICIO_DESA = 'https://contratos.qa.vitacuracultura.cl/arch/contratos/inicio/';

export const KErroresSubirContrato = [
    { codigo:10, mensaje:'No existe contrato en tabla de contratos'},
    { codigo:30, mensaje:'Parámetro no puede estar vacío'},
    { codigo:40, mensaje:'Parámetro no puede ser nulo'},
    { codigo:70, mensaje:'Parámetro debe ser numérico'},
    { codigo:100, mensaje:'Actualización de contrato ha fallado'},
    { codigo:110, mensaje:'No se logró mover archivo a la carpeta de error'},
    { codigo:210, mensaje:'No se logró cargar documento PDF para validación de firma'},
    { codigo:220, mensaje:'El contrato no contiene firmas requeridas'},
    { codigo:230, mensaje:'La firma del documento no es válida'},
    { codigo:240, mensaje:'Identificador de clave de autoridad no registrado (AuthorityKeyId)'},
    { codigo:250, mensaje:'El certificado ha expirado'},
    { codigo:260, mensaje:'El rut no se encuentra en el certificado'},
    { codigo:270, mensaje:'El contrato no contiene la cantidad de firmas requeridas'},
    { codigo:720, mensaje:'La firma del documento no es válida'},
    { codigo:999, mensaje:'Ha ocurrido un error al intentar subir el contrato. Por favor avise a soporte'},
]

export const KErroresSubirAdjunto = [
    { codigo:10, mensaje:'No existe contrato en tabla de contratos'},
    { codigo:30, mensaje:'Parámetro no puede estar vacío'},
    { codigo:40, mensaje:'Parámetro no puede ser nulo'},
    { codigo:70, mensaje:'Parámetro debe ser numérico'},
    { codigo:310, mensaje:'No se envió archivo'},
    { codigo:320, mensaje:'El archivo no se logró subir'},
    { codigo:330, mensaje:'No se logró mover archivo a la carpeta destino'},
    { codigo:340, mensaje:'Creación de adjunto ha fallado'},
    { codigo:999, mensaje:'Ha ocurrido un error al intentar subir el adjunto. Por favor avise a soporte'},
]

export const KErroresDescargarContrato = [
	{ codigo:10, mensaje:'No existe contrato para la bitacora indicada' },
	{ codigo:20, mensaje:'No existe contrato' },
	{ codigo:30, mensaje:'Parámetro no puede estar vacío' },
	{ codigo:40, mensaje:'Parámetro no puede ser nulo' },
	{ codigo:70, mensaje:'Parámetro debe ser numérico' },
	{ codigo:210, mensaje:'Recuperación del contrato ha fallado' },
	{ codigo:220, mensaje:'Archivo del contrato no existe' },
	{ codigo:230, mensaje:'Recuperación del contrato ha fallado. No se puede leer archivo' },
    { codigo:999, mensaje:'Ha ocurrido un error al intentar descargar el contrato. Por favor avise a soporte'},
]

export const KErroresDescargarContratoAdjunto = [
	{ codigo:10, mensaje:'No existe adjunto en tabla de adjuntos de contrato' },
	{ codigo:30, mensaje:'Parámetro no puede estar vacío' },
	{ codigo:40, mensaje:'Parámetro no puede ser nulo' },
	{ codigo:70, mensaje:'Parámetro debe ser numérico' },
	{ codigo:210, mensaje:'Recuperación del adjunto ha fallado' },
	{ codigo:220, mensaje:'Archivo adjunto no existe' },
	{ codigo:230, mensaje:'Recuperación del adjunto ha fallado. No se puede leer archivo' },
    { codigo:999, mensaje:'Ha ocurrido un error al intentar descargar el adjunto. Por favor avise a soporte'},
]

export const KErroresDescargarProveedorAdjunto = [
	{ codigo:10, mensaje:'No existen adjuntos para rut' },
	{ codigo:20, mensaje:'No contiene alguno de los valores permitidos (pers_natural ó pers_juridica)' },
	{ codigo:30, mensaje:'Parámetro no puede estar vacío' },
	{ codigo:40, mensaje:'Parámetro no puede ser nulo' },
	{ codigo:70, mensaje:'Parámetro debe ser numérico' },
	{ codigo:210, mensaje:'Recuperación del adjunto ha fallado' },
	{ codigo:220, mensaje:'Archivo adjunto no existe' },
	{ codigo:230, mensaje:'Recuperación del adjunto ha fallado. No se puede leer archivo' },
    { codigo:999, mensaje:'Ha ocurrido un error al intentar descargar el adjunto. Por favor avise a soporte'},
]

export const KApps = {
    _PANEL_CONTROL: 'panel_control',
    _CONTRATOS_FLUJO: 'contratos_flujo',
    _ADMIN_TABLAS: 'admin_tablas',
    _ADMIN_USUARIOS: 'admin_usuarios',
    _PROV_PERSJURIDICA: 'prov_persjuridica',
    _PROV_PERSNATURAL: 'prov_persnatural',
    _DOWNLOAD: 'dw',
}

export const K = {
    _CODE_ERROR_500_API: 999,
    _CARGANDO: 'Cargando...',
    _CTTONOEXISTE: '(contrato no existe, ha sido eliminado o no dispone de permiso)',
    _NOHAYDATOS: '(no hay datos)',
    _NOHAYDATOS_F: '(no hay datos para los filtros indicados)',
    _NOHAYPDTES: '(no tienes pendientes)',
    _SI: 'S',
    _NO: 'N',
    _NUEVO: 'nuevo',
    _COLOR_TITULO_L: 'teal',
    _COLOR_TITULO_D: 'whiteAlpha.900',
    _COLOR_SUBTITULO_L: 'teal',
    _COLOR_SUBTITULO_D: 'white',
    _COLOR_NROLINEA_L: 'gray.400',
    _COLOR_NROLINEA_D: 'gray.500',
    _COLOR_TH_L: 'gray.100',
    _COLOR_TH_D: 'gray.600',
    _COLOR_LINK_L: 'blue',
    _COLOR_LINK_D: 'cyan',
    _COLOR_SECCION_L: 'blue',
    _COLOR_SECCION_D: 'cyan',
    _COLOR_DIVIDER_L: 'green',
    _COLOR_DIVIDER_D: 'teal',
    _SELE_ID: '_seleccionar',
    _SELE_TEXTO: '( seleccionar )',
    _TODO_ID: '_todos',
    _TODO_TEXTO: '( todos )',
    _IGNORAR_ID: '_ignorar',
    _PNPJ_ID_DEFAULT: 'pers_natural',
    _PNPJ_ID_PNATURAL: 'pers_natural',
    _PNPJ_ID_PJURIDICAL: 'pers_juridica',
    _MONE_ID_DEFAULT: 'CLP',
    _MONE_SIM_DEFAULT: '$',
    _MONE_INT_DEFAULT: 'es-CL',
    _FEMENINO: 'F',
    _MASCULINO: 'M',
    _FEMENINO_PRES: 'doña',
    _MASCULINO_PRES: 'don',
    _FEMENINO_FNAC: 'nacida el',
    _MASCULINO_FNAC: 'nacido el',
    _REMATE_MONTO: '.-',
    _MSJE_TOTAL: 'TOTAL',
    _MSJE_ERRORES: 'ERRORES',
    _MSJE_ERROR: '*** ERROR ***',
    _MSJE_VACIO: '***[ERROR: CAMPO VACIO]***',
    _MSJE_NOENCONTRADO: 'ELEMENTO NO ENCONTRADO',
    _TABLA_CAMPO_NOMBRE: 'nombre',
    _TABLA_CAMPO_DESCRIPCION: 'descripcion',
    _TABLA_CAMPO_STR1: 'str1',
    _TABLA_CAMPO_STR2: 'str2',
    _TABLA_CAMPO_STR3: 'str3',
    _TCON_ID_DEFAULT: 'expositor_persnatural',
    _LARGO_TEXT_CONSULTAS: 30,
    _LARGO_TEXT_CONSULTAS_ERROR: 60,
    _SOPORTE_NOMBRE: 'Soporte - Gestión de Contratos',
    _SOPORTE_MSJE_TITULO_EXITO: 'Operación exitosa\n',
    _SOPORTE_MSJE_TITULO_FRACASO: 'Operación ha fallado\n',
    _SOPORTE_MSJE_CORREO_EXITO: 'El correo a soporte ha sido enviado\n',
    _SOPORTE_MSJE_CORREO_FRACASO: 'El correo a soporte no pudo ser enviado\n',
    _NOTIFICACION_NOMBRE: 'Gestión de Contratos - Vitacura Cultura',
    _DURACION_TOAST_DEFAULT: 8000,
    _DURACION_TOAST_DEFAULT_CORTA: 3000,
    _DURACION_TOAST_DEFAULT_LARGA: 15000,
    _CORREO_SOPORTE_DEFAULT: 'soporte@vitacuracultura.cl',
    _CORREO_CCO_DEFAULT: 'cmorist@gmail.com',
    _CONTINGENCIA_TODO: '*',
    _CONTINGENCIA_FIRMA_1_OK: 'firma_1_ok',
    _CONTINGENCIA_FIRMA_2_OK: 'firma_2_ok',
    _CONTINGENCIA_FIRMA_PROVEEDOR_OK: 'firma_proveedor_ok',
    _CONTINGENCIA_RECHZAR: 'rechazar',
    _TADJ_ID_DECL_JURADA: 'declaracion_jurada_',
    _TADJ_ID_COTIZACION: 'cotizacion',
    _TADJ_ID_MEMO_CONDUCTOR: 'memo_conductor',
    _TADJ_ID_PROPUESTA: 'propuesta_tallerista',
    _TADJ_ID_CUMPLIMIENTO: 'cumplimiento',
}

export const KPerfiles = {
    _PERF_PNAT_USUARIO: 'pnat_usuario',
    _PERF_GERENTE_FINANZAS: 'gerente_finanzas',
    _PERF_DIRECCION_EJECUTIVA: 'direccion_ejecutiva',
    _PERF_ADMIN_SUPERUSUARIO: 'admin_superusuario',
}

export const KParametros = {
    ctto_rut_lmin: 'ctto_rut_lmin',
    ctto_rut_lmax: 'ctto_rut_lmax',
    ctto_fecha_ppal_dias_atras: 'ctto_fecha_ppal_dias_atras',
    ctto_fecha_ppal_dias_adelante: 'ctto_fecha_ppal_dias_adelante',
    ctto_fecha_fin_dias_atras: 'ctto_fecha_fin_dias_atras',
    ctto_fecha_fin_dias_adelante: 'ctto_fecha_fin_dias_adelante',
    ctto_fecha_ini_dias_atras: 'ctto_fecha_ini_dias_atras',
    ctto_fecha_ini_dias_adelante: 'ctto_fecha_ini_dias_adelante',
    ctto_nombre_lmin: 'ctto_nombre_lmin',
    ctto_nombre_lmax: 'ctto_nombre_lmax',
    ctto_objetivo_lmin: 'ctto_objetivo_lmin',
    ctto_objetivo_lmax: 'ctto_objetivo_lmax',
    ctto_descripcion_lmin: 'ctto_descripcion_lmin',
    ctto_descripcion_lmax: 'ctto_descripcion_lmax',
    ctto_desc_pago_lmin: 'ctto_desc_pago_lmin',
    ctto_desc_pago_lmax: 'ctto_desc_pago_lmax',
    ctto_observaciones_lmax: 'ctto_observaciones_lmax',
    ctto_mensajes_lmax: 'ctto_mensajes_lmax',
    ctto_pcjte_mayusculas: 'ctto_pcjte_mayusculas',
    ctto_adjunto_extensiones_list: 'ctto_adjunto_extensiones_list',
    ctto_adjunto_size_max: 'ctto_adjunto_size_max',
    correo_soporte_para: 'correo_soporte_para',
    correo_soporte_cco: 'correo_soporte_cco',
    correo_soporte_lmin: 'correo_soporte_lmin',
    correo_soporte_lmax: 'correo_soporte_lmax',
    notif_ctto_pn_enviado_a_firma1: 'notif_ctto_pn_enviado_a_firma1',
    notif_ctto_pn_firma1_ok: 'notif_ctto_pn_firma1_ok',
    notif_ctto_pn_firmap_ok: 'notif_ctto_pn_firmap_ok',
    notif_ctto_pn_firma2_ok: 'notif_ctto_pn_firma2_ok',
    notif_ctto_rechazo: 'notif_ctto_rechazo',
    ctto_contingencia_manual: 'ctto_contingencia_manual',
}

export const KCasosTransicion = {
    _CASO_TRANS_ENVIAR_A_FIRMA: 'enviar_a_firma',
    _CASO_TRANS_FIRMA1_OK: 'firma_1_ok',
    _CASO_TRANS_FIRMA1_RECHAZO: 'firma_1_rechazo',
    _CASO_TRANS_FIRMAP_OK:  'firma_proveedor_ok',
    _CASO_TRANS_FIRMAP_RECHAZO:  'firma_proveedor_rechazo',
    _CASO_TRANS_FIRMA2_OK: 'firma_2_ok',
    _CASO_TRANS_FIRMA2_RECHAZO: 'firma_2_rechazo',
    _CASO_TRANS_RECHAZO_BORRADOR: 'rechazo_borrador',
}

export const KPNatural = {
    _PNC_TITULO: 'Proveedores (Personas Naturales)',
    _PNC_RUT_PROV: 'RUT',
    _PNC_NOMBRE_PROV: 'Nombre o Apellido',
    _PN_TITU_EDITAR: 'Proveedor / Persona Natural [editar]',
    _PN_TITU_CREAR: 'Proveedor / Persona Natural [crear]',
    _PN_RUT: 'RUT',
    _PN_NOMBRE1: 'Primer nombre',
    _PN_NOMBRE2: 'Segundo nombre',
    _PN_APELLIDO1: 'Primer apellido',
    _PN_APELLIDO2: 'Segundo apellido',
    _PN_ALIAS: 'Alias nombre',
    _PN_CORREO: 'Correo electrónico',
    _PN_GENERO: 'Género',
    _PN_TELEFONOS: 'Teléfonos',
    _PN_FECHANAC: 'Fecha Nacimiento',
    _PN_ECIVIL: 'Estado Civil',
    _PN_NACIONALIDAD: 'Nacionalidad',
    _PN_PROFESION: 'Profesión',
    _PN_DIRECCION: 'Dirección',
    _PN_REGION: 'Región',
    _PN_COMUNA: 'Comuna',
    _PN_BANCO: 'Banco',
    _PN_NROCUENTA: 'Nro. de cuenta',
    _PN_TIPOCUENTA: 'Tipo de cuenta',
    _PN_DECL_JURADA_FECHA: 'Declaración Jurada',
    _PN_CGESTION: 'Programa (principal)',
    _PN_BOTON_CREAR: 'Crear Persona',
    _PN_BOTON_MODIFICAR: 'Grabar Cambios',
    _PN_MSJE_TITULO_EXITO: 'Operación exitosa\n',
    _PN_MSJE_TITULO_FRACASO: 'Operación ha fallado\n',
    _PN_MSJE_MODIF_EXITO: 'Actualización de persona ha sido exitosa\n',
    _PN_MSJE_MODIF_FRACASO: 'Grabación ha fallado:\nSe produjo error [PN-M-10] al intentar grabar cambios.\nAvise a soporte indicando el código de error, RUT de la persona y cualquier información que pueda ser de utilidad.',
    _PN_MSJE_CREAR_EXITO: 'Creación de persona ha sido exitosa\n',
    _PN_MSJE_CREAR_FRACASO: 'Creación ha fallado:\nSe produjo error [PN-C-10] al intentar crear persona.\nAvise a soporte indicando el código de error, RUT de la persona y cualquier información que pueda ser de utilidad.',
    _PN_MSJE_DECL_JURADA: 'Debe ser del año en curso',

    _PC_TH_APELLIDO: 'Apellido',
    _PC_TH_NOMBRE: 'Nombre',
    _PC_TH_ID: 'ID',
    _PC_TH_RUT: 'RUT',
    _PC_TH_DECL_JUR_1: 'Decl.',
    _PC_TH_DECL_JUR_2: 'Jur.',
    _PC_TH_CORREO: 'Correo',
    _PC_TH_CONEXION: 'Conx.',
    _PC_TH_GENERO: 'Gnro.',
    _PC_TH_TELEFONO: 'Fono',
    _PC_TH_BANCO: 'Bco.',
    _PC_TH_TIPO_CTA_1: 'Tipo',
    _PC_TH_TIPO_CTA_2: 'Cta.',
    _PC_TH_NRO_CUENTA_1: 'Nro.',
    _PC_TH_NRO_CUENTA_2: 'Cta.',
    _PC_TH_PROFESION: 'Prof.',
    _PC_TH_FECHA_NAC_1: 'Fec.',
    _PC_TH_FECHA_NAC_2: 'Nac.',
    _PC_TH_DIRECCION: 'Dir.',
    _PC_TH_NACIONALIDAD: 'Nac.',
    _PC_TH_ECIVIL_1: 'Est.',
    _PC_TH_ECIVIL_2: 'Civil',
    _PC_TH_COMUNA: 'Com.',
    _PC_TH_REGION: 'Reg.',

    _PC_TT_DECL_JUR: 'declaración jurada vigente',
    _PC_TT_CORREO: 'correo electrónico',
    _PC_TT_TELEFONO: 'teléfono(s)',
    _PC_TT_CONEXION: 'habilitado para operar',
    _PC_TT_BANCO: 'banco para transferencias',
    _PC_TT_NRO_CUENTA: 'nro. de cuenta para transferencias',
    _PC_TT_TIPO_CUENTA: 'tipo de cuenta para transferencias',
    _PC_TT_PROFESION: 'profesión',
    _PC_TT_GENERO: 'género',
    _PC_TT_NACIONALIDAD: 'nacionalidad',
    _PC_TT_ECIVIL: 'estado civil',
    _PC_TT_COMUNA: 'dirección - comuna',
    _PC_TT_REGION: 'dirección - región',
    _PC_TT_DIRECCION: 'dirección',
    _PC_TT_FECHA_NAC: 'fecha de nacimiento',

    _PN_MSJE_V_SIN_DATOS: '- No hay datos para revisar',
    _PN_MSJE_V_PNAT_CORREO: '- Persona sin correo',
    _PN_MSJE_V_PNAT_TELEFONO: '- Persona sin teléfono',
    _PN_MSJE_V_PNAT_BANCO: '- Persona sin banco',
    _PN_MSJE_V_PNAT_CUENTA_NRO: '- Persona sin nro de cuenta (banco)',
    _PN_MSJE_V_PNAT_CUENTA_TIPO: '- Persona sin tipo de cuenta bancaria',
    _PN_MSJE_V_PNAT_GENERO: '- Persona sin género asignado',
    _PN_MSJE_V_PNAT_FEC_NAC: '- Persona sin fecha de nacimiento',
    _PN_MSJE_V_PNAT_NACI_ID: '- Persona sin nacionalidad',
    _PN_MSJE_V_PNAT_PROFESION: '- Persona sin profesión',
    _PN_MSJE_V_PNAT_DIRECCION: '- Persona sin dirección',
    _PN_MSJE_V_PNAT_COMU_ID: '- Persona sin comuna (dirección)',
    _PN_MSJE_V_PNAT_REGI_ID: '- Persona sin región (dirección)',
    _PN_MSJE_V_PNAT_ECIV_ID: '- Persona sin estado civil',
    _PN_MSJE_V_PNAT_DECL_JURADA: 'Persona sin declaración jurada vigente.',
  
    _PN_AD_TITULO: 'Documentos Adjuntos Proveedor',
    _PN_AD_FECHA: 'Fecha',
    _PN_AD_TIPO: 'Tipo',
    _PN_AD_NOMBRE: 'Nombre',
    _PN_AD_DESCRIPCION: 'Descripción',
    _PN_AD_DOCUMENTO: 'Documento',
    _PN_AD_DESCARGAR: 'Descargar',
    _PN_AD_ELIMINAR: 'Eliminar',
    _PN_AD_BOTON_DESCARGAR: '(descargar)',
    _PN_AD_MSJE_ERROR_BITACORA: 'Se produjo un error al intentar leer adjuntos',
    _PN_AD_MSJE_TITULO_EXITO: 'Operación exitosa\n',
    _PN_AD_MSJE_TITULO_FRACASO: 'Error al subir archivo\n',
    _PN_AD_MSJE_TITULO_FRACASO_DESCARGAR: 'Error al descargar archivo\n',
    _PN_AD_MSJE_ADJUNTAR_EXITO: 'El adjunto ha sido subido\n',
    _PN_AD_MSJE_ADJUNTAR_FRACASO: 'Se detectaron errores al intentar subir el archivo\n',
    _PN_AD_MSJE_DESCARGAR_FRACASO: 'Se detectaron errores al intentar subir el archivo\n',
    _PN_AD_TIPO_SEL: 'Tipo Adjunto',
    _PN_AD_BOTON_SUBIR: 'Subir adjunto',
    _PN_AD_DECL_TITULO: 'Instrucciones para subir Declaración Jurada:',
    _PN_AD_DECL_TEXTO: 'Descarga el formulario indicado más abajo, completa con sus datos, genera el pdf y firma con tu firma electrónica avanzada. Luego sube el documento pdf firmado, asociado al tipo Declaración Jurada',
    _PN_AD_DECL_LINK: 'descargar formulario de declaración jurada',
    _PN_AD_MSJE_ERROR_ADJUNTOS: 'Se produjo un error al intentar leer documentos adjuntos',

}

export const KV = {
    _LARGO_MIN: 'largo mínimo',
    _LARGO_MAX: 'largo máximo',
    _CAMPO_OBL: '(campo obligatorio)',
    _FORMATO_CORREO: '(formato correo)',
    _MSJE_SELECCIONE: 'seleccione',
    _RUT_INVALIDO: 'RUT no es válido',
    _CORREO_INVALIDO: 'Correo no es válido',
    _MAYOR_18: 'Debe ser mayor de 18 años',
    _RUT_LMIN: 8,
    _RUT_LMAX: 20,
    _PCTJE_MAYUSCULAS: 50,
    _MSJE_PCTJE_MAYUSCULAS: 'Demasiadas mayúsculas',
    _CTTO_FECHA_PPAL_TOPE_MIN: 30, // 1 mes atrás
    _CTTO_FECHA_PPAL_TOPE_MAX: 30, // 1 mes adelante
    _CTTO_FECHA_INI_TOPE_MIN: 180, // 6 meses atrás
    _CTTO_FECHA_INI_TOPE_MAX: 180, // 6 meses adelante
    _CTTO_FECHA_FIN_TOPE_MIN: 365, // 6 meses atrás
    _CTTO_FECHA_FIN_TOPE_MAX: 365, // 6 meses adelante
    _CTTO_MSJE_FECHA_PPAL: 'Fuera de rango',
    _CTTO_MSJE_FECHA_INI: 'Fuera de rango',
    _CTTO_MSJE_FECHA_FIN: 'Fuera de rango',
    _CTTO_MSJE_MONTO_TOTAL_M0: 'Monto debe ser mayor que 0',
    _CTTO_NOMBRE_LMIN: 10,
    _CTTO_NOMBRE_LMAX: 200,
    _CTTO_OBJETIVO_LMIN: 10,
    _CTTO_OBJETIVO_LMAX: 1000,
    _CTTO_DESCRIPCION_LMIN: 10,
    _CTTO_DESCRIPCION_LMAX: 2000,
    _CTTO_DESC_PAGO_LMIN: 10,
    _CTTO_DESC_PAGO_LMAX: 1000,
    _CTTO_MSJE_INTERNO_LMAX: 500,
    _CTTO_MSJE_A_PROV_LMAX: 500,
    _CTTO_MSJE_DE_PROV_LMAX: 500,
    _CTTO_MSJE_DE_FIRMA1_LMAX: 500,
    _CTTO_MSJE_DE_FIRMA2_LMAX: 500,
    _CTTO_OBSERVACIONES_LMAX: 500,
    _CTTO_MENSAJES_LMAX: 500,
    _CTTO_FECHA_LMIN: 10,
    _CTTO_FECHA_LMAX: 10,
    _CTTO_MSJE_RUT_YA_EXISTE: '*** RUT existe. No puede volver a crearlo ***',
    _CTTO_MSJE_RUT_NO_EXISTE: '*** RUT no existe en Proveedores o es erróneo ***',
    _CTTO_MSJE_RUT_ERROR: '*** Se produjo un error de acceso  ***',
    _CTTO_MSJE_RUT_VACIO: '*** Ingrese un Rut ***',
    _CTTO_TITULO_CONSULTA_RUT: 'Consulta RUT',
    _CTTO_ADJUNTO_SIZE_MAX: 1048576,
    _CTTO_ADJUNTO_EXTE_DEFAULT: 'pdf',
    _CTTO_ADJUNTO_EXTE_CTTO: 'pdf',
    _CTTO_ADJUNTO_EXTE_LIST: 'pdf, zip',
    _CTTO_MSJE_FECHA_PPAL_NODEF: '- Fecha de contrato no está definida',
    _CTTO_MSJE_FECHA_INI_NODEF: '- Fecha de inicio no está definida',
    _CTTO_MSJE_FECHA_FIN_NODEF: '- Fecha de término no está definida',
    _CTTO_MSJE_PPAL_VS_INI: '- Fecha de contrato debe ser menor o igual a fecha de inicio de servicios',
    _CTTO_MSJE_INI_VS_FIN: '- Fecha de inicio de servicios debe ser menor o igual a fecha de término de servicio',
    _CTTO_MSJE_ANO_CALENDARIO: '- Las fechas deben corresponder al mismo año calendario',
    _CTTO_MSJE_ADJUNTO_AVISO: 'AVISO',
    _CTTO_MSJE_ADJUNTO_EXTENSION: '- La extensión del archivo no está permitida: ',
    _CTTO_MSJE_ADJUNTO_SIZE: '- El tamaño del archivo excede el permitido: ',
    _CTTO_MSJE_ADJUNTO_VACIO: '- Seleccione un archivo ',
    _CTTO_MSJE_ADJUNTO_INPUT: 'Revise tamaño y/o extensión permitods',
    _CTTO_MSJE_ADJUNTO_EXT_NULA: '- La extensión del archivo no está permitida',
}

export const KEstado = {
    _ELIMINADO: 'eliminado',
    _BORRADOR: 'borrador',
    _RECHAZADO: 'rechazado',
    _ENVIADO_A_FIRMA_1: 'enviado_a_firma_1',
    _FIRMA_1_OK: 'firma_1_ok',
    _FIRMA_PROVEEDOR_OK: 'firma_proveedor_ok',
    _FIRMA_2_OK: 'firma_2_ok',

    _PENDIENTE: 'pendiente',
    _TOTAL: 'total',

    _BORRADOR_P: 'B',
    _RECHAZADO_P: 'R',
    _ENVIADO_A_FIRMA_1_P: 'F1',
    _FIRMA_1_OK_P: 'FP',
    _FIRMA_PROVEEDOR_OK_P: 'F2',
    _FIRMA_2_OK_P: 'Z',
    _PENDIENTES_P: 'P',
    _TODOS_P: 'T',

    _BORRADOR_T1: 'Borrador',
    _RECHAZADO_T1: 'Rechazado',
    _ENVIADO_A_FIRMA_1_T1: 'En firma #1 CCV',
    _FIRMA_1_OK_T1: 'En Proveedor',
    _FIRMA_PROVEEDOR_OK_T1: 'En firma #2 CCV',
    _FIRMA_2_OK_T1: 'Finalizado',
}

export const KConexion = {
    _CONX_LABEL_USUARIO: 'Usuario o RUT',
    _CONX_MSJE_INGR_USUARIO: 'ingresa tu usuario o RUT',
    _CONX_LABEL_CLAVE: 'Contraseña',
    _CONX_MSJE_INGR_CLAVE: 'ingresa tu contraseña',
    _CONX_CLAVE_OCULTAR: 'Ocultar contraseña',
    _CONX_CLAVE_MOSTRAR: 'Mostrar contraseña',
    _CONX_LABEL_INGRESAR: 'Ingresar',
    _CONX_LABEL_OLVIDO_CLAVE: '¿Olvidaste tu contraseña?',
    _CONX_USUARIO_LMIN: 4,
    _CONX_USUARIO_LMAX: 30,
    _CONX_CLAVE_LMIN: 4,
    _CONX_CLAVE_LMAX: 30,
    _CONX_MSJE_ERROR: 'Conexión ha fallado. Revisa tu usuario y contraseña.',

    _RCLV_CASO_PNATURAL: 'pnatural',
    _RCLV_CASO_USUARIO: 'usuario',
    _RCLV_MSJE_PIN_INGRESAR: 'Ingresa el código de verificación (pin) enviado al correo indicado y cambia la contraseña de acceso.',
    _RCLV_MSJE_PIN_ERROR: 'Código de verificación no pudo ser enviado, revisa el rut ingresado',
    _RCLV_MSJE_CLAVE_ERROR: 'Las contraseñas ingresadas deben ser iguales.',
    _RCLV_MSJE_CLAVE_OK: 'Contraseña ha sido cambiada en forma exitosa',
    _RCLV_MSJE_CLAVE_ERROR_SERV: 'La contraseña no pudo ser actualizada, revise el pin (código de verificación) en el correo enviado y el rut asociado.',
    _RCLV_FORM_LABEL_RUT: 'Ingresa tu RUT',
    _RCLV_FORM_BOTON_PIN: 'Enviar código (pin) por correo',
    _RCLV_FORM_BOTON_VOLVER: '<< volver a conexión',
    _RCLV_FORM_LABEL_PIN: 'Ingresa PIN',
    _RCLV_FORM_LABEL_CLAVE: 'Nueva Contraseña',
    _RCLV_FORM_MSJE_CLAVE: 'La contraseña debe contener al menos una letra mayúscula, una minúscula y un número',
    _RCLV_FORM_MSJE_CLAVE2: 'Repetir Contraseña',
    _RCLV_MSJE_INGR_CLAVE: 'ingresa la contraseña',
    _RCLV_MSJE_INGR_CLAVE2: 'repite la contraseña',
    _RCLV_FORM_BOTON_CAMBIAR: 'Cambiar clave',
}

export const KContratos = {
    _C_TITULO: 'Consulta Contratos',
    _C_RUT: 'RUT',
    _C_ID: 'ID',
    _C_PERS_CTTO: 'Contrato nombre',
    _C_FECHA_DESDE: 'Fecha desde',
    _C_FECHA_HASTA: 'Fecha hasta',
    _C_CGESTION: 'Programa',
    _C_BUSCAR: 'Buscar',
    _TH_CGESTION: 'Programa',
    _TH_ID: 'ID',
    _TH_RUT: 'RUT',
    _TH_NOMBRE: 'Nombre',
    _TH_CONTRATO: 'Contrato',
    _TH_FECHA_PPAL: 'Fecha',
    _TH_MONTO: 'Monto',
    _TH_FECHA_INI: 'Inic. Serv.',
    _TH_FECHA_FIN: 'Fin Serv.',
    _TH_FECHA_PDTE: 'Fecha Pdte.',
    _TH_ESTADO: 'Estado',

    _SECCION_I: 'Sección I: Estructura',
    _SECCION_II: 'Sección II: Contenido',
    _SECCION_III: 'Sección III: Fechas y Valores',
    _SECCION_IV: 'Sección IV: Mensajes y Anotaciones',
    _SECCION_V: 'Sección V: Adjuntos',
    _SECCION_VI: 'Sección VI: Bitácora',

    _F_TITULO: 'Contrato',
    _F_TITULO_NUEVO: 'Nuevo Contrato',
    _F_TIPO_CTTO: 'Tipo de Contrato',
    _F_CGESTION: 'Programa',
    _F_FECHA_PPAL: 'Fecha Contrato',
    _F_NRO_INTERNO: 'Nro. Interno',
    _F_RUT_PROV: 'RUT',
    _F_NOMBRE_PROV: 'Nombre',
    _F_ESTADO: 'Estado',
    _F_FECHA_ESTADO: 'Fecha Estado',
    _F_NOMBRE: 'Nombre Contrato/Servicio',
    _F_OBJETIVO: 'Objetivo / Necesidad',
    _F_DESCRIPCION: 'Descripción del servicio',
    _F_MONEDA: 'Moneda',
    _F_MONTO: 'Monto',
    _F_FECHA_INI: 'Fecha inicio servicio',
    _F_FECHA_FIN: 'Fecha término servicio',
    _F_PAGO_CUOTAS: 'Nro. Cuotas / Pago',
    _F_PAGO_MONTO: 'Monto cuotas / Pago',
    _F_PAGO_UNIDAD: 'Periodicidad',
    _F_PAGO_GLOSA: 'Descripción forma de pago (opcional)',
    _F_OBSERVACIONES: 'Comentarios',
    _F_MSJE_INT_CCV: 'Mensaje interno CCV',
    _F_MSJE_A_PROV: 'Mensaje a Proveedor',
    _F_MSJE_DE_PROV: 'Mensaje de Proveedor',
    _F_MSJE_DE_FIRMA1: 'Mensaje de Firma #1 CCV',
    _F_MSJE_DE_FIRMA2: 'Mensaje de Firma #2 CCV',
    _F_BOTON_GRABAR: 'Grabar Cambios',
    _F_BOTON_CREAR: 'Crear Contrato',
    _F_MSJE_TITULO_AVISO: 'Aviso importante\n',
    _F_MSJE_TITULO_EXITO: 'Operación exitosa\n',
    _F_MSJE_TITULO_FRACASO: 'Operación ha fallado\n',
    _F_MSJE_ERROR_BITACORA: 'Se produjo un error al intentar leer la bitácora',
    _F_MSJE_ERROR_USUARIOS: 'Se produjo un error al intentar leer usuarios',
    _F_MSJE_ERROR_FORM: 'Formulario con error(es)',
    _F_MSJE_MODIFICAR_EXITO: 'El contrato ha sido actualizado\n',
    _F_MSJE_MODIFICAR_FRACASO: 'Grabación ha fallado:\nSe produjo error [CT-M-10] al intentar grabar cambios a contrato.\nAvise a soporte indicando el código de error y cualquier información que pueda ser de utilidad.',
    _F_MSJE_ELIMINAR_EXITO: 'El contrato ha sido eliminado\n',
    _F_MSJE_ELIMINAR_FRACASO: 'Grabación ha fallado:\nSe produjo error [CT-E-11] al intentar eliminar el contrato.\nAvise a soporte indicando el código de error y cualquier información que pueda ser de utilidad.',
    _F_MSJE_CREAR_EXITO: 'El contrato ha sido creado\n',
    _F_MSJE_CREAR_FRACASO: 'Creación ha fallado:\nSe produjo error [CT-C-12] al intentar crear contrato.\nAvise a soporte indicando el código de error y cualquier información que pueda ser de utilidad.',
    _F_MSJE_GENERAR_PDF: 'Generación de contrato en formato pdf ha fallado. Informe a Soporte.',
    _F_MSJE_AUTORIZAR_EXITO: 'El contrato ha sido enviado a firmas\n',
    _F_MSJE_AUTORIZAR_FRACASO: 'Autorización ha fallado:\nSe produjo error [CA-A-13] al intentar autorizar el contrato.\nAvise a soporte indicando el código de error y cualquier información que pueda ser de utilidad.',
    _F_MSJE_FIRMAR_EXITO: 'El contrato ha sido actualizado (firma)\n',
    _F_MSJE_FIRMAR_FRACASO: 'Autorización ha fallado:\nSe produjo error [CA-F-14] al intentar firmar el contrato.\nAvise a soporte indicando el código de error y cualquier información que pueda ser de utilidad.',
    _F_MSJE_RECHAZAR_EXITO: 'El contrato ha sido actualizado (rechazo)\n',
    _F_MSJE_RECHAZAR_FRACASO: 'Rechazo ha fallado:\nSe produjo error [CA-R-15] al intentar rechazar el contrato.\nAvise a soporte indicando el código de error y cualquier información que pueda ser de utilidad.',
    _F_MSJE_RECHAZAR_SIN_MENSAJE: 'Rechazo requiere indicar motivo en el campo mensaje.',
    _F_MSJE_RECH_BORRADOR_EXITO: 'El contrato ha sido actualizado (rechazo a borrador)\n',
    _F_MSJE_RECH_BORRADOR_FRACASO: 'Actualización ha fallado:\nSe produjo error [CA-R-16] al intentar rechazar el contrato.\nAvise a soporte indicando el código de error y cualquier información que pueda ser de utilidad.',
    _F_MSJE_ADJUNTAR_EXITO: 'El adjunto ha sido subido\n',
    _F_MSJE_ADJUNTAR_FRACASO: 'El adjunto no ha sido subido\n',
    _F_MSJE_PNAT_SIN_DECL_JURADA: 'Persona sin declaración jurada vigente.',
    _F_MSJE_CTTO_SIN_PROPUESTA: 'Falta adjunto: Propuesta tallerista',
    _F_MSJE_CTTO_SIN_COTIZACION: 'Falta adjunto: Cotizaciones',
    _F_MSJE_CTTO_SIN_MEMOCONDUCTOR: 'Falta adjunto: Memo Conductor',
    _F_MSJE_CTTO_SIN_CUMPLIMIENTO: 'Falta adjunto: Cumplimiento',

    _F_ACCION_ELIMINAR: 'eliminar',
    _F_ACCION_AUTORIZAR: 'autorizar',
    _F_ACCION_FIRMAR1OK: 'firmar1ok',
    _F_ACCION_FIRMARPOK: 'firmarPok',
    _F_ACCION_FIRMAR2OK: 'firmar2ok',
    _F_ACCION_RECHAZAR: 'rechazar',
    _F_ACCION_RECHBORR: 'rechAborrador',

    _F_ACCION_ELIMINAR_MSJE_TITULO: 'Eliminar Contrato',
    _F_ACCION_ELIMINAR_MSJE_CUERPO: '¿Estás segura(o) que deseas eliminar el contrato?',

    _F_ACCION_RECH_BORRADOR_MSJE_TITULO: 'Modificar Contrato',
    _F_ACCION_RECH_BORRADOR_MSJE_CUERPO: '¿Estás segura(o) que deseas modificar contrato rechazado (enviar a borrador)?',

    _F_ACCION_AUTORIZAR_MSJE_TITULO: 'Iniciar proceso de firmas',
    _F_ACCION_AUTORIZAR_MSJE_CUERPO: 'Esta acción envía el pdf del contrato para la primera firma. \nEs importante revisar previamente el pdf generado en la pestaña "Preliminar". Una vez presionado el botón "Aceptar" no podrás hacer cambios al contrato.',

    _F_ACCION_AUTORIZAR_MSJE_TITULO_C: 'Iniciar proceso de firmas',
    _F_ACCION_AUTORIZAR_MSJE_CUERPO_C: 'Para enviar a firmas no debe modificar campos del formulario, antes debe grabar cambios.',

    _F_ACCION_FIRMAR_MSJE_TITULO: 'Firmar Contrato',
    _F_ACCION_FIRMAR_MSJE_CUERPO: '¿Estás segura(o) que deseas subir el contrato firmado?',

    _F_ACCION_RECHAZAR_MSJE_TITULO: 'Rechazar Contrato',
    _F_ACCION_RECHAZAR_MSJE_CUERPO: '¿Estás segura(o) que deseas rechazar firma de contrato?',


    _F_LINK_DESCARGA_GLOSA: 'Descarga y revisa el borrador del contrato en formato pdf',
    _F_BOTON_CONFIRMAR_CERRAR: 'Cerrar',
    _F_BOTON_CONFIRMAR_CANCELAR: 'Cancelar',
    _F_BOTON_CONFIRMAR_ACEPTAR: 'Aceptar',

    _CR_TITULO1: 'Cuadro Resumen',
    _CR_TITULO2: '',
    _CR_CGESTION: 'Programa',
    _CR_BORRADOR_P: 'Borra-',
    _CR_BORRADOR_S: 'dor',
    _CR_RECHAZADO_P: 'Recha-',
    _CR_RECHAZADO_S: 'zado',
    _CR_EN_FIRMA1_P: 'En Firma',
    _CR_EN_FIRMA1_S: '#1 CCV',
    _CR_EN_FIRMA1_Z: '(a)',
    _CR_EN_FIRMA_PROV_P: 'En Firma',
    _CR_EN_FIRMA_PROV_S: 'Prov.',
    _CR_EN_FIRMA_PROV_Z: '(b)',
    _CR_EN_FIRMA2_P: 'En Firma',
    _CR_EN_FIRMA2_S: '#2 CCV',
    _CR_EN_FIRMA2_Z: '(c)',
    _CR_EN_FIRMAS_P: 'En Firma',
    _CR_EN_FIRMAS_S: 'Total',
    _CR_EN_FIRMAS_Z: '(a+b+c)',
    _CR_FINALIZADOS_P: 'Total',
    _CR_FINALIZADOS_S: 'Finaliz.',
    _CR_FINALIZADOS_Z: '(d)',
    _CR_TOTAL_P: 'Total',
    _CR_TOTAL_S: 'Gral.',
    _CR_TOTAL_Z: '(a+b+c+d)',
    _CR_TT_FIRMA_1: 'Contratos esperando firma #1 CCV',
    _CR_TT_FIRMA_P: 'Contratos esperando firma del Proveedor',
    _CR_TT_FIRMA_2: 'Contratos esperando firma #2 CCV',
    _CR_TT_FIRMA_TOTAL: 'Total de contratos en proceso de firmas',
    _CR_TT_TOTAL_FINALIZ: 'Total de contratos finalizados con todas las firmas correspondientes',
    _CR_TT_TOTAL_GRAL: 'La suma de todos los contratos: borradores, rechazados, pendientes de firma y finalizados',

    _PF_TITULO1: 'En firma proveedores',
    _PF_TITULO2: '(pendientes)',
    _PF_ID: 'Id',
    _PF_CGESTION: 'Programa',
    _PF_RUT_PROV: 'RUT',
    _PF_NOMBRE_PROV: 'Nombre',
    _PF_NOMBRE_CTTO: 'Contrato',
    _PF_FECHA_CTTO: 'Fecha',
    _PF_MONTO: 'Monto',
    _PF_FECHA_PDTE: 'Fecha Pdte.',
    _PF_ESTADO: 'Estado',
    _PF_PLAZO: 'Días',
   
    _PB_TITULO1: 'Borrador',
    _PB_TITULO2: '',
    _PB_ID: 'Id',
    _PB_CGESTION: 'Programa',
    _PB_RUT_PROV: 'RUT',
    _PB_NOMBRE_PROV: 'Nombre',
    _PB_NOMBRE_CTTO: 'Contrato',
    _PB_FECHA_CTTO: 'Fecha',
    _PB_MONTO: 'Monto',
    _PB_FECHA_DESDE: 'F. desde',
    _PB_FECHA_HASTA: 'F. hasta',

    _PR_TITULO1: 'Rechazados',
    _PR_TITULO2: '(en corrección)',
    _PR_ID: 'Id',
    _PR_CGESTION: 'Programa',
    _PR_RUT_PROV: 'RUT',
    _PR_NOMBRE_PROV: 'Nombre',
    _PR_NOMBRE_CTTO: 'Contrato',
    _PR_FECHA_CTTO: 'Fecha',
    _PR_MONTO: 'Monto',
    _PR_FECHA_PDTE: 'Fecha Pdte.',
    
    _MP_TITULO1: 'Mis Pendientes',
    _MP_TITULO2: '(esperando firma)',    
    _MP_TITULO1_F: 'Subir contratos firmados',
    _MP_TITULO2_F: '(esperando firma)',    
    _MP_ID: 'Id',
    _MP_CGESTION: 'Programa',
    _MP_RUT_PROV: 'RUT',
    _MP_NOMBRE_PROV: 'Nombre',
    _MP_NOMBRE_CTTO: 'Contrato',
    _MP_FECHA_CTTO: 'Fecha',
    _MP_MONTO: 'Monto',
    _MP_FECHA_PDTE: 'Fecha Pdte.',
    _MP_ESTADO: 'Estado',
    _MP_PLAZO: 'Días',

    _MP_ORDEN_CTTO_ID: 'ctto_id',
    _MP_ORDEN_FECHA_PDTE: 'fecha',
    _MP_ORDEN_CGESTION: 'cgestion',
    _MP_ORDEN_RUT: 'rut',
    _MP_ORDEN_NOMBRE: 'nombre',
    _MP_ORDEN_PLAZO: 'plazo',

    _AF_TITULO: 'Zona de subida de archivos',
    _AF_TIPO: 'Tipo',
    _AF_NOMBRE: 'Nombre',
    _AF_DESCRIPCION: 'Descripción',
    _AF_DOCUMENTO: 'Documento',
    _AF_BOTON_SUBIR: 'Subir documento(s)',
    _AF_TIPO_SEL: 'Tipo Adjunto',

    _AR_ID: 'Id',
    _AR_FECHA: 'Fecha',
    _AR_TIPO: 'Tipo',
    _AR_NOMBRE: 'Nombre',
    _AR_DESCRIPCION: 'Descripción',
    _AR_DOCUMENTO: 'Documento',
    _AR_DESCARGAR: 'Descargar',
    _AR_ELIMINAR: 'Eliminar',
    _AR_BOTON_DESCARGAR: '(descargar)',

    _BI_ID: 'Id',
    _BI_FECHA: 'Fecha',
    _BI_ESTADO: 'Estado Contrato',
    _BI_MENSAJE: 'Mensaje',
    _BI_USUARIO: 'Usuario',
    _BI_RESULTADO: 'Resultado',
    _BI_DESCARGA: 'Pdf',
    _BI_BOTON_DESCARGA: 'descargar',

    _TAB_FORMULARIO: 'Formulario',
    _TAB_PRELIMINAR: 'Vista Preliminar',
    _TAB_ADJUNTOS: 'Adjuntos',
    _TAB_BITACORA: 'Bitácora',
    _TAB_FIRMAR: 'Firmar',

    _SC_BOTON_RECHAZAR: 'Rechazar / Devolver',
    _SC_BOTON_SUBIR: 'Subir documento firmado',
    _SC_BOTON_SUBIR_N: 'Subir documentos firmados',
    _SC_BOTON_DESCARGAR: 'Descargar documento a firmar',
    _SC_MSJE_RECHAZAR: 'Mensaje (motivo de rechazo/devolución):',
    _SC_PASO1_TITULO: 'Paso 1:',
    _SC_PASO1_DESCRIPCION: 'Descarga el documento pdf a firmar',
    _SC_PASO2_TITULO: 'Paso 2:',
    _SC_PASO2_DESCRIPCION: 'Firma el documento pdf con firma electrónica avanzada.',
    _SC_PASO3_TITULO: 'Paso 3:',
    _SC_PASO3_DESCRIPCION: 'Sube el documento firmado para que sea ingresado al sistema',
    _SC_PASO3_DESCRIPCION_F: 'Selecciona los documento firmados para que sean ingresados al sistema',
    _SC_PASO2_AYUDA_TITULO: '(leer indicaciones)',
    _SC_PASO2_AYUDA_PARRAFO1: 'Métodos de Firma  Electrónica Avanzada (FEA):',
    _SC_PASO2_AYUDA_PARRAFO1_1: 'Firma con token comprado para FEA',
    _SC_PASO2_AYUDA_PARRAFO1_2: 'Firma con clave única, si compraste certificado para FEA',
    _SC_PASO2_AYUDA_PARRAFO1_3: 'Firma con clave única comprando pack de firmas para FEA',
    _SC_PASO2_AYUDA_PARRAFO2: 'Graba el documento con el mismo nombre, agregando al final la palabra "firmado", antes de la extensión ".pdf". De este modo se evita confundir de documento al momento de subirlo.',
    _SC_PASO2_AYUDA_PARRAFO3: 'Por ejemplo, si el documento descargado es "98765432_1234_2255_f1.pdf", al momento de guardar la firma debe tener el nombre "98765432_1234_2255_f1_firmado.pdf"',
    _SC_PASO3_AYUDA_TITULO: '(en caso de rechazar/devolver el documento, sin firmar, dar clic acá)',
    _SC_PASO3_AYUDA_PARRAFO1: 'Métodos de Firma  Electrónica Avanzada (FEA):',
    _SC_MSJE_ARCHIVO_PREFIJO: 'Archivo no corresponde al requerido para firma: ',
    _SC_MSJE_NO_REQ_FIRMA: 'Contrato no requiere firma en esta etapa o no dispones de permiso para firmar',
    _SC_MSJE_NO_ARCH_PERMITIDO: 'Revise que sea un archivo en la norma requerida (nombre y extensión)',
    _SC_MSJE_SUBIR_AVISO: 'AVISO',
    _SC_MSJE_TITULO_EXITO: 'Operación exitosa\n',
    _SC_MSJE_TITULO_FRACASO: 'Operación ha fallado\n',
    _SC_MSJE_RECHAZAR_SIN_MENSAJE: 'Rechazo requiere indicar motivo en el campo mensaje.',
    _SC_MSJE_RECHAZAR_EXITO: 'El contrato ha sido rechazo/devuelto\n',
    _SC_MSJE_RECHAZAR_FRACASO: 'Rechazo ha fallado:\nSe produjo error [SC-E-17] al intentar rechazar el contrato.\nAvise a soporte indicando el código de error y cualquier información que pueda ser de utilidad.',
    _SC_MSJE_DESCARGAR_EXITO: 'El contrato ha sido descargado\n',
    _SC_MSJE_DESCARGAR_FRACASO: 'El contrato no ha sido descargado\n',
    _SC_MSJE_SUBIR_EXITO: 'El contrato ha sido subido\n',
    _SC_MSJE_SUBIR_FRACASO: 'El contrato no ha sido subido\n',
    _SC_MSJE_ERROR_BITACORA: 'Se produjo un error al intentar leer la bitácora',
    _SC_NOTIF_ASUNTO_RECHAZAR: 'Gestión de Contratos: Contrato Rechazado',
    _SC_NOTIF_ASUNTO_FIRMAR: 'Gestión de Contratos: Contrato pendiente de firma',
    _SC_NOTIF_ASUNTO_FINALIZAR: 'Gestión de Contratos: Contrato firmado completo',
    _SC_CONFIRMAR_RECHAZAR_TITULO: 'Rechazar/Devolver contrato',
    _SC_CONFIRMAR_RECHAZAR_CUERPO: '¿Estás segura(o) que deseas rechazar/devolver el contrato?. Esta operación elimina todas las firmas parciales previas.',
    _SC_BOTON_DESCARGAR_REVISION: 'Descargar',

    _AC_MSJE_TITULO_EXITO: 'Operación exitosa\n',
    _AC_MSJE_TITULO_FRACASO: 'Operación ha fallado\n',
    _AC_MSJE_DESCARGAR_EXITO: 'El adjunto ha sido descargado\n',
    _AC_MSJE_DESCARGAR_FRACASO: 'El adjunto no ha sido descargado\n',

 }

export const KConfiguracion = {
    _CGESTION: 'cgestion',
    _CGESTION_CORR: 'cgestion_correlativos',
    _TCONTRATOS: 'tcontratos',
    _TCONTRATOS_X_ESTADO: 'tcontratos_x_estado',
    _TRANSICIONES_X_TCONTRATO: 'transiciones_x_tcontrato',
    _TRANSICIONES_X_PARAM: 'transiciones_x_param',
    _CAMPOS: 'campos',
    _TABLAS: 'tablas',
    _TABLAS_REL: 'tablas_relacionadas',
}

export const KTablas = {
    _AYUDA_CAMPO: 'ayuda_campo',
    _AYUDA_FORM: 'ayuda_form',
    _BANCOS: 'bancos',
    _COMUNAS: 'comunas',
    _DIAS_SEMANA: 'dias_semana',
    _ESTADO_CIVIL: 'estado_civil',
    _GENERO: 'genero',
    _MESES: 'meses',
    _MONEDAS: 'monedas',
    _PAISES: 'paises',
    _REGIONES: 'regiones',
    _SI_NO: 'si_no',
    _TIPO_CUENTA: 'tipo_cuenta',
    _TIPO_PERSONA: 'tipo_persona',
    _TIPO_PAGO_CUOTAS: 'tipo_pago_cuotas',
    _TIPO_ADJUNTO: 'tipo_adjunto',
    _PARAMETROS: 'parametros',
}

export const KAyudaForm = {
    _CONTRATO_MANT: 'contrato_mant',
    _CONTRATO_CONS: 'contrato_mant',
    _PNATURAL_MANT: 'pnatural_mant',
    _PNATURAL_CONS: 'pnatural_mant',
}

export const NMes = ['', 'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
