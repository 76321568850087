import * as React from "react"
import { AuthProvider } from "./context"
import { BrowserRouter } from "react-router-dom"
import { Inicio } from "./componentes/conexion/Inicio"
// import { theme } from '@chakra-ui/pro-theme'
import {
  ChakraProvider,
  // extendTheme,
} from "@chakra-ui/react"
import RoutesLogin from "./routers/RoutesLogin"

export const App = () => {
/*
  const myTheme = extendTheme(
    {
      colors: { ...theme.colors, brand: theme.colors.red },
    },
    theme,
  )
*/
  return(
    <AuthProvider>
      <ChakraProvider>
          <BrowserRouter>
            <Inicio/> 
          </BrowserRouter>
      </ChakraProvider>
    </AuthProvider>
  )
}
