import { useFetch } from '../../hooks/useFetch';
import PNaturalesRspta from './PNaturalesConsRspta'
import { servPNaturalesConsultar } from '../../utils/servicios';
import { IUseFetch } from '../../interfaces/iEndpoints';
import { useForm } from 'react-hook-form'
import {
    Box,
    Button,
    Container,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    Select,
    VStack,
    useColorModeValue,
  } from '@chakra-ui/react'
import { limpiarRut, podarCGestion } from '../../utils/utilidades';
import { useState, useContext } from 'react';
import { K, KApps, KPNatural } from '../../utils/constantes';
import { TablasContext } from '../../context/TablasContext';

type FormData = {
  pnat_rut   : string,
  pnat_nombre: string,
  cges_id_select  : string,
};
   
const PNaturales = () => {
  
  const [primeraVez, setprimeraVez] = useState(true);
  const { tCentrosGestion, tAplicacionesPermisos } = useContext ( TablasContext );

  const { register, handleSubmit, formState: { isSubmitting } } = useForm<FormData>();

  const colorLabel = useColorModeValue("blue.500", "whiteAlpha.800");
  const colorTitulo = useColorModeValue(K._COLOR_TITULO_L, K._COLOR_TITULO_D);

  const tCentrosGestionAut = podarCGestion (tCentrosGestion, tAplicacionesPermisos, KApps._PANEL_CONTROL);
  const onSubmit = ( {pnat_rut, pnat_nombre, cges_id_select}: FormData ) => {
    setprimeraVez(false);
    servPNaturalesConsultar.params = {
      ...servPNaturalesConsultar.params,
      pnat_rut : limpiarRut(pnat_rut),
      pnat_nombre: pnat_nombre,
      pnat_vigente: 'S',
      cges_id_default_list: (cges_id_select === K._TODO_ID) ? '' : cges_id_select, // listaCGestion (tCentrosGestionAut, cges_id_select ),
    }
  }

  const PNaturalesServ:IUseFetch = useFetch (servPNaturalesConsultar, JSON.stringify ( servPNaturalesConsultar.params ), 'PNaturales', primeraVez);

  return (
    <Container 
            py={{ base: '1', md: '1' }} 
            px={{ base: '1', md: '1' }} 
            maxW='6xl' 
            p="3" 
            bg={useColorModeValue("gray.200", "gray.900")}
          >
      <Flex 
        direction={{ base: 'column', lg: 'row' }}
        overflowY="auto"
        bg={useColorModeValue("white", "gray.700") }
        py={{ base: '1', md: '2' }} 
        px={{ base: '1', md: '2' }} 
      >

        <Box
            bg={useColorModeValue("white", "gray.700") }
            borderRadius={{ base: 'none', sm: 'md' }}
        >
          <VStack spacing="2" p='2'>

            <Heading size='md' p={3} color={colorTitulo}>{KPNatural._PNC_TITULO}</Heading>

            <Divider />

            <form  onSubmit={ handleSubmit(onSubmit) } >

              <HStack spacing="6" direction={{ base: 'column', md: 'row' }}>

                <FormControl>
                  <FormLabel color={colorLabel} fontSize="xs">{KPNatural._PNC_RUT_PROV}</FormLabel>
                  <Input w='40' {...register('pnat_rut', { })} />
                </FormControl>

                <FormControl>
                  <FormLabel color={colorLabel} fontSize="xs">{KPNatural._PNC_NOMBRE_PROV}</FormLabel>
                    <Input w='40' {...register('pnat_nombre', {})} />
                </FormControl>


                <FormControl>
                  <FormLabel>&nbsp;</FormLabel>
                    <Button colorScheme='teal' variant='outline' type="submit" isLoading={isSubmitting}>
                      Buscar
                  </Button>
                </FormControl>
              </HStack>

              <FormControl >
                <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_CGESTION}</FormLabel>
                  <Select 
                    size={'md'} 
                    defaultValue={K._TODO_ID} 
                    {...register('cges_id_select')} 
                  >
                    <option key={K._TODO_ID} value={K._TODO_ID}>{`${K._TODO_TEXTO}`}</option>
                    {
                      tCentrosGestionAut.map( cgestion => (
                        <option key={cgestion.cges_cges_id} value={cgestion.cges_cges_id} >{cgestion.cges_cges_nombre}</option>
                      ))
                    }  
                  </Select>
                </FormControl>

            </form>
          </VStack>
        </Box>
      </Flex >

      <Divider />

      <Container 
        maxW='12xl' 
        bg={useColorModeValue("white", "gray.700")}
        p='2'
      >
        <Box bg={useColorModeValue("white", "gray.700") }>
          <PNaturalesRspta respuesta={PNaturalesServ} primeraVez={primeraVez} />
        </Box>
        <Divider />
        <Container bg={useColorModeValue("white", "gray.700")} p='40' />
      </Container>

    </Container>
  )
}

export default PNaturales;