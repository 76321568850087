import { useContext } from 'react';
import { IEndpoint, IPNaturalesCrear, IPNaturalesConsultar } from '../../interfaces/iEndpoints';
import { useForm } from 'react-hook-form'
import {
    Box,
    Button,
    Container,
    Heading,
    useColorModeValue,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Divider,
    VStack,
    StackDivider,
    FormErrorMessage,
    Textarea,
    Select,
  } from '@chakra-ui/react'
import { esCorreoValido, esMayorEdad, esRUTValido, formatRUT, fParametros, limpiarRut, nombrePersona, pctjeMayusculas } from '../../utils/utilidades';
import { useState } from 'react';
import { Stack, useToast } from '@chakra-ui/react';
import { K, KPerfiles, KPNatural, KV } from '../../utils/constantes';
import { fetchTx } from '../../utils/fetchTx';
import { servPNaturalesConsultar, servPNaturalesCrear } from '../../utils/servicios';
import { AuthContext } from '../../context';
import { TablasContext } from '../../context/TablasContext';
import { fetchQS } from '../../utils/fetchQS';

const PNaturalCrear = ( ) => {

  const { usuario, ambiente } = useContext( AuthContext );
  const { tEstadoCivil, tGenero, tBancos, tTipoCuenta, tPaises, tRegiones, tComunas, tCentrosGestion, tParametros } = useContext( TablasContext );

  const toast = useToast();
  const [ hayErrorRut, setHayErrorRut ] = useState(false);

  const [ mostrarMensajeError, setMostrarMensajeError ] = useState(false);
  const [ mostrarMensajeOK, setMostrarMensajeOK ] = useState(false);
  const [ mensajeActualizar, setMensajeActualizar ] = useState('');
  const [ mostrarCrear, setMostrarCrear ] = useState(true);

  const vParametros = fParametros(tParametros);

  const colorLabel        = useColorModeValue("blue.500", "whiteAlpha.800");
  const colorBG           = useColorModeValue("gray.50", "gray.700");
  const colorBG2          = useColorModeValue("gray.200", "gray.900")
  const colorBGErrores    = useColorModeValue("pink.100", "pink.200");
  const colorBGok         = useColorModeValue("green.100", "green.100");
  const colorTextMensajes  = useColorModeValue("gray.800", "gray.800");
  const bgInput           = useColorModeValue("white", "gray.600");
  const bgInputRead       = useColorModeValue("gray.50", "gray.700");
  const widthInput        = "40"; 
  const colorTitulo       = useColorModeValue(K._COLOR_TITULO_L, K._COLOR_TITULO_D);

  const { register, handleSubmit, getValues, setValue, trigger, formState: { errors, isSubmitting } } = useForm<IPNaturalesCrear>();

  const onSubmit = async ( data: IPNaturalesCrear ) => {
    
    servPNaturalesCrear.params = {
      pnat_rut: limpiarRut ( data.pnat_rut ),
      pnat_nacional: K._SI,
      pnat_nombre1: data.pnat_nombre1,
      pnat_nombre2: data.pnat_nombre2,
      pnat_apellido1: data.pnat_apellido1,
      pnat_apellido2: data.pnat_apellido2,
      pnat_alias: data.pnat_alias,
      perf_id: KPerfiles._PERF_PNAT_USUARIO,
      pnat_correo: data.pnat_correo,
      pnat_telefono: data.pnat_telefono,
      pnat_profesion: data.pnat_profesion,
      pnat_genero: data.pnat_genero,
      pnat_fecha_nac: data.pnat_fecha_nac,
      naci_id: data.naci_id,
      eciv_id: data.eciv_id,
      pnat_direccion: data.pnat_direccion,
      pnat_direccion_compl: '',
      comu_id: data.comu_id,
      regi_id: data.regi_id,
      pais_id: '',
      pnat_tx_cuenta_banco: data.pnat_tx_cuenta_banco,
      pnat_tx_cuenta_nro: data.pnat_tx_cuenta_nro,
      pnat_tx_cuenta_tipo: data.pnat_tx_cuenta_tipo,
      pnat_cod_postal: '',
      pnat_decljurada_id: 0,
      pnat_decljurada_fecha: '2022-01-01',
      cges_id_default: data.cges_id_default,
      pnat_pers_id_insc: 0,
      pnat_vigente: K._SI,
      usua_id: usuario ? usuario.usua_id : '',
    }

    const resp:IEndpoint = await fetchTx (servPNaturalesCrear, null, ambiente);

    if (resp.code === 0) {
      const d = new Date()
      setMensajeActualizar(`${KPNatural._PN_MSJE_CREAR_EXITO}${d.toLocaleString()}`);
      setMostrarMensajeOK(true);
      setMostrarMensajeError(false);
      setMostrarCrear(false);

      toast({
        title: KPNatural._PN_MSJE_TITULO_EXITO,
        description: KPNatural._PN_MSJE_CREAR_EXITO,
        status: 'success',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })
    }
    else {
      setMensajeActualizar(`${KPNatural._PN_MSJE_CREAR_FRACASO}\n\n${resp.data}`);
      setMostrarMensajeOK(false);
      setMostrarMensajeError(true);

      toast({
        title: KPNatural._PN_MSJE_TITULO_FRACASO,
        description: KPNatural._PN_MSJE_CREAR_FRACASO,
        status: 'error',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })
    }
  }

  const handleRUT = (e: React.SyntheticEvent):void => { setValue('pnat_rut', formatRUT(getValues('pnat_rut'))) }

  const onBlurRUT = async (e: React.SyntheticEvent) => { 

    const rut = limpiarRut(getValues('pnat_rut'));
    const msjeSiExiste = KV._CTTO_MSJE_RUT_YA_EXISTE;
    const msjeNoExiste = '';
    const msjeError2 = KV._CTTO_MSJE_RUT_ERROR;
    const msjeError3 = KV._CTTO_MSJE_RUT_VACIO;

    if ((!rut) || (rut.length <= 0)) {
      setValue('pnat_nombre', msjeError3)
      setHayErrorRut(true);

      toast({
        title: KV._CTTO_TITULO_CONSULTA_RUT,
        description: msjeError3,
        status: 'error',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })
    }
    else {
      servPNaturalesConsultar.params = {
        ...servPNaturalesConsultar.params,
        pnat_rut : rut,
        pnat_nombre: '',
        pnat_vigente: K._SI,
        cges_id_default_list: '',
      }

      const resp:IEndpoint = await fetchQS (servPNaturalesConsultar, null, ambiente );

      if (resp.code === 0) {
        if (resp.data.length <= 0) {
          setValue('pnat_nombre', msjeNoExiste );
          setHayErrorRut(false);
        }
        else {
          const datos:IPNaturalesConsultar = resp.data[0];
          setValue('pnat_nombre', nombrePersona( datos.pnat_pnat_nombre1, datos.pnat_pnat_nombre2, datos.pnat_pnat_apellido1, datos.pnat_pnat_apellido2 ) );
          setHayErrorRut(true);
          toast({
            title: KV._CTTO_TITULO_CONSULTA_RUT,
            description: msjeSiExiste,
            status: 'error',
            duration: K._DURACION_TOAST_DEFAULT,
            isClosable: true,
          })
        }
      }
      else {
        setValue('pnat_nombre', msjeError2)
        setHayErrorRut(true);

        toast({
          title: KV._CTTO_TITULO_CONSULTA_RUT,
          description: msjeError2,
          status: 'error',
          duration: K._DURACION_TOAST_DEFAULT,
          isClosable: true,
        })
      }
    }
  }

  return (

    <Container 
            py={{ base: '1', md: '2' }} 
            px={{ base: '1', md: '2' }} 
            maxW='8xl' 
            p="1" 
            bg={colorBG2}
            >
      <Flex 
        direction={{ base: 'column', lg: 'row' }}
        overflowY="auto"
        bg={colorBG}
        py={{ base: '1', md: '2' }} 
        px={{ base: '1', md: '1' }} 
        borderRadius={{ base: 'none', sm: 'xl' }}
      >
        <Box
          bg={colorBG}
          w='3xl' 
        >
          <VStack spacing="0" p='2'>

            <Heading size='sm' p={3} color={colorTitulo}>{KPNatural._PN_TITU_CREAR}</Heading>

            <Divider />

            <form onSubmit={ handleSubmit(onSubmit) } >

              <Stack spacing="3">

              <Stack spacing="3" direction={{ base: 'column', md: 'row' }}  >
                {/* RUT ----------------------------------------------------------- */}
                <FormControl isInvalid={errors.pnat_rut?true:false}>
                  <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_RUT}</FormLabel>
                  <Input 
                    bg={bgInput}
                    w={widthInput}
                    defaultValue={''} 
                    { ...register('pnat_rut', 
                      { 
                        required: KV._CAMPO_OBL, 
                        minLength: { value: 8, message: `${KV._LARGO_MIN} 8` },
                        maxLength: { value: 15, message: `${KV._LARGO_MAX} 20` },
                        onChange: handleRUT,
                        onBlur: onBlurRUT,
                        validate: {
                          digito: (v) => esRUTValido(v) || KV._RUT_INVALIDO
                        },
                      })
                    } 
                  />
                  <FormErrorMessage fontSize="10">
                    {errors.pnat_rut && errors.pnat_rut.message}
                  </FormErrorMessage>
                </FormControl>

                { /* pnat_pnat_nombre1... ----------------------------------------------------------- */ }
                <FormControl>
                  <FormLabel fontSize="xs" color={colorLabel}>&nbsp;</FormLabel>
                  <Input 
                    bg={ hayErrorRut ? colorBGErrores : bgInputRead }
                    w="xl"
                    isReadOnly={true}
                    variant="flushed"
                    { ...register('pnat_nombre', )} 
                  />
                </FormControl>
              </Stack>

                <Stack spacing="3" direction={{ base: 'column', md: 'row' }} divider={<StackDivider />} >

                  {/* NOMBRE1 ----------------------------------------------------------- */}
                  <FormControl isInvalid={errors.pnat_nombre1?true:false}>
                    <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_NOMBRE1}</FormLabel>
                    <Input 
                      bg={bgInput}
                      w={widthInput}
                      defaultValue={''} 
                      { ...register('pnat_nombre1', 
                        { 
                          required: KV._CAMPO_OBL, 
                          minLength: { value: 2, message: `${KV._LARGO_MIN} 2` },
                          maxLength: { value: 30, message: `${KV._LARGO_MAX} 30` },
                          validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                        })
                      } 
                    />
                    <FormErrorMessage fontSize="10">
                      {errors.pnat_nombre1 && errors.pnat_nombre1.message}
                    </FormErrorMessage>
                  </FormControl>

                  {/* NOMBRE2 ----------------------------------------------------------- */}
                  <FormControl isInvalid={errors.pnat_nombre2?true:false}>
                    <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_NOMBRE2}</FormLabel>
                    <Input 
                      bg={bgInput}
                      w={widthInput}
                      defaultValue={''} 
                      { ...register('pnat_nombre2', 
                        { 
                          minLength: { value: 2, message: `${KV._LARGO_MIN} 2` },
                          maxLength: { value: 30, message: `${KV._LARGO_MAX} 30` },
                          validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                        })
                      } 
                    />
                    <FormErrorMessage fontSize="10">
                      {errors.pnat_nombre2 && errors.pnat_nombre2.message}
                    </FormErrorMessage>
                  </FormControl>

                  {/* APPELLIDO1 ----------------------------------------------------------- */}
                  <FormControl isInvalid={errors.pnat_apellido1?true:false}>
                    <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_APELLIDO1}</FormLabel>
                    <Input 
                      bg={bgInput}
                      w={widthInput}
                      defaultValue={''} 
                      { ...register('pnat_apellido1', 
                        { 
                          required: KV._CAMPO_OBL, 
                          minLength: { value: 2, message: `${KV._LARGO_MIN} 2` },
                          maxLength: { value: 30, message: `${KV._LARGO_MAX} 30` },
                          validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                        })
                      } 
                    />
                    <FormErrorMessage fontSize="10">
                      {errors.pnat_apellido1 && errors.pnat_apellido1.message}
                    </FormErrorMessage>
                  </FormControl>

                  {/* APELLIDO2 ----------------------------------------------------------- */}
                  <FormControl isInvalid={errors.pnat_apellido2?true:false}>
                    <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_APELLIDO2}</FormLabel>
                    <Input 
                      bg={bgInput}
                      w={widthInput}
                      defaultValue={''} 
                      { ...register('pnat_apellido2', 
                        { 
                          maxLength: { value: 30, message: `${KV._LARGO_MAX} 30` },
                          validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                        })
                      } 
                    />
                    <FormErrorMessage fontSize="10">
                      {errors.pnat_apellido2 && errors.pnat_apellido2.message}
                    </FormErrorMessage>
                  </FormControl>

                </Stack>

                {/* <Divider /> */}

                <Stack spacing="3" direction={{ base: 'column', md: 'row' }} divider={<StackDivider />}>

                  {/* ALIAS ----------------------------------------------------------- */}
                  <FormControl isInvalid={errors.pnat_alias?true:false}>
                    <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_ALIAS}</FormLabel>
                    <Input 
                      bg={bgInput}
                      w={60}
                      defaultValue={''} 
                      { ...register('pnat_alias', 
                        { 
                          maxLength: { value: 30, message: `${KV._LARGO_MAX} 30` },
                          validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                        })
                      } 
                    />
                    <FormErrorMessage fontSize="10">
                      {errors.pnat_alias && errors.pnat_alias.message}
                    </FormErrorMessage>
                  </FormControl>

                  {/* CORREO ----------------------------------------------------------- */}
                  <FormControl isInvalid={errors.pnat_correo?true:false}>
                    <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_CORREO}</FormLabel>
                    <Input 
                      bg={bgInput}
                      w={'md'}
                      defaultValue={''} 
                      { ...register('pnat_correo', 
                        { 
                          required: `${KV._CAMPO_OBL}  ${KV._FORMATO_CORREO}`, 
                          minLength: { value: 5, message: `${KV._LARGO_MIN} 5` },
                          maxLength: { value: 100, message: `${KV._LARGO_MAX} 100` },
                          validate: {
                            forma: (v) => esCorreoValido(v) || KV._CORREO_INVALIDO,
                            abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS,
                          }
                        })
                      } 
                    />
                    <FormErrorMessage fontSize="10">
                      {errors.pnat_correo && errors.pnat_correo.message}
                    </FormErrorMessage>
                  </FormControl>

                </Stack>

                <Stack spacing="3" direction={{ base: 'column', md: 'row' }} divider={<StackDivider />}>

                {/* GENERO ----------------------------------------------------------- */}
                  <FormControl isInvalid={errors.pnat_genero?true:false}>
                    <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_GENERO}</FormLabel>
                    <Select 
                      size={'md'} 
                      defaultValue={K._SELE_ID} 
                      bg={bgInput}
                      {...register('pnat_genero', {
                        validate: {
                          seleccionar: (c) => (c !== K._SELE_ID) || `${KV._MSJE_SELECCIONE} ${KPNatural._PN_GENERO}`
                      },})} 
                    >
                      <option key='pnat_genero_s' value={K._SELE_ID}>{`${K._SELE_TEXTO}`}</option>
                      {
                        tGenero.map( genero => (
                          <option key={genero.tabl_tabl_elemento} value={genero.tabl_tabl_elemento} >
                            {genero.tabl_tabl_nombre}
                          </option>
                        ))
                      }  
                    </Select>
                    <FormErrorMessage fontSize="10">
                      {errors.pnat_genero && errors.pnat_genero.message}
                    </FormErrorMessage>
                  </FormControl>

                  {/* TELEFONO ----------------------------------------------------------- */}
                  <FormControl isInvalid={errors.pnat_telefono?true:false}>
                    <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_TELEFONOS}</FormLabel>
                    <Input 
                      bg={bgInput}
                      w={'md'}
                      defaultValue={''} 
                      { ...register('pnat_telefono', 
                        { 
                          required: KV._CAMPO_OBL, 
                          minLength: { value: 8, message: `${KV._LARGO_MIN} 8` },
                          maxLength: { value: 100, message: `${KV._LARGO_MAX} 100` },
                        })
                      } 
                    />
                    <FormErrorMessage fontSize="10">
                      {errors.pnat_telefono && errors.pnat_telefono.message}
                    </FormErrorMessage>
                  </FormControl>

                </Stack>

                {/* <Divider /> */}

                <Stack spacing="3" direction={{ base: 'column', md: 'row' }} divider={<StackDivider />}>

                  {/* FECHA_NAC ----------------------------------------------------------- */}
                  <FormControl isInvalid={errors.pnat_fecha_nac?true:false}>
                    <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_FECHANAC}</FormLabel>
                    <Input 
                      type="date"
                      bg={bgInput}
                      w={widthInput}
                      defaultValue={''} 
                      { ...register('pnat_fecha_nac', 
                        { 
                          required: KV._CAMPO_OBL, 
                          minLength: { value: 10, message: `${KV._LARGO_MIN} 8` },
                          maxLength: { value: 10, message: `${KV._LARGO_MAX} 8` },
                          validate: {
                            mayorEdad: (v) => esMayorEdad(v) || KV._MAYOR_18
                          }
                        })
                      } 
                    />
                    <FormErrorMessage fontSize="10">
                      {errors.pnat_fecha_nac && errors.pnat_fecha_nac.message}
                    </FormErrorMessage>
                  </FormControl>

                  {/* ESTADO_CIVIL ----------------------------------------------------------- */}
                  <FormControl isInvalid={errors.eciv_id?true:false}>
                    <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_ECIVIL}</FormLabel>
                    <Select 
                      size={'md'} 
                      defaultValue={K._SELE_ID} 
                      bg={bgInput}
                      {...register('eciv_id', {
                        validate: {
                          seleccionar: (c) => (c !== K._SELE_ID) || `${KV._MSJE_SELECCIONE} ${KPNatural._PN_ECIVIL}`
                      },})} 
                    >
                      <option key='eciv_id_s' value={K._SELE_ID}>{`${K._SELE_TEXTO}`}</option>
                      {
                        tEstadoCivil.map( ecivil => (
                          <option key={ecivil.tabl_tabl_elemento} value={ecivil.tabl_tabl_elemento} >
                            {ecivil.tabl_tabl_nombre}
                          </option>
                        ))
                      }  
                    </Select>
                    <FormErrorMessage fontSize="10">
                      {errors.eciv_id && errors.eciv_id.message}
                    </FormErrorMessage>
                  </FormControl>

                  {/* NACIONALIDAD ----------------------------------------------------------- */}
                  <FormControl isInvalid={errors.naci_id?true:false}>
                    <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_NACIONALIDAD}</FormLabel>
                    <Select 
                      size={'md'} 
                      defaultValue={K._SELE_ID} 
                      bg={bgInput}
                      {...register('naci_id', {
                        validate: {
                          seleccionar: (c) => (c !== K._SELE_ID) || `${KV._MSJE_SELECCIONE} ${KPNatural._PN_NACIONALIDAD}`
                      },})} 
                    >
                      <option key='naci_id_s' value={K._SELE_ID}>{`${K._SELE_TEXTO}`}</option>
                      {
                        tPaises.map( pais => (
                          <option key={pais.tabl_tabl_elemento} value={pais.tabl_tabl_elemento} >
                            {pais.tabl_tabl_nombre}
                          </option>
                        ))
                      }  
                    </Select>
                    <FormErrorMessage fontSize="10">
                      {errors.naci_id && errors.naci_id.message}
                    </FormErrorMessage>
                  </FormControl>

                  {/* PROFESION ----------------------------------------------------------- */}
                  <FormControl isInvalid={errors.pnat_profesion?true:false}>
                    <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_PROFESION}</FormLabel>
                    <Input 
                      bg={bgInput}
                      w={widthInput}
                      defaultValue={''} 
                      { ...register('pnat_profesion', 
                        { 
                          required: KV._CAMPO_OBL, 
                          minLength: { value: 1, message: `${KV._LARGO_MIN} 1` },
                          maxLength: { value: 100, message: `${KV._LARGO_MAX} 100` },
                          validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                        })
                      } 
                    />
                    <FormErrorMessage fontSize="10">
                      {errors.pnat_profesion && errors.pnat_profesion.message}
                    </FormErrorMessage>
                  </FormControl>

                </Stack>

                {/* <Divider /> */}

                <Stack spacing="3" direction={{ base: 'column', md: 'row' }} divider={<StackDivider />}>

                  {/* DIRECCION ----------------------------------------------------------- */}
                  <FormControl isInvalid={errors.pnat_direccion?true:false}>
                    <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_DIRECCION}</FormLabel>
                    <Input 
                      bg={bgInput}
                      w={'100%'}
                      defaultValue={''} 
                      { ...register('pnat_direccion', 
                        { 
                          required: KV._CAMPO_OBL, 
                          minLength: { value: 10, message: `${KV._LARGO_MIN} 10` },
                          maxLength: { value: 200, message: `${KV._LARGO_MAX} 200` },
                          validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                        })
                      } 
                    />
                    <FormErrorMessage fontSize="10">
                      {errors.pnat_direccion && errors.pnat_direccion.message}
                    </FormErrorMessage>
                  </FormControl>

                </Stack>

                <Stack spacing="3" direction={{ base: 'column', md: 'row' }} divider={<StackDivider />}>
                  {/* REGION ----------------------------------------------------------- */}
                  <FormControl isInvalid={errors.regi_id?true:false}>
                    <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_REGION}</FormLabel>
                    <Select 
                      size={'md'} 
                      defaultValue={K._SELE_ID} 
                      bg={bgInput}
                      {...register('regi_id', {
                        validate: {
                          seleccionar: (c) => (c !== K._SELE_ID) || `${KV._MSJE_SELECCIONE} ${KPNatural._PN_REGION}`
                      },})} 
                      onChange={() => setValue('comu_id', K._SELE_ID)}
                    >
                      <option key='regi_id' value={K._SELE_ID}>{`${K._SELE_TEXTO}`}</option>
                      {
                        tRegiones.map( region => (
                          <option key={region.tabl_tabl_elemento} value={region.tabl_tabl_elemento} >
                            {region.tabl_tabl_nombre}
                          </option>
                        ))
                      }  
                    </Select>
                    <FormErrorMessage fontSize="10">
                      {errors.regi_id && errors.regi_id.message}
                    </FormErrorMessage>
                  </FormControl>

                  {/* COMUNA ----------------------------------------------------------- */}
                  <FormControl isInvalid={errors.comu_id?true:false}>
                    <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_COMUNA}</FormLabel>
                    <Select 
                      size={'md'} 
                      defaultValue={K._SELE_ID} 
                      bg={bgInput}
                      {...register('comu_id', { 
                        validate: {
                          seleccionar: (c) => (c !== K._SELE_ID) || `${KV._MSJE_SELECCIONE} ${KPNatural._PN_COMUNA}`
                      },})} 
                      onClick={async () => {await trigger(["regi_id"]); }}
                    >
                      <option key='comu_id_s' value={K._SELE_ID}>{`${K._SELE_TEXTO}`}</option>
                      {
                        tComunas.map( comuna => {
                          if ( comuna.tabl_tabl_elemento_padre === getValues('regi_id') )
                            return(<option key={comuna.tabl_tabl_elemento} value={comuna.tabl_tabl_elemento} >{comuna.tabl_tabl_nombre}</option>)
                          else
                            return null
                        })
                      }  
                    </Select>
                    <FormErrorMessage fontSize="10">
                      {errors.comu_id && errors.comu_id.message}
                    </FormErrorMessage>
                  </FormControl>

                </Stack>

                {/* <Divider /> */}

                <Stack spacing="3" direction={{ base: 'column', md: 'row' }} divider={<StackDivider />}>

                  {/* BANCO ----------------------------------------------------------- */}
                  <FormControl isInvalid={errors.pnat_tx_cuenta_banco?true:false}>
                    <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_BANCO}</FormLabel>
                    <Select 
                      size={'md'} 
                      defaultValue={K._SELE_ID} 
                      bg={bgInput}
                      {...register('pnat_tx_cuenta_banco', {
                        validate: {
                          seleccionar: (c) => (c !== K._SELE_ID) || `${KV._MSJE_SELECCIONE} ${KPNatural._PN_BANCO}`
                      },})} 
                    >
                      <option key='pnat_tx_cuenta_banco_s' value={K._SELE_ID}>{`${K._SELE_TEXTO}`}</option>
                      {
                        tBancos.map( banco => (
                          <option key={banco.tabl_tabl_elemento} value={banco.tabl_tabl_elemento} >
                            {banco.tabl_tabl_nombre}
                          </option>
                        ))
                      }  
                    </Select>
                    <FormErrorMessage fontSize="10">
                      {errors.pnat_tx_cuenta_banco && errors.pnat_tx_cuenta_banco.message}
                    </FormErrorMessage>
                  </FormControl>

                  {/* NRO_CUENTA ----------------------------------------------------------- */}
                  <FormControl isInvalid={errors.pnat_tx_cuenta_nro?true:false}>
                    <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_NROCUENTA}</FormLabel>
                    <Input 
                      bg={bgInput}
                      w={'50'}
                      defaultValue={''} 
                      { ...register('pnat_tx_cuenta_nro', 
                        { 
                          required: KV._CAMPO_OBL, 
                          minLength: { value: 4, message: `${KV._LARGO_MIN} 4` },
                          maxLength: { value: 30, message: `${KV._LARGO_MAX} 30` },
                        })
                      } 
                    />
                    <FormErrorMessage fontSize="10">
                      {errors.pnat_tx_cuenta_nro && errors.pnat_tx_cuenta_nro.message}
                    </FormErrorMessage>
                  </FormControl>

                  {/* TIPO_CUENTA ----------------------------------------------------------- */}
                  <FormControl isInvalid={errors.pnat_tx_cuenta_tipo?true:false}>
                    <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_TIPOCUENTA}</FormLabel>
                    <Select 
                      size={'md'} 
                      defaultValue={K._SELE_ID} 
                      bg={bgInput}
                      {...register('pnat_tx_cuenta_tipo', {
                        validate: {
                          seleccionar: (c) => (c !== K._SELE_ID) || `${KV._MSJE_SELECCIONE} ${KPNatural._PN_TIPOCUENTA}`
                      },})} 
                      
                      >
                      <option key='pnat_tx_cuenta_tipo_s' value={K._SELE_ID}>{`${K._SELE_TEXTO}`}</option>
                      {
                        tTipoCuenta.map( tipo => (
                          <option key={tipo.tabl_tabl_elemento} value={tipo.tabl_tabl_elemento} >
                            {tipo.tabl_tabl_nombre}
                          </option>
                        ))
                      }  
                    </Select>
                    <FormErrorMessage fontSize="10">
                      {errors.pnat_tx_cuenta_tipo && errors.pnat_tx_cuenta_tipo.message}
                    </FormErrorMessage>
                  </FormControl>

                </Stack>

                {/* CENTRO_GESTION ----------------------------------------------------------- */}
                <FormControl isInvalid={errors.cges_id_default?true:false}>
                  <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_CGESTION}</FormLabel>
                  <Select 
                    size={'md'} 
                    defaultValue={K._SELE_ID} 
                    bg={bgInput}
                    {...register('cges_id_default', {
                      validate: {
                        seleccionar: (c) => (c !== K._SELE_ID) || `${KV._MSJE_SELECCIONE} ${KPNatural._PN_CGESTION}`
                    },})} 
                  >
                    <option key='cges_id_default_s' value={K._SELE_ID}>{`${K._SELE_TEXTO}`}</option>
                    {
                      tCentrosGestion.map( cgestion => (
                        <option key={cgestion.cges_cges_id} value={cgestion.cges_cges_id} >
                          {cgestion.cges_cges_nombre}
                        </option>
                      ))
                    }  
                  </Select>
                  <FormErrorMessage fontSize="10">
                    {errors.cges_id_default && errors.cges_id_default.message}
                  </FormErrorMessage>
                </FormControl>

                {/* <Divider /> */}

               {
                mostrarCrear
                ?
                  <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
                    <Button colorScheme='teal' variant='outline' type="submit" isLoading={isSubmitting}>
                      {KPNatural._PN_BOTON_CREAR}
                    </Button>
                  </Flex>
                :
                  null
                }
                
                {
                <>
                  { mostrarMensajeOK && <Textarea readOnly={true} bg={colorBGok} color={colorTextMensajes} value={`${mensajeActualizar}`} /> }
                  { mostrarMensajeError && <Textarea isInvalid readOnly={true} bg={colorBGErrores} color={colorTextMensajes} value={`${mensajeActualizar}...`} h={120}/> }
                </>
              }
              </Stack>

            </form>
          </VStack>
        </Box>
      </Flex>
    </Container>
  )
}

export default PNaturalCrear;