import { useParams } from "react-router-dom"
import { useFetch } from '../../hooks/useFetch';
import ContratosRspta from './ContratoConsRspta'
import { servContratosConsultar } from '../../utils/servicios';
import { IUseFetch } from '../../interfaces/iEndpoints';
import { useForm } from 'react-hook-form'
import { limpiarRut, listaCGestion, podarCGestion } from '../../utils/utilidades';
import { useContext, useState } from 'react';
import {
    Box,
    Button,
    Checkbox, 
    CheckboxGroup,
    Container,
    Divider,
    Flex,
    FormLabel,
    FormControl,
    Heading,
    HStack,
    Input,
    Stack,
    VStack,
    useColorModeValue,
    Select,
  } from '@chakra-ui/react'
import { K, KApps, KContratos, KEstado } from "../../utils/constantes";
import { TablasContext } from "../../context/TablasContext";

type FormData = {
  pnat_rut        : string,
  ctto_id         : string,
  ctto_nro_interno: string,
  ctto_nombre     : string,
  cges_id_list    : string,
  esta_id_b     : string,
  esta_id_r     : string,
  esta_id_f1    : string,
  esta_id_fp    : string,
  esta_id_f2    : string,
  esta_id_f    : string,
  ctto_fecha_ini  : string,
  ctto_fecha_fin  : string,
  cges_id_select  : string,
}
  
const ContratoCons = () => {

  const { tCentrosGestion, tAplicacionesPermisos } = useContext ( TablasContext );

  const { register, handleSubmit, getValues, formState: { isSubmitting } } = useForm<FormData>();
  const [primeraVez, setPrimeravez] = useState(true);

  const tCentrosGestionAut = podarCGestion (tCentrosGestion, tAplicacionesPermisos, KApps._PANEL_CONTROL);

  const params = useParams();
  const {p} = params;

  let ce:string[] = [];
  let cges_id_list = '';
  let esta_id_list = '';

  let esta_id_default:string[] = [];

  if (primeraVez) {
    
    let cges_id_list_envio = ''

    if (p) {
      ce = p.split('|');

      cges_id_list = ce[0];
      cges_id_list_envio = listaCGestion (tCentrosGestionAut, cges_id_list );

      switch (ce[1]) {
        case KEstado._BORRADOR_P:
          esta_id_list = KEstado._BORRADOR;
          esta_id_default.push(esta_id_list);
          break;
        case KEstado._RECHAZADO_P:
          esta_id_list = KEstado._RECHAZADO;
          esta_id_default.push(esta_id_list);
          break;
        case KEstado._PENDIENTES_P:
          esta_id_list = `${KEstado._ENVIADO_A_FIRMA_1},${KEstado._FIRMA_1_OK},${KEstado._FIRMA_PROVEEDOR_OK}`;
          esta_id_default.push(KEstado._ENVIADO_A_FIRMA_1);
          esta_id_default.push(KEstado._FIRMA_1_OK);
          esta_id_default.push(KEstado._FIRMA_PROVEEDOR_OK);
          break;
        case KEstado._ENVIADO_A_FIRMA_1_P:
          esta_id_list = KEstado._ENVIADO_A_FIRMA_1;
          esta_id_default.push(esta_id_list);
          break;
        case KEstado._FIRMA_1_OK_P:
          esta_id_list = KEstado._FIRMA_1_OK;
          esta_id_default.push(esta_id_list);
          break;
        case KEstado._FIRMA_PROVEEDOR_OK_P:
          esta_id_list = KEstado._FIRMA_PROVEEDOR_OK;
          esta_id_default.push(esta_id_list);
          break;
        case KEstado._TODOS_P:
          esta_id_list = `${KEstado._BORRADOR},${KEstado._RECHAZADO},${KEstado._ENVIADO_A_FIRMA_1},${KEstado._FIRMA_1_OK},${KEstado._FIRMA_PROVEEDOR_OK},${KEstado._FIRMA_2_OK}`;
          esta_id_default.push(KEstado._BORRADOR);
          esta_id_default.push(KEstado._RECHAZADO);
          esta_id_default.push(KEstado._ENVIADO_A_FIRMA_1);
          esta_id_default.push(KEstado._FIRMA_1_OK);
          esta_id_default.push(KEstado._FIRMA_PROVEEDOR_OK);
          esta_id_default.push(KEstado._FIRMA_2_OK);
          break;
        case KEstado._FIRMA_2_OK_P:
          esta_id_list = KEstado._FIRMA_2_OK;
          esta_id_default.push(esta_id_list);
          break;
      }
      if (cges_id_list_envio === ".") cges_id_list_envio = '_'
    }

    servContratosConsultar.params = {
      ...servContratosConsultar.params,
      cges_id_list: cges_id_list_envio,
      esta_id_list: esta_id_list,
      pnat_rut : '',
      ctto_id : '',
      ctto_nombre: '',
      ctto_fecha_ini: '',
      ctto_fecha_fin: '',
    }

  }
  else {
    if (getValues('esta_id_b')) esta_id_default.push(KEstado._BORRADOR);
    if (getValues('esta_id_r')) esta_id_default.push(KEstado._RECHAZADO);
    if (getValues('esta_id_f1')) esta_id_default.push(KEstado._ENVIADO_A_FIRMA_1);
    if (getValues('esta_id_fp')) esta_id_default.push(KEstado._FIRMA_1_OK);
    if (getValues('esta_id_f2')) esta_id_default.push(KEstado._FIRMA_PROVEEDOR_OK);
    if (getValues('esta_id_f')) esta_id_default.push(KEstado._FIRMA_2_OK);
  }   

  const contratosServ:IUseFetch = useFetch (servContratosConsultar, JSON.stringify ( servContratosConsultar.params ), 'ContratoCons');

  const bgColorC  = useColorModeValue("gray.200", "gray.900");
  const bgColorB  = useColorModeValue("white", "gray.700");
  const colorLabel = useColorModeValue("blue.500", "whiteAlpha.800");
  const bgInput    = useColorModeValue("white", "gray.600");
  const colorTitulo = useColorModeValue(K._COLOR_TITULO_L, K._COLOR_TITULO_D);

  const onSubmit = ( {ctto_id, pnat_rut, ctto_nombre, ctto_fecha_ini, ctto_fecha_fin, esta_id_f1, esta_id_fp, esta_id_f2, esta_id_b, esta_id_r, esta_id_f, cges_id_select}: FormData ) => {

    const esta_id_list_todos = `${KEstado._BORRADOR},${KEstado._RECHAZADO},${KEstado._ENVIADO_A_FIRMA_1},${KEstado._FIRMA_1_OK},${KEstado._FIRMA_PROVEEDOR_OK},${KEstado._FIRMA_2_OK}`;

    let esta_id_list_envio = '';
    if (esta_id_f1 ) esta_id_list_envio = (esta_id_list_envio.length <= 0) ? KEstado._ENVIADO_A_FIRMA_1 : (esta_id_list_envio + `,${KEstado._ENVIADO_A_FIRMA_1}`);
    if (esta_id_fp ) esta_id_list_envio = (esta_id_list_envio.length <= 0) ? KEstado._FIRMA_1_OK : (esta_id_list_envio + `,${KEstado._FIRMA_1_OK}`);
    if (esta_id_f2 ) esta_id_list_envio = (esta_id_list_envio.length <= 0) ? KEstado._FIRMA_PROVEEDOR_OK : (esta_id_list_envio + `,${KEstado._FIRMA_PROVEEDOR_OK}`);
    if (esta_id_b ) esta_id_list_envio = (esta_id_list_envio.length <= 0) ? KEstado._BORRADOR : (esta_id_list_envio + `,${KEstado._BORRADOR}`);
    if (esta_id_r ) esta_id_list_envio = (esta_id_list_envio.length <= 0) ? KEstado._RECHAZADO : (esta_id_list_envio + `,${KEstado._RECHAZADO}`);
    if (esta_id_f ) esta_id_list_envio = (esta_id_list_envio.length <= 0) ? KEstado._FIRMA_2_OK : (esta_id_list_envio + `,${KEstado._FIRMA_2_OK}`);
    if (esta_id_list_envio.length <= 0) esta_id_list_envio = '_';

    if (esta_id_f1 ) esta_id_default.push(KEstado._ENVIADO_A_FIRMA_1);
    if (esta_id_fp ) esta_id_default.push(KEstado._FIRMA_1_OK);
    if (esta_id_f2 ) esta_id_default.push(KEstado._FIRMA_PROVEEDOR_OK);
    if (esta_id_b ) esta_id_default.push(KEstado._BORRADOR);
    if (esta_id_r ) esta_id_default.push(KEstado._RECHAZADO);
    if (esta_id_f ) esta_id_default.push(KEstado._FIRMA_2_OK);

    const cges_id_list_envio = listaCGestion (tCentrosGestionAut, cges_id_select );

    servContratosConsultar.params = {
      ...servContratosConsultar.params,
      ctto_id: ctto_id,
      cges_id_list: cges_id_list_envio,
      esta_id_list: (ctto_id.length > 0) ? esta_id_list_todos : ( ( ( (pnat_rut.length <= 0) && (ctto_nombre.length <= 0) ) ? esta_id_list_envio : '') ),
      pnat_rut : limpiarRut(pnat_rut),
      ctto_nombre: ctto_nombre,
      ctto_fecha_ini: ctto_fecha_ini,
      ctto_fecha_fin: ctto_fecha_fin,
      random: Math.random(),
    }

    setPrimeravez(false);

  }

  return (
    contratosServ.cargando
    ?
    (
        <Button isLoading={contratosServ.cargando} loadingText={K._CARGANDO} colorScheme='teal' variant='outline' />
    )
    :
    <Container 
            py={{ base: '1', md: '1' }} 
            px={{ base: '1', md: '1' }} 
            maxW='6xl' 
            p="3" 
            bg={ bgColorC }
          >
      <Flex 
        direction={{ base: 'column', lg: 'row' }}
        overflowY="auto"
        bg={ bgColorB }
        py={{ base: '1', md: '2' }} 
        px={{ base: '1', md: '2' }} 
      >

        <Box
            bg={ bgColorB }
            borderRadius={{ base: 'none', sm: 'md' }}
        >
          <VStack spacing="2" p='2'>

            <Heading size='md' p={3} color={colorTitulo}>{KContratos._C_TITULO}</Heading>

            <Divider />

            <form  onSubmit={ handleSubmit(onSubmit) } >

              <VStack>
              <HStack spacing="6" direction={{ base: 'column', md: 'row' }}>
                <FormControl>
                  <FormLabel color={colorLabel} fontSize="xs">{KContratos._C_RUT}</FormLabel>
                  <Input w='40' defaultValue={servContratosConsultar.params.pnat_rut} {...register('pnat_rut', { })} />
                </FormControl>

                <FormControl>
                  <FormLabel color={colorLabel} fontSize="xs">{KContratos._C_ID}</FormLabel>
                  <Input w='20' defaultValue={''} {...register('ctto_id', { })} />
                </FormControl>

                <FormControl>
                  <FormLabel color={colorLabel} fontSize="xs">{KContratos._C_PERS_CTTO}</FormLabel>
                    <Input w='40' defaultValue={servContratosConsultar.params.ctto_nombre}{...register('ctto_nombre', {})} />
                </FormControl>

                <FormControl>
                  <FormLabel color={colorLabel} fontSize="xs">{KContratos._C_FECHA_DESDE}</FormLabel>
                    <Input w='40' type="date" defaultValue={servContratosConsultar.params.ctto_fecha_ini}{...register('ctto_fecha_ini', {})} />
                </FormControl>

                <FormControl>
                  <FormLabel color={colorLabel} fontSize="xs">{KContratos._C_FECHA_HASTA}</FormLabel>
                    <Input w='40' type="date" defaultValue={servContratosConsultar.params.ctto_fecha_fin}{...register('ctto_fecha_fin', {})} />
                </FormControl>

                <FormControl>
                  <FormLabel>&nbsp;</FormLabel>
                    <Button colorScheme='teal' variant='outline' type="submit" isLoading={isSubmitting}>
                      {KContratos._C_BUSCAR}
                  </Button>
                </FormControl>
              </HStack>
              </VStack>

              <VStack align="left" p="5">
                <FormControl>
                  <CheckboxGroup colorScheme='blue' defaultValue={esta_id_default} size="sm" >
                    <Stack spacing={[1, 5]} direction={['column', 'row']}>
                      <Checkbox key={KEstado._BORRADOR} value={KEstado._BORRADOR} {...register('esta_id_b', { })}>{KEstado._BORRADOR_T1}</Checkbox>
                      <Checkbox key={KEstado._RECHAZADO} value={KEstado._RECHAZADO} {...register('esta_id_r', { })}>{KEstado._RECHAZADO_T1}</Checkbox>
                      <Checkbox key={KEstado._ENVIADO_A_FIRMA_1} value={KEstado._ENVIADO_A_FIRMA_1} {...register('esta_id_f1', { })}>{KEstado._ENVIADO_A_FIRMA_1_T1}</Checkbox>
                      <Checkbox key={KEstado._FIRMA_1_OK} value={KEstado._FIRMA_1_OK} {...register('esta_id_fp', { })}>{KEstado._FIRMA_1_OK_T1}</Checkbox>
                      <Checkbox key={KEstado._FIRMA_PROVEEDOR_OK} value={KEstado._FIRMA_PROVEEDOR_OK} {...register('esta_id_f2', { })}>{KEstado._FIRMA_PROVEEDOR_OK_T1}</Checkbox>
                      <Checkbox key={KEstado._FIRMA_2_OK} value={KEstado._FIRMA_2_OK} {...register('esta_id_f', { })}>{KEstado._FIRMA_2_OK_T1}</Checkbox>
                    </Stack>
                  </CheckboxGroup>
                </FormControl>
              </VStack>

              <VStack align="left" p="3">

                {/* CENTRO_GESTION ----------------------------------------------------------- */}
                <FormControl>
                  <FormLabel fontSize="xs" color={colorLabel}>{KContratos._C_CGESTION}</FormLabel>
                  <Select 
                    size={'sm'} 
                    defaultValue={cges_id_list} 
                    bg={bgInput}
                    {...register('cges_id_select')} 
                  >
                    {
                      tCentrosGestionAut.length > 1
                      ?
                      <option key={K._TODO_ID} value={K._TODO_ID}>{`${K._TODO_TEXTO}`}</option>
                      :
                      null
                    }
                    {
                      tCentrosGestionAut.map( cgestion => (
                        <option key={cgestion.cges_cges_id} value={cgestion.cges_cges_id} >{cgestion.cges_cges_nombre}</option>
                      ))
                    }  
                  </Select>
                </FormControl>



              </VStack>
            </form>
          </VStack>
        </Box>
      </Flex >

      <Container 
        maxW='12xl' 
        bg={ bgColorB }
        p='2'
      >
        <Box bg={ bgColorB }>
          <ContratosRspta respuesta={contratosServ} />
        </Box>
        <Divider />
        <Container bg={ bgColorB } p='40' />
      </Container>

    </Container>

  )
}

export default ContratoCons;