import { FC, useState } from 'react';

import { Divider, VStack } from "@chakra-ui/react";
import ContratoAdjuntosForm from "./ContratoAdjuntosForm";
import ContratoAdjuntosConsulta from "./ContratoAdjuntosConsulta";
import PNaturalAdjuntos from '../pnaturalMant/PNaturalAdjuntos';
import { IContratoConsultar } from '../../interfaces/iEndpoints';

interface Props {
  id: number;
  datos: IContratoConsultar;
  ambiente: string | undefined;
  usua_id: string | undefined;
}

const ContratoAdjuntos: FC<Props> = ({ id, datos, ambiente, usua_id }) => {

  const [actualizar, setActualizar] = useState(0);
  return (
    <VStack>
      <ContratoAdjuntosConsulta id={id} ambiente={ambiente} actualizar={actualizar} setActualizar={setActualizar} />
      <Divider />
      <PNaturalAdjuntos id={datos.pnat_pnat_rut} ambiente={ambiente} />
      <Divider />
      <ContratoAdjuntosForm id={id} ambiente={ambiente} usua_id={usua_id} actualizar={actualizar} setActualizar={setActualizar} />
    </VStack>  
  )
}

export default ContratoAdjuntos;