import { FC, useReducer, useEffect } from 'react';
import { AuthContext, authReducer } from './';
import Cookies from 'js-cookie';
import { IUsuarioContext } from '../../interfaces/iUsuarioContext';
import { _HOSTNAME_CONTRATOS_PROD, _HOSTNAME_CONTRATOS_PROD_P, _HOSTNAME_CONTRATOS_QA, _HOSTNAME_CONTRATOS_QA_P, _HOSTNAME_LOCALHOST, _REACT_APP_BASE_URL_API_DESA, _REACT_APP_BASE_URL_API_PROD } from '../../utils/constantes';

const setAmbienteX = ( ): string => {
    const home =  window.location;
    switch (home.hostname) {
        case _HOSTNAME_LOCALHOST: return( _REACT_APP_BASE_URL_API_DESA ); //??? _REACT_APP_BASE_URL_API_PROD _REACT_APP_BASE_URL_API_DESA
        case _HOSTNAME_CONTRATOS_QA: return( _REACT_APP_BASE_URL_API_DESA );
        case _HOSTNAME_CONTRATOS_QA_P: return( _REACT_APP_BASE_URL_API_DESA );
        case _HOSTNAME_CONTRATOS_PROD: return( _REACT_APP_BASE_URL_API_PROD );
        case _HOSTNAME_CONTRATOS_PROD_P: return( _REACT_APP_BASE_URL_API_PROD );
        default: return( _REACT_APP_BASE_URL_API_DESA );
    }
}

export interface AuthState {
    conectado: boolean;
    ambiente?: string;
    usuario?: IUsuarioContext;
}

type Props = {
    children?: React.ReactNode
  };

const AUTH_INITIAL_STATE: AuthState = {
    conectado: false,
    ambiente: setAmbienteX(),
    usuario: undefined,
}


export const AuthProvider:FC<Props> = ({ children }) => {

    const [state, dispatch] = useReducer( authReducer, AUTH_INITIAL_STATE );
    const  ambienteX = setAmbienteX();

    useEffect(() => {
        renovarToken();
    }, [])

    const renovarToken = async(): Promise<boolean> => {
// console.log('_____renovar_token_____')
        let exito = false;
        const jwt = Cookies.get ("jwt");

        if (!jwt) return exito;

        await fetch(`${ambienteX}/renovar-token`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            }
        }) 
        
        .then(async resolve => {
            if (resolve.ok) 
                return (await resolve.json());
            else {
                const text = await resolve.text();
                throw new Error(text);
            }
        })
            
        .then(data => {
            const { nuevo_token } = data.data;
            Cookies.set ("jwt", nuevo_token, { SameSite: 'Lax'} );
            const payloadData = JSON.parse( atob(nuevo_token.split('.')[1] ));
            dispatch({ type: 'nuevo_token', payload:  payloadData });
            exito = true;
        })

        .catch ( (error) => {
            Cookies.remove("jwt");
            dispatch({ type: 'logout'});
// console.log('_______renovarToken____error___________', error)
            exito = false;
        });

        return exito;
    }
    
    const login = async( usua_id: string, usua_clave: string ): Promise<boolean> => {

        let exito = false;

//obtiene-token-pnatural?pnat_rut=160075635&pnat_clave=test.pnatural
//obtiene-token-pjuridica?pjur_rut=716443000&pnat_rut=118907299&pnat_clave=test.rlegal
        
        await fetch(`${ambienteX}/obtener-token-usuario`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
             body: JSON.stringify({
                usua_id,
                usua_clave,
            })
        }) 

        .then(async resolve => {
            if (resolve.ok) 
                return (await resolve.json());
            else {
                const text = await resolve.text();
                throw new Error(text);
            }
        })
            
        .then(data => {
            const { token } = data.data;
            Cookies.set ("jwt", token, { SameSite: 'Lax'} );
            const payloadData = JSON.parse( atob(token.split('.')[1] ));
            dispatch({ type: 'login', payload:  payloadData });
            exito = true;
        })
        .catch ( (error) => {
            Cookies.remove("jwt");
            // const { code, message } = JSON.parse(error.message);
            exito = false;;
        });
        return (exito);
    }

    const logout = ( ): void => {
        Cookies.remove("jwt");
        dispatch({ type: 'logout' });
    }

    return (
        <AuthContext.Provider value={{
            ...state,
            login,
            logout,
            renovarToken,
        }}>
            { children }
        </AuthContext.Provider>
    )
};