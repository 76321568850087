import { useParams } from "react-router-dom"
import { K } from "../../utils/constantes";
import ContratoCrear from './ContratoCrear';
import ContratoEditarTabs from "./ContratoEditarTabs";

const ContratoMant = () => {

  const params = useParams();
  const esNuevo = ( params && params.id && (params.id === K._NUEVO) );

  return (
    (
      (!params || !params.id)
        ?
          null
        : (
            esNuevo
            ?
            <ContratoCrear />
            :
            <ContratoEditarTabs id={parseInt( params.id)} />
          )
    )
  )
}

export default ContratoMant;