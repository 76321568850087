import { FC, useContext } from 'react';
import { useState } from 'react';
import { servContratosConsultar, servContratoSubir } from '../../utils/servicios';
import { IContratoSubir, IEndpoint, IContratoConsultar } from '../../interfaces/iEndpoints';
import { IconSubir4 } from '../../utils/Icons';
import { K, KCasosTransicion, KContratos, KErroresSubirContrato, KEstado, KPerfiles } from '../../utils/constantes';
import { AuthContext } from '../../context';
import { useForm } from 'react-hook-form';
import { enviarCorreo, fParametros, leerUsuario, preparaNotificacionSubir, validarFileFirma } from '../../utils/utilidades';
import { TablasContext } from '../../context/TablasContext';
import { Table, Tr } from '@chakra-ui/react';
import { FiCheck, FiXCircle } from 'react-icons/fi';
import { fetchSubirContrato } from '../../utils/fetchSubirContrato';
import {
  Box,
  Button,
  Container,
  HStack,
  Heading,
  Stack,
  VStack,
  useColorModeValue,
  Text,
  useToast,
  FormControl,
  Input,
  TableContainer,
  Thead,
  Th,
  Tbody,
  Td,
} from '@chakra-ui/react'
import { fetchQS } from '../../utils/fetchQS';

interface Props {
  nFirma: number;
}

interface IArchivo {
  file: Blob;
  name: string;
  ctto_id: number;
  pnat_rut: string;
  validaOK: boolean;
  subidoOK: number;
  mensaje: string;
  lastModified: number;
  f1: string | null | undefined;
  fp: string | null | undefined;
  otros: string | null | undefined;
  cargando:boolean;
  contrato:IContratoConsultar[];
}

interface ISubir {
  ctto_id: number;
  caso: string;
  esta_id_actual: string;
  esta_id_nuevo: string;
  archivo: string | Blob;
  usua_id: string | null;
  mensaje: string;
  rut:  string;
}

const MisPendientesSubir: FC<Props> = ({ nFirma }) => {

    var archivosValidos:IArchivo[] = [];
    
    const { usuario, ambiente } = useContext( AuthContext );
    const [ archivos, setArchivos ] = useState (archivosValidos);
    const [ actualizacion, setActualizacion ] = useState (-1);
  
    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<IContratoSubir>();
    const { tParametros } = useContext( TablasContext );
    const toast = useToast();

    const vParametros = fParametros(tParametros);

    var rutFirma = '?';
    var rutTipoFirma = 0;
    var caso = '?';
    var esta_id_actual = '?';
    var esta_id_nuevo = '?';
    var arch_sufijo = '?';

    switch (usuario?.perf_id) {
      case KPerfiles._PERF_GERENTE_FINANZAS:
        caso = KCasosTransicion._CASO_TRANS_FIRMA1_OK;
        esta_id_actual = KEstado._ENVIADO_A_FIRMA_1;
        esta_id_nuevo = KEstado._FIRMA_1_OK;
        arch_sufijo = '';
        rutTipoFirma = 1;
        break;
      case KPerfiles._PERF_DIRECCION_EJECUTIVA:
        caso = KCasosTransicion._CASO_TRANS_FIRMA2_OK;
        esta_id_actual = KEstado._FIRMA_PROVEEDOR_OK;
        esta_id_nuevo = KEstado._FIRMA_2_OK;
        arch_sufijo = '_f1_fp';
        rutTipoFirma = 2;
        break;
      case KPerfiles._PERF_ADMIN_SUPERUSUARIO:
        caso = KCasosTransicion._CASO_TRANS_FIRMA2_OK;
        esta_id_actual = KEstado._FIRMA_PROVEEDOR_OK;
        esta_id_nuevo = KEstado._FIRMA_2_OK;
        arch_sufijo = '_f1_fp';
        if (nFirma === 1) {
          caso = KCasosTransicion._CASO_TRANS_FIRMA1_OK;
          esta_id_actual = KEstado._ENVIADO_A_FIRMA_1;
          esta_id_nuevo = KEstado._FIRMA_1_OK;
          arch_sufijo = '';
          rutTipoFirma = 1;
        }
        else if (nFirma === 2) {
          caso = KCasosTransicion._CASO_TRANS_FIRMA2_OK;
          esta_id_actual = KEstado._FIRMA_PROVEEDOR_OK;
          esta_id_nuevo = KEstado._FIRMA_2_OK;
          arch_sufijo = '_f1_fp';
          rutTipoFirma = 2;
        }
        break;
    }

    const colorTitulo = useColorModeValue(K._COLOR_TITULO_L, K._COLOR_TITULO_D);
    const bgColor = useColorModeValue("white", "gray.700");
    const colorBG2 = useColorModeValue("gray.200", "gray.900");

    const ConsultarContrato = async (ctto_id:string) => {

      servContratosConsultar.params = {
        ...servContratosConsultar.params,
        pnat_rut: '',
        ctto_nro_interno: '',
        ctto_nombre: '',
        cges_id_list: '',
        esta_id_list: '',
        ctto_fecha_ini: '',
        ctto_fecha_fin: '',
        ctto_id:ctto_id,
      }

      const contratoServ:IEndpoint = await fetchQS(servContratosConsultar, null, ambiente)
      if (contratoServ.code === 0) {
        const datos:any = contratoServ.data;
        return (datos)
      }
      else
        return(null)
    }
    
    const handleChangeFile = async (e:React.FormEvent<HTMLInputElement>) => {

      const cantidad = e.currentTarget.files ? e.currentTarget.files.length : 0;
      const archivos = e.currentTarget.files ? e.currentTarget.files : [];
      setArchivos([]);

      for (let i = 0; i < cantidad; i++) {
        
        const archivo = archivos[i];

        const [ctto_id, pnat_rut, nro, f1, fp, otros] = archivo.name.split('_');
        const arch_prefijo = `${ctto_id}_${pnat_rut}_${nro}${arch_sufijo}`;
        const [validaOK, mensaje] = validarFileFirma ( archivo, vParametros, arch_prefijo );
       
        const arch:IArchivo = {
          validaOK: validaOK,
          file: archivo,
          name: archivo.name,
          mensaje: mensaje,
          lastModified: archivo.lastModified,
          ctto_id: validaOK ? parseInt(ctto_id) : -1,
          pnat_rut: validaOK ? pnat_rut : '',
          subidoOK: 0,
          f1: f1,
          fp: fp,
          otros: otros,
          cargando:true,
          contrato:[],
        }
        
        await ConsultarContrato (ctto_id)
        .then ( resp => {
          arch.contrato = resp
          arch.cargando = false

          //revisar estados
          const datos = arch.contrato ? arch.contrato[0] : null
          var bDatos = false;
          if ( datos ) {
            switch (datos.ctto_esta_id_actual) {
              case KEstado._ENVIADO_A_FIRMA_1:
                if (rutTipoFirma === 1)
                  bDatos = true;
                else 
                  arch.mensaje = 'No corresponde firma este contrato (está en firma #1)';
                break;
              case KEstado._FIRMA_PROVEEDOR_OK:
                if (rutTipoFirma === 2)
                  bDatos = true;
                else 
                  arch.mensaje = 'No corresponde firmar este contrato (está en firma #2)';
                break;
              default:
                arch.mensaje = 'No corresponde firmar este contrato (fuera de secuencia)';
                break;
            }
          }
          else {
            arch.mensaje = 'Contrato no encontrado (rut, id)';
          }

          arch.validaOK = bDatos;
          archivosValidos.push(arch);
        })        
          
        setArchivos(archivosValidos);
        setActualizacion(i);
      }
    }

    const onSubmit = async( data:IContratoSubir ) => {

      archivos.forEach((archivo, index) => {

        setActualizacion(-1);
        
        if ( archivo.validaOK) {

          const contrato = archivos[index].contrato[0];
          if (rutTipoFirma === 1) {
            rutFirma = (contrato) ? (contrato.ctto_usua_rut_firma1) : '?';
          }
          else {
            if  (rutTipoFirma === 2) {
              rutFirma = (contrato) ? (`${contrato.ctto_usua_rut_firma1},${contrato.pnat_pnat_rut},${contrato.ctto_usua_rut_firma2}`) : '?';
            }
          }

          const params:ISubir = {
            ctto_id: archivo.ctto_id,
            caso: caso,
            esta_id_actual: esta_id_actual,
            esta_id_nuevo: esta_id_nuevo,
            archivo: archivo.file ? archivo.file : '',
            usua_id: usuario?.usua_id || '?subirCtto',
            mensaje: '',
            rut: rutFirma, 
          }

          subirArchivo ( index, params, servContratoSubir )  

        }

      });

      setArchivos(archivos);
    }

    const subirArchivo = async ( index:number, params:ISubir, servContratoSubir:any ) => {

      const contrato = archivos[index].contrato[0];
      const usua_id_cg1 = (contrato) ? contrato.cges_usua_id_responsable : '?';
      const usua_id_cg2 = usua_id_cg1 ? usua_id_cg1 : '?'

      const usuarioCG:any = await leerUsuario ( usua_id_cg2, ambiente);

      const resp:IEndpoint = await fetchSubirContrato ( params, servContratoSubir, ambiente );

      if (resp.code === 0) { 
    
        const correo = preparaNotificacionSubir (caso, archivos[index].contrato[0], vParametros, usuarioCG);
        await enviarCorreo ( correo, ambiente );

        archivos[index].subidoOK = 1;
        archivos[index].mensaje = 'Subido ok';

        toast({
          title: KContratos._SC_MSJE_TITULO_EXITO,
          description: `${KContratos._SC_MSJE_SUBIR_EXITO}`,
          status: 'success',
          duration: K._DURACION_TOAST_DEFAULT_CORTA,
          isClosable: true,
        })

      }
      else {

        const errorSubir = KErroresSubirContrato.filter (elemento => (elemento.codigo ===  resp.code) );
        const errorMensaje = (errorSubir.length > 0) ? errorSubir[0].mensaje : `[${resp.code} // ${resp.data}]`;

        archivos[index].subidoOK = -1;
        archivos[index].mensaje = errorMensaje;
        setActualizacion(index);
        
        toast({
          title: KContratos._SC_MSJE_TITULO_FRACASO,
          description: `${errorMensaje} [a]`,
          status: 'error',
          duration: K._DURACION_TOAST_DEFAULT_CORTA,
          isClosable: true,
        })

      }
      setArchivos(archivos);
    }
  
    return (
        <Container 
          py={{ base: '1', md: '1' }} 
          px={{ base: '1', md: '1' }} 
          maxW='8xl'
          w='6xl' 
          p="1" 
          bg={colorBG2}
        >
          <Stack direction={'column'} spacing={3}  >

              <Box overflowX="auto" bg={bgColor} p={5} >

                <VStack direction={{ base: 'column', md: 'row' }} spacing={5} align={'left'}>

                  <Heading size='md' color={colorTitulo}>{KContratos._MP_TITULO1_F}</Heading>

                  {/* --------------- PASO 3: SUBIR --------------------------------*/}
                  <form onSubmit={ handleSubmit(onSubmit) }  >
                    <Stack spacing="4" direction="row" >

                      <Stack  spacing={5}>

                        <Text color="emphasized" fontSize={'md'}>{KContratos._SC_PASO3_DESCRIPCION_F}</Text>

                        <VStack>
 
                          <FormControl isInvalid={errors.archivo?true:false}>
                            <Input 
                              required
                              multiple
                              type="file" 
                              size={'sm'}
                              { ...register('archivo', { onChange: handleChangeFile,}) } 
                            />
                          </FormControl>

                        </VStack>
                      </Stack>
                    </Stack>

                  <TableContainer  >
                    <Table colorScheme='teal' size="sm">
                      <Thead>
                        <Tr >
                          <Th></Th>
                          <Th>Id</Th>
                          <Th>Leer<br/>Contrato</Th>
                          <Th>Revisión<br/>Archivo</Th>
                          <Th>Subida<br/>Archivo</Th>
                          <Th>Mensaje</Th>
                          <Th>Nombre<br/>Archivo</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                      {
                        ( (!archivos) || (archivos.length <= 0) )
                        ?
                        (
                          <Tr key={1}>
                            <Td colSpan={5}>
                              <h1>{'no hay archivos para subir'}</h1>
                            </Td>
                          </Tr>
                        )
                        :
                        (
                          archivos.map((fila:any, index:number) => (
                            <Tr key={index}>
                                <Td>{index+1}</Td>
                                <Td>{fila.validaOK ? fila.ctto_id : (<FiXCircle fontSize="1.0rem" color={'red'}/>) }</Td>
                                <Td>{( ((fila.validaOK) && (actualizacion >= 0) )
                                        ?
                                        (
                                          (fila.cargando)
                                          ?
                                          (<Button isLoading={true} colorScheme='teal' variant='link' />)
                                          :
                                          (<FiCheck fontSize="1.0rem" color={'green'} />)
                                        )
                                        :
                                        ('')
                                        )}
                                  </Td>
                                <Td>{fila.validaOK ? <FiCheck fontSize="1.0rem" color={'green'} /> : <FiXCircle fontSize="1.0rem" color={'red'}/>}</Td>
                                <Td>{
                                      (actualizacion >= 0)
                                      ?
                                      (
                                        (fila.subidoOK !== 0) 
                                        ? 
                                        (
                                            (fila.subidoOK > 0) 
                                            ? 
                                              <FiCheck fontSize="1.0rem" color={'green'} /> 
                                            : 
                                              <FiXCircle fontSize="1.0rem" color={'red'}/>  
                                        ) 
                                        : ''
                                      )
                                      :
                                      ('')
                                    }
                                </Td>
                                <Td color={'red'}>{fila.mensaje}</Td>
                                <Td>{fila.name}</Td>

                                {/* <Td color={'white'}>{( (actualizacion >= 0) && (fila.contrato) ) ? JSON.stringify(fila.contrato) : '?' }</Td> */}
                                
                            </Tr>
                          ))
                        )
                      }
                      </Tbody>
                    </Table>
                  </TableContainer>

                  {
                    ( (!archivos) || (archivos.length <= 0) )
                    ?
                      null
                    :
                      <HStack spacing={4} py={'6'}>
                        { /* boton subir ------------------------------------------------------ */ }
                        <Button 
                          colorScheme='green' 
                          variant='outline' 
                          isLoading={isSubmitting} 
                          fontSize='md'
                          leftIcon={<IconSubir4 />}
                          type='submit'
                        >
                          {KContratos._SC_BOTON_SUBIR_N}
                        </Button>
                      </HStack>
                  }

                  </form>

                </VStack>              
              </Box>
          </Stack>              
        </Container>
    )
 
  }

  export default MisPendientesSubir;