import { FC, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { TablasContext } from '../../context/TablasContext';
import { IAdjuntosForm, IEndpoint } from '../../interfaces/iEndpoints';
import { K, KContratos, KErroresSubirAdjunto, KV } from '../../utils/constantes';
import { 
  Button, 
  Container, 
  Divider, 
  useColorModeValue, 
  VStack, 
  HStack, 
  FormControl, 
  FormLabel, 
  Select, 
  FormErrorMessage, 
  Text, 
  Input,
  useToast,
  Heading
} from '@chakra-ui/react';
import { servContratoAdjuntoSubir } from '../../utils/servicios';
import { fParametros, validarFile, validarInputFile } from '../../utils/utilidades';
import { fetchSubirAdjunto } from '../../utils/fetchSubirAdjunto';

interface Props {
    id: number;
    ambiente: string | undefined;
    usua_id: string | undefined;
    actualizar: number;
    setActualizar: React.Dispatch<React.SetStateAction<number>>;
}

interface Datos {
  ctto_id: number;
  tadj_id: string;
  adju_nombre: string;
  adju_descripcion: string;
  adju_path: string | Blob;
  usua_id: string | null;
}


const ContratoAdjuntosForm: FC<Props> = ({ id, ambiente, usua_id, actualizar, setActualizar }) => {

  const { tTipoAdjunto, tParametros } = useContext( TablasContext );
  const { register, handleSubmit, setValue, formState: { errors, isSubmitting } } = useForm<IAdjuntosForm>();
  
  const toast = useToast();

  const colorBG = useColorModeValue("white", "gray.700");
  const colorLabel = useColorModeValue("blue", "whiteAlpha.900");
  const colorTitulo = useColorModeValue(K._COLOR_TITULO_L, K._COLOR_TITULO_D);

  const bgInput       = useColorModeValue("white", "gray.600");
  const readOnlyInput = false;

  const vParametros = fParametros(tParametros);

  const handleChangeFile = (e:React.FormEvent<HTMLInputElement>) => {
    const [validaOK, validaMsje] = validarFile ( (e.currentTarget.files ? e.currentTarget.files[0] : null), vParametros );
    if (!validaOK) {
      toast({
        title: KV._CTTO_MSJE_ADJUNTO_AVISO,
        description: validaMsje,
        status: 'error',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })
    }
  }

  const onSubmit = async ( data:IAdjuntosForm ) => {

    var datos:Datos = {
      ctto_id: id,
      tadj_id: '',
      adju_nombre: '',
      adju_descripcion: '',
      usua_id: usua_id || '?adjunto',
      adju_path: ''
    }
    var bSubir = false;

    for (let index = 1; index <= 3; index++) {

      bSubir = false;
      switch (index) {
        case 1:
          if ( (data.tadj_id_1 !== K._SELE_ID) && (data.adju_path_1) ) {
            const d = new Date();
            const d_str = d.toJSON();
            datos = {
              ...datos,
              tadj_id: data.tadj_id_1,
              adju_nombre: `${id}_${d_str}`,
              adju_descripcion: data.adju_descripcion_1,
              adju_path: data.adju_path_1[0],
            }
            bSubir = true;
          }     
          break;
        case 2:
          if ( (data.tadj_id_2 !== K._SELE_ID) && (data.adju_path_2) ) {
            const d = new Date();
            const d_str = d.toJSON();
            datos = {
              ...datos,
              tadj_id: data.tadj_id_2,
              adju_nombre: `${id}_${d_str}`,
              adju_descripcion: data.adju_descripcion_2,
              adju_path: data.adju_path_2[0],
            }
            bSubir = true;
          }     
          break;
        case 3:
          if ( (data.tadj_id_3 !== K._SELE_ID) && (data.adju_path_3) ) {
            const d = new Date();
            const d_str = d.toJSON();
            datos = {
              ...datos,
              tadj_id: data.tadj_id_3,
              adju_nombre: `${id}_${d_str}`,
              adju_descripcion: data.adju_descripcion_3,
              adju_path: data.adju_path_3[0],
            }
            bSubir = true;
          }     
          break;
      }

      if (bSubir) {
        const resp:IEndpoint = await fetchSubirAdjunto ( datos, servContratoAdjuntoSubir, ambiente );

        if (resp.code === 0) {
          toast({
            title: KContratos._F_MSJE_TITULO_EXITO,
            description: `${KContratos._F_MSJE_ADJUNTAR_EXITO} [${index}]`,
            status: 'success',
            duration: K._DURACION_TOAST_DEFAULT,
            isClosable: true,
          })
        }
        else {

          const errorSubir = KErroresSubirAdjunto.filter (elemento => (elemento.codigo ===  resp.code) );
          const errorMensaje = (errorSubir.length > 0) ? errorSubir[0].mensaje : `[${resp.code} // ${resp.data}]`;
  
          toast({
            title: KContratos._F_MSJE_TITULO_FRACASO,
            description: `${errorMensaje}]`,
            status: 'error',
            duration: K._DURACION_TOAST_DEFAULT,
            isClosable: true,
          })
        }
      }
    }
    setActualizar(actualizar+1);
    setValue('tadj_id_1', K._SELE_ID);
    setValue('adju_descripcion_1', '');
    setValue('adju_path_1', '');

    setValue('tadj_id_2', K._SELE_ID);
    setValue('adju_descripcion_2', '');
    setValue('adju_path_2', '');

    setValue('tadj_id_3', K._SELE_ID);
    setValue('adju_descripcion_3', '');
    setValue('adju_path_3', '');
  }

  return(
    <Container 
        py={{ base: '1', md: '2' }} 
        px={{ base: '1', md: '4' }} 
        maxW='8xl' 
        bg={colorBG}
    >
      <VStack spacing="3">

        <form onSubmit={ handleSubmit(onSubmit) }  >

          <VStack p="5">

          <Heading size='sm' p={3}  color={colorTitulo}>{KContratos._AF_TITULO}</Heading>


            <HStack>
              { /* adjunto_tipo ----------------------------------------------------------- */ }
              <FormControl isInvalid={errors.tadj_id_1?true:false}>
                <FormLabel fontSize="xs" color={colorLabel}>{KContratos._AF_TIPO}</FormLabel>
                <Select 
                  size={'sm'} 
                  defaultValue={K._SELE_ID} 
                  bg={bgInput}
                  {...register('tadj_id_1', {
                    validate: { seleccionar: (c) => (c !== K._SELE_ID) || `${KV._MSJE_SELECCIONE} ${KContratos._AF_TIPO_SEL} ` }
                  })} 
                >
                  <option key='tadj_id_1_s' value={K._SELE_ID}>{`${K._SELE_TEXTO}`}</option>
                  {
                    tTipoAdjunto.map( adjunto => (
                      <option key={adjunto.tabl_tabl_elemento} value={adjunto.tabl_tabl_elemento} >{adjunto.tabl_tabl_nombre}</option>
                    ))
                  }  
                </Select>
                <FormErrorMessage fontSize="10">
                  {errors.tadj_id_1 && errors.tadj_id_1.message}
                </FormErrorMessage>
              </FormControl>


              { /* adjunto_nombre ----------------------------------------------------------- */ }
              <FormControl isInvalid={errors.adju_descripcion_1?true:false}>
                <FormLabel fontSize="xs" color={colorLabel}>
                  <HStack>
                    <Text>{KContratos._AF_DESCRIPCION}</Text>
                  </HStack>
                </FormLabel>
                <Input 
                  bg={bgInput}
                  defaultValue={''} 
                  readOnly={readOnlyInput}
                  size='sm'
                  { ...register('adju_descripcion_1', 
                    { 
                      required: KV._CAMPO_OBL, 
                      minLength: { value:   5, message: `${KV._LARGO_MIN} 5`   },
                      maxLength: { value: 100, message: `${KV._LARGO_MAX} 100` },
                    })
                  } 
                />
                <FormErrorMessage fontSize="10">
                  {errors.adju_descripcion_1 && errors.adju_descripcion_1.message}
                </FormErrorMessage>
              </FormControl>

              { /* file ----------------------------------------------------------- */ }
              <FormControl isInvalid={errors.adju_path_1?true:false}>
                <FormLabel fontSize="xs" color={colorLabel}>
                  <HStack>
                    <Text>{KContratos._AF_DOCUMENTO}</Text>
                  </HStack>
                </FormLabel>
                <Input 
                  type="file" 
                  size={'sm'}
                  { ...register('adju_path_1', { 
                      required: KV._CAMPO_OBL, 
                      onChange: handleChangeFile,
                      validate: {
                        revisar: (v) => validarInputFile ( v, vParametros ) || KV._CTTO_MSJE_ADJUNTO_INPUT
                      },
                    }) } 
                />
                <FormErrorMessage fontSize="10">
                  {errors.adju_path_1 && errors.adju_path_1.message}
                </FormErrorMessage>
              </FormControl>

              </HStack>






              <HStack>
              { /* adjunto_tipo ----------------------------------------------------------- */ }
              <FormControl isInvalid={errors.tadj_id_2?true:false}>
              <FormLabel fontSize="xs" color={colorLabel}>{KContratos._AF_TIPO}</FormLabel>
              <Select 
                size={'sm'} 
                defaultValue={''} 
                bg={bgInput}
                disabled={readOnlyInput}
                {...register('tadj_id_2', {})} 
              >
                <option key='tadj_id_2_s' value={K._SELE_ID}>{`${K._SELE_TEXTO}`}</option>
                {
                  tTipoAdjunto.map( adjunto => (
                    <option key={adjunto.tabl_tabl_elemento} value={adjunto.tabl_tabl_elemento} >{adjunto.tabl_tabl_nombre}</option>
                  ))
                }  
              </Select>
              <FormErrorMessage fontSize="10">
                {errors.tadj_id_2 && errors.tadj_id_2.message}
              </FormErrorMessage>
            </FormControl>


              { /* adjunto_nombre ----------------------------------------------------------- */ }
              <FormControl isInvalid={errors.adju_descripcion_2?true:false}>
                <FormLabel fontSize="xs" color={colorLabel}>
                  <HStack>
                    <Text>{KContratos._AF_DESCRIPCION}</Text>
                  </HStack>
                </FormLabel>
                <Input 
                  bg={bgInput}
                  defaultValue={''} 
                  readOnly={readOnlyInput}
                  size={'sm'} 
                  { ...register('adju_descripcion_2', 
                    { 
                      minLength: { value:   5, message: `${KV._LARGO_MIN} 5`   },
                      maxLength: { value: 100, message: `${KV._LARGO_MAX} 100` },
                    })
                  } 
                />
                <FormErrorMessage fontSize="10">
                  {errors.adju_descripcion_2 && errors.adju_descripcion_2.message}
                </FormErrorMessage>
              </FormControl>

              { /* file ----------------------------------------------------------- */ }
              <FormControl isInvalid={errors.adju_path_2?true:false}>
                <FormLabel fontSize="xs" color={colorLabel}>
                  <HStack>
                    <Text>{KContratos._AF_DOCUMENTO}</Text>
                  </HStack>
                </FormLabel>
                <Input 
                  type="file" 
                  size={'sm'} 
                  { ...register('adju_path_2', { onChange: handleChangeFile } ) } 
                />
                <FormErrorMessage fontSize="10">
                  {errors.adju_path_2 && errors.adju_path_2.message}
                </FormErrorMessage>
              </FormControl>

              </HStack>






              <HStack>
              { /* adjunto_tipo ----------------------------------------------------------- */ }
              <FormControl isInvalid={errors.tadj_id_3?true:false}>
              <FormLabel fontSize="xs" color={colorLabel}>{KContratos._AF_TIPO}</FormLabel>
              <Select 
                size={'sm'} 
                defaultValue={''} 
                bg={bgInput}
                disabled={readOnlyInput}
                {...register('tadj_id_3', {})} 
              >
                <option key='tadj_id_3_s' value={K._SELE_ID}>{`${K._SELE_TEXTO}`}</option>
                {
                  tTipoAdjunto.map( adjunto => (
                    <option key={adjunto.tabl_tabl_elemento} value={adjunto.tabl_tabl_elemento} >{adjunto.tabl_tabl_nombre}</option>
                  ))
                }  
              </Select>
              <FormErrorMessage fontSize="10">
                {errors.tadj_id_3 && errors.tadj_id_3.message}
              </FormErrorMessage>
            </FormControl>


              { /* adjunto_nombre ----------------------------------------------------------- */ }
              <FormControl isInvalid={errors.adju_descripcion_3?true:false}>
                <FormLabel fontSize="xs" color={colorLabel}>
                  <HStack>
                    <Text>{KContratos._AF_DESCRIPCION}</Text>
                  </HStack>
                </FormLabel>
                <Input 
                  bg={bgInput}
                  defaultValue={''} 
                  readOnly={readOnlyInput}
                  size={'sm'} 
                  { ...register('adju_descripcion_3', 
                    { 
                      minLength: { value:   5, message: `${KV._LARGO_MIN} 5`   },
                      maxLength: { value: 100, message: `${KV._LARGO_MAX} 100` },
                    })
                  } 
                />
                <FormErrorMessage fontSize="10">
                  {errors.adju_descripcion_3 && errors.adju_descripcion_3.message}
                </FormErrorMessage>
              </FormControl>

              { /* file ----------------------------------------------------------- */ }
              <FormControl isInvalid={errors.adju_path_3?true:false}>
                <FormLabel fontSize="xs" color={colorLabel}>
                  <HStack>
                    <Text>{KContratos._AF_DOCUMENTO}</Text>
                  </HStack>
                </FormLabel>
                <Input 
                  type="file" 
                  size={'sm'} 
                  { ...register('adju_path_3', { onChange: handleChangeFile } ) } 
                />
                <FormErrorMessage fontSize="10">
                  {errors.adju_path_3 && errors.adju_path_3.message}
                </FormErrorMessage>
              </FormControl>

              </HStack>

              <Divider/>

              <Button 
                    colorScheme='green' 
                    variant='outline' 
                    type="submit"
                    isLoading={isSubmitting} 
                  >
                    {KContratos._AF_BOTON_SUBIR}
                  </Button>

            </VStack>
          </form>
        </VStack>
      </Container>

    )
}

export default ContratoAdjuntosForm;
