import { FC, useContext } from 'react';
import { useState } from 'react';
import { useFetch } from '../../hooks/useFetch';
import MisPendientesRspta from './MisPendientesRspta'
import { servContratosConsultar } from '../../utils/servicios';
import { IUseFetch } from '../../interfaces/iEndpoints';
import { IconRefrescar } from '../../utils/Icons';
import {
    Box,
    Button,
    Container,
    HStack,
    Heading,
    Stack,
    VStack,
    useColorModeValue,
    Text,
  } from '@chakra-ui/react'
import { K, KContratos, KEstado, KPerfiles } from '../../utils/constantes';
import { AuthContext } from '../../context';

interface Props {
  nFirma: number;
}

const MisPendientes: FC<Props> = ({nFirma}) => {

    const { usuario } = useContext( AuthContext );
    const [ consultar, setConsultar ] = useState (1);

    let esta_id_list:string = '';
    
    switch (usuario?.perf_id) {
      case KPerfiles._PERF_GERENTE_FINANZAS:
        esta_id_list = KEstado._ENVIADO_A_FIRMA_1;
        break;
      case KPerfiles._PERF_DIRECCION_EJECUTIVA:
        esta_id_list = KEstado._FIRMA_PROVEEDOR_OK;
        break;
      case KPerfiles._PERF_ADMIN_SUPERUSUARIO:
          if (nFirma === 1)
            esta_id_list = KEstado._ENVIADO_A_FIRMA_1;
          else if (nFirma === 2)
            esta_id_list = KEstado._FIRMA_PROVEEDOR_OK;
          else
            esta_id_list = '?'
        break;
      default:
        esta_id_list = '?'
        break;
    }

    servContratosConsultar.params = {
      ...servContratosConsultar.params,
      esta_id_list: esta_id_list,
      cges_id_list: '',
      ctto_id: '',
    }    

    const colorTitulo = useColorModeValue(K._COLOR_TITULO_L, K._COLOR_TITULO_D);
    const bgColor = useColorModeValue("white", "gray.700");
    const bgColor2 = useColorModeValue("gray.200", "gray.900");

    const onClickConsultar = (e: React.SyntheticEvent) => {
      setConsultar(consultar+1);
      servContratosConsultar.params = {
        ...servContratosConsultar.params,
        ctto_id: '',
        cges_id_list: '',
        random: Math.random(),
      }    
    }

    const contratosServ:IUseFetch = useFetch (servContratosConsultar, JSON.stringify ( servContratosConsultar.params ), 'MisPendientes');
    
    if (contratosServ.cargando)
      return (<Button isLoading={true} loadingText={K._CARGANDO} colorScheme='teal' variant='outline' />)
    else
      return (
          <Container 
            py={{ base: '1', md: '1' }} 
            px={{ base: '1', md: '1' }} 
            maxW='8xl' 
            p="3" 
            bg={bgColor2}
          >
            <Stack direction={'column'} spacing={5}>

                <Box overflowX="auto" bg={bgColor} p={5} >
                  <VStack direction={{ base: 'column', md: 'row' }} spacing={5} align={'left'}>

                    <HStack spacing={3}>
                      <Heading size='md' color={colorTitulo}>{KContratos._MP_TITULO1}</Heading>
                      <Text fontSize='md'>{KContratos._MP_TITULO2}</Text>
                      <Button onClick={onClickConsultar} colorScheme='teal' variant='outline' leftIcon={<IconRefrescar/>} />
                    </HStack>

                      <MisPendientesRspta data={contratosServ.data.data} />

                  </VStack>              
                </Box>
            </Stack>              
          </Container>
    )
  }

  export default MisPendientes;