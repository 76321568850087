import { Route, Routes, Navigate } from 'react-router-dom';
import { Portal } from '../componentes/portal/Portal';
import PanelControl from '../componentes/panelControl/PanelControl';
import MisPendientes from '../componentes/misPendientes/MisPendientes';
import MisPendientesSubir from '../componentes/misPendientes/MisPendientesSubir';
import ContratoCons from '../componentes/contratoCons/ContratoCons';
import ContratoMant from '../componentes/contratoMant/ContratoMant';
import PNaturalCons from '../componentes/pnaturalCons/PNaturalesCons';
import PNaturalMant from '../componentes/pnaturalMant/PNaturalMant';
import Soporte from '../componentes/soporte/Soporte';

const RoutesPortal = () => {
    return(
        <div>
            <Routes>
                <Route exact path="/inicio" element={ <Portal/> } />
                <Route exact path="/resumen" element={ <PanelControl/> } />

                <Route exact path="/mis-pendientes" element={ <MisPendientes nFirma={0}  /> } />
                <Route exact path="/mis-pendientes-f1" element={ <MisPendientes nFirma={1} /> } />
                <Route exact path="/mis-pendientes-f2" element={ <MisPendientes nFirma={2} /> } />

                <Route exact path="/subir-firmados" element={ <MisPendientesSubir nFirma={0}/> } />
                <Route exact path="/subir-firmados-f1" element={ <MisPendientesSubir nFirma={1}/> } />
                <Route exact path="/subir-firmados-f2" element={ <MisPendientesSubir nFirma={2}/> } />

                <Route exact path="/proveedores"  element={ <PNaturalCons/> } />

                <Route exact path="/soporte" element={ <Soporte/> } />

                <Route strict path="/form-contrato/:id" element={ <ContratoMant/> } />
                <Route strict path="/form-proveedor-pn/:rut"  element={ <PNaturalMant/> } />

                <Route path="/contratos/:p" element={ <ContratoCons/> } />

                <Route path="*" element={<Navigate to="/resumen" replace />} />
            </Routes>
        </div>
    )
}

export default RoutesPortal;