import { FC, useState, useEffect } from 'react';
import { IContratoConsultar, IContratoBitacora } from '../../interfaces/iEndpoints';
import { K, KContratos, KErroresDescargarContrato, KEstado, KV } from '../../utils/constantes';
import { servContratoBitacoraConsultar, servContratoDescargar } from '../../utils/servicios';
import { formatFecha } from '../../utils/utilidades';
import { IconDescargar4 } from '../../utils/Icons';
import { fetchDescargarContrato } from '../../utils/fetchDescargarContrato';
import { fetchQS } from '../../utils/fetchQS';import { 
  Button, 
  useToast,
} from '@chakra-ui/react';


interface Props {
    id: string;
    datos: IContratoConsultar;
    ambiente: string | undefined;
}

const MisPendientesDescargar: FC<Props> = ({ id, datos, ambiente}) => {

  const [ errorDetectado, setErrorDetectado ] = useState('');
  const [ cargando, setCargando ] = useState(true);
  const [ bitacora, setBitacora ] = useState([]);

  const arch_prefijo = `${id}_${datos.ctto_pnat_rut}_${datos.ctto_ctto_nro_interno}`;
  const arch_sufijo = (datos.ctto_esta_id_actual === KEstado._FIRMA_1_OK) ? ('_f1') : ((datos.ctto_esta_id_actual === KEstado._FIRMA_PROVEEDOR_OK) ? ('_f1_fp') : (''));
  const arch_proveedor = `__${datos.pnat_pnat_nombre1.replace(/[^a-zA-Z]/g, "")}_${datos.pnat_pnat_apellido1.replace(/[^a-zA-Z]/g, "")}__${formatFecha(datos.ctto_ctto_fecha_ini, false, false)}_al_${formatFecha(datos.ctto_ctto_fecha_fin, false, false)}`;
  const toast = useToast();

  var bita_id = '';
  var bita_pdf = 'error_pdf';
  var datosR:any = [];

  const leerBitacora = (id:any, ambiente:any) => {
    fetchQS (servContratoBitacoraConsultar, id, ambiente)
      .then ( resp => {
        if (resp.code !== 0) {
          setErrorDetectado(KContratos._SC_MSJE_ERROR_BITACORA);
        }
        else if (resp.data.length > 0) {
          datosR = resp.data;
          setBitacora(datosR)
        } 
        setCargando(false);
      })
      .catch ( (error) => {
          const { message } = JSON.parse(error.message);
          setErrorDetectado(message);
          setCargando(false);
      })    
  }

  // if ((primeraVez) || (datos.bita_bita_id === '') ) {
  //   leerBitacora(id, ambiente);
  //   setPrimeraVez(false)    
  // }

  useEffect(() => {
    setCargando(true);
    leerBitacora(id, ambiente);
  }, [ambiente, id, datos])


  if (cargando) return ( <Button isLoading={cargando} colorScheme='teal' variant='link' /> )
  
  if (errorDetectado !== '' ) {
    toast({
      title: KContratos._SC_MSJE_TITULO_FRACASO,
      description: errorDetectado,
      status: 'error',
      duration: K._DURACION_TOAST_DEFAULT,
      isClosable: true,
    })
  }

  for (let index = 0; index < bitacora.length; index++) {
    const element:IContratoBitacora = bitacora[index];
    switch (datos.ctto_esta_id_actual) {
      case KEstado._ENVIADO_A_FIRMA_1:
        if (element.bita_esta_id === KEstado._ENVIADO_A_FIRMA_1) {
          bita_id = element.bita_bita_id;
          bita_pdf = `${arch_prefijo}${arch_sufijo}${arch_proveedor}.${KV._CTTO_ADJUNTO_EXTE_CTTO}`; 
        }
        break;
      case KEstado._FIRMA_1_OK:
        if (element.bita_esta_id === KEstado._FIRMA_1_OK) {
          bita_id = element.bita_bita_id;
          bita_pdf = (element.bita_bita_pdf) ? element.bita_bita_pdf : `${arch_prefijo}${arch_sufijo}${arch_proveedor}.${KV._CTTO_ADJUNTO_EXTE_CTTO}`; 
        }
        break;
      case KEstado._FIRMA_PROVEEDOR_OK:
          if (element.bita_esta_id === KEstado._FIRMA_PROVEEDOR_OK) {
          bita_id = element.bita_bita_id;
          bita_pdf = `${arch_prefijo}${arch_sufijo}${arch_proveedor}.${KV._CTTO_ADJUNTO_EXTE_CTTO}`; 
        }
        break;
    }

    datos.bita_id = bita_id;
    datos.bita_pdf = bita_pdf;
  }

  const onClickDescargar = (e: React.SyntheticEvent):void => {

    const [ctto_id_b, bita_id_b, bita_pdf_b] = e.currentTarget.id.split('|');

    if (bita_id_b !== '') {
      fetchDescargarContrato (servContratoDescargar, ctto_id_b, bita_id_b, bita_pdf_b, ambiente)
      .then ( resp => { 
        if (resp.code !== 0) { 
          const errorDescargar = KErroresDescargarContrato.filter (elemento => (elemento.codigo ===  resp.code) );
          const errorMensaje = (errorDescargar.length > 0) ? errorDescargar[0].mensaje : `[${resp.code} // ${resp.data}]`;

          toast({
            title: KContratos._SC_MSJE_TITULO_FRACASO,
            description: errorMensaje,
            status: 'error',
            duration: K._DURACION_TOAST_DEFAULT,
            isClosable: true,
          })
        }
        else {
          toast({
            title: KContratos._SC_MSJE_TITULO_EXITO,
            description: `${KContratos._SC_MSJE_DESCARGAR_EXITO} ${bita_pdf_b}`,
            status: 'success',
            duration: K._DURACION_TOAST_DEFAULT,
            isClosable: true,
          })
        }
      })
      .catch ( (error) => {
        toast({
          title: KContratos._SC_MSJE_TITULO_FRACASO,
          description: `${KContratos._SC_MSJE_DESCARGAR_FRACASO} [error]`,
          status: 'error',
          duration: K._DURACION_TOAST_DEFAULT,
          isClosable: true,
        })      })    
    }
    else {
      toast({
        title: KContratos._SC_MSJE_TITULO_FRACASO,
        description: `${KContratos._SC_MSJE_DESCARGAR_FRACASO} [sin bitácora]`,
        status: 'error',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })
    }
  }

  return(
      <Button 
        colorScheme='blue' 
        variant='link' 
        type="button"
        leftIcon={cargando ? <IconDescargar4 /> : <IconDescargar4 />}
        onClick={onClickDescargar}
        id={`${datos.ctto_ctto_id}|${bita_id}|${bita_pdf}`} 
        title={`${datos.ctto_ctto_id}|${bita_id}|${bita_pdf}`}
      />
  )
}

export default MisPendientesDescargar;
