import * as React from 'react'
import { FC } from 'react';
import { IUseFetch } from '../../interfaces/iEndpoints'
import {
    Button,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Tfoot,
    Th,
    Thead,
    Tooltip,
    Tr,
    useColorModeValue,
} from '@chakra-ui/react'
import { armarCross } from '../../utils/utilidades';
import { Link as LinkRouter } from 'react-router-dom';
import { K, KContratos, KEstado } from '../../utils/constantes';

interface Props {
  respuesta: IUseFetch,
}

const CuadroResumenRspta: FC<Props> = ( {respuesta} ) => {

  const { cargando, data } = respuesta;
  const datos = data.data;
  let matriz:number[][] = [];

  const bgColor = useColorModeValue("gray.50", "gray.600");
  const colorLink = useColorModeValue(K._COLOR_LINK_L, K._COLOR_LINK_D);
  const colorTH = useColorModeValue(K._COLOR_TH_L, K._COLOR_TH_D);

  if (!cargando) {
    matriz = armarCross (datos);
  }

  return(
    <TableContainer  >
      <Table colorScheme='teal' size="sm">
          <Thead>
            <Tr bg={colorTH}>
              <Th></Th>
              <Th>{KContratos._CR_CGESTION}</Th>
              <Th>{KContratos._CR_BORRADOR_P}<br/>{KContratos._CR_BORRADOR_S}</Th>
              <Th>{KContratos._CR_RECHAZADO_P}<br/>{KContratos._CR_RECHAZADO_S}</Th>
              <Th><Tooltip label={KContratos._CR_TT_FIRMA_1} placement='top'><Text>{KContratos._CR_EN_FIRMA1_P}<br/>{KContratos._CR_EN_FIRMA1_S}<br/>{KContratos._CR_EN_FIRMA1_Z}</Text></Tooltip></Th>
              <Th><Tooltip label={KContratos._CR_TT_FIRMA_P} placement='top'><Text>{KContratos._CR_EN_FIRMA_PROV_P}<br/>{KContratos._CR_EN_FIRMA_PROV_S}<br/>{KContratos._CR_EN_FIRMA_PROV_Z}</Text></Tooltip></Th>
              <Th><Tooltip label={KContratos._CR_TT_FIRMA_2} placement='top'><Text>{KContratos._CR_EN_FIRMA2_P}<br/>{KContratos._CR_EN_FIRMA2_S}<br/>{KContratos._CR_EN_FIRMA2_Z}</Text></Tooltip></Th>
              <Th><Tooltip label={KContratos._CR_TT_FIRMA_TOTAL} placement='top'><Text>{KContratos._CR_EN_FIRMAS_P}<br/>{KContratos._CR_EN_FIRMAS_S}<br/>{KContratos._CR_EN_FIRMAS_Z}</Text></Tooltip></Th>
              <Th><Tooltip label={KContratos._CR_TT_TOTAL_FINALIZ} placement='top'><Text>{KContratos._CR_FINALIZADOS_P}<br/>{KContratos._CR_FINALIZADOS_S}<br/>{KContratos._CR_FINALIZADOS_Z}</Text></Tooltip></Th>
              <Th><Tooltip label={KContratos._CR_TT_TOTAL_GRAL} placement='top'><Text>{KContratos._CR_TOTAL_P}<br/>{KContratos._CR_TOTAL_S}<br/>{KContratos._CR_TOTAL_Z}</Text></Tooltip></Th>
            </Tr>
          </Thead>
          <Tbody>
          {
            cargando
            ?
            (<Tr key={1}>
              <Td colSpan={5}>
                <Button isLoading={cargando} loadingText={K._CARGANDO} colorScheme='teal' variant='outline' />
              </Td>
            </Tr>)
            :
            (
              ( (!data) || (data.length <= 0) )
              ?
              (<Tr key={1}>
                <Td colSpan={5}>
                  <h1>{K._NOHAYDATOS}</h1>
                </Td>
              </Tr>)
              :
              (
                matriz.map((fila, index) => (
                  (index < (matriz.length-1))
                  &&
                  (
                    <Tr key={index}>
                        <Td >{index+1}</Td>
                        <Td >{fila[1]}</Td>
                        <Td isNumeric={true} color={colorLink} ><LinkRouter to={`/contratos/${fila[0]}|${KEstado._BORRADOR_P}`}>{fila[2]}</LinkRouter></Td>
                        <Td isNumeric={true} color={colorLink} ><LinkRouter to={`/contratos/${fila[0]}|${KEstado._RECHAZADO_P}`}>{fila[3]}</LinkRouter></Td>
                        <Td isNumeric={true} color={colorLink} ><LinkRouter to={`/contratos/${fila[0]}|${KEstado._ENVIADO_A_FIRMA_1_P}`}>{fila[4]}</LinkRouter></Td>
                        <Td isNumeric={true} color={colorLink} ><LinkRouter to={`/contratos/${fila[0]}|${KEstado._FIRMA_1_OK_P}`}>{fila[5]}</LinkRouter></Td>
                        <Td isNumeric={true} color={colorLink} ><LinkRouter to={`/contratos/${fila[0]}|${KEstado._FIRMA_PROVEEDOR_OK_P}`}>{fila[6]}</LinkRouter></Td>
                        <Td isNumeric={true} color={colorLink} fontWeight="bold"><LinkRouter to={`/contratos/${fila[0]}|${KEstado._PENDIENTES_P}`}>{fila[7]}</LinkRouter></Td>
                        <Td isNumeric={true} color={colorLink} fontWeight="bold"><LinkRouter to={`/contratos/${fila[0]}|${KEstado._FIRMA_2_OK_P}`}>{fila[8]}</LinkRouter></Td>
                        <Td isNumeric={true} color={colorLink} fontWeight="bold"><LinkRouter to={`/contratos/${fila[0]}|${KEstado._TODOS_P}`}>{fila[9]}</LinkRouter></Td>

                        </Tr>
                  )
                )
              )
              )
            )}
          </Tbody>
          <Tfoot>
          {
            (matriz && matriz.length>1)
            &&
            <Tr key={matriz.length+1}  bg={bgColor}>
              <Td ></Td>
              <Td >TOTALES</Td>
              <Td isNumeric={true} fontWeight="bold" color={colorLink} ><LinkRouter to={`/contratos/${K._TODO_ID}|${KEstado._BORRADOR_P}`}>{matriz[matriz.length-1][2]}</LinkRouter></Td>
              <Td isNumeric={true} fontWeight="bold" color={colorLink} ><LinkRouter to={`/contratos/${K._TODO_ID}|${KEstado._RECHAZADO_P}`}>{matriz[matriz.length-1][3]}</LinkRouter></Td>
              <Td isNumeric={true} fontWeight="bold" color={colorLink} ><LinkRouter to={`/contratos/${K._TODO_ID}|${KEstado._ENVIADO_A_FIRMA_1_P}`}>{matriz[matriz.length-1][4]}</LinkRouter></Td>
              <Td isNumeric={true} fontWeight="bold" color={colorLink} ><LinkRouter to={`/contratos/${K._TODO_ID}|${KEstado._FIRMA_1_OK_P}`}>{matriz[matriz.length-1][5]}</LinkRouter></Td>
              <Td isNumeric={true} fontWeight="bold" color={colorLink} ><LinkRouter to={`/contratos/${K._TODO_ID}|${KEstado._FIRMA_PROVEEDOR_OK_P}`}>{matriz[matriz.length-1][6]}</LinkRouter></Td>
              <Td isNumeric={true} fontWeight="bold" color={colorLink} ><LinkRouter to={`/contratos/${K._TODO_ID}|${KEstado._PENDIENTES_P}`}>{matriz[matriz.length-1][7]}</LinkRouter></Td>
              <Td isNumeric={true} fontWeight="bold" color={colorLink} ><LinkRouter to={`/contratos/${K._TODO_ID}|${KEstado._FIRMA_2_OK_P}`}>{matriz[matriz.length-1][8]}</LinkRouter></Td>
              <Td isNumeric={true} fontWeight="bold" color={colorLink} ><LinkRouter to={`/contratos/${K._TODO_ID}|${KEstado._TODOS_P}`}>{matriz[matriz.length-1][9]}</LinkRouter></Td>
            </Tr>
          }

          </Tfoot>
        </Table>
      </TableContainer>
    )
}

export default CuadroResumenRspta;