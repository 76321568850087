import { FC } from 'react';
import { IPNaturalesConsultar, IUseFetch } from '../../interfaces/iEndpoints'
import {
    Button,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tooltip,
    Tr,
    useColorModeValue,
} from '@chakra-ui/react'
import { formatRUT } from '../../utils/utilidades';
import { FiEdit, FiCheck, FiXCircle } from 'react-icons/fi';
import { Link as LinkRouter } from 'react-router-dom';
import { K, KPNatural } from '../../utils/constantes';

interface Props {
  respuesta: IUseFetch,
  primeraVez:boolean
}
//(!fila.pnat_pnat_profesion) ||

const iconoCampoGlobal = (fila:IPNaturalesConsultar, pColorEstadoOK: string, pColorEstadoNOK: string ):any => {

  if ( (!fila.pnat_pnat_correo) ||
      (!fila.pnat_pnat_telefono) ||
      (!fila.pnat_perf_id) ||
      (!fila.pnat_pnat_tx_cuenta_banco) ||
      (!fila.pnat_pnat_tx_cuenta_nro) ||
      (!fila.pnat_pnat_tx_cuenta_tipo) ||
      (!fila.pnat_pnat_genero) ||
      (!fila.pnat_pnat_fecha_nac) ||
      (!fila.pnat_naci_id) ||
      (!fila.pnat_eciv_id) ||
      (!fila.pnat_pnat_profesion) ||
      (!fila.pnat_pnat_direccion) ||
      (!fila.pnat_comu_id) ||
      (!fila.pnat_regi_id)
      // || ( (!fila.pnat_pnat_decljurada_fecha) || (!validaFechaDeclJurada(fila.pnat_pnat_decljurada_fecha) ) )
      )
    return(<FiEdit fontSize="1.0rem" color={pColorEstadoNOK} />); 
  else return (<FiEdit fontSize="1.0rem" color={pColorEstadoOK} />)
}

const iconoCampo = (campo: string | null, pColorEstadoOK: string, pColorEstadoNOK: string):any => {
    if (campo)
      return (<FiCheck fontSize="1.0rem" color={pColorEstadoOK} />) 
    else
      return (<FiXCircle fontSize="1.0rem" color={pColorEstadoNOK}/>); 
}

const PNaturalesRspta: FC<Props> = ( {respuesta, primeraVez} ) => {

  const { cargando, data } = respuesta;
  const datos = data.data;
  const colorEstadoOK = useColorModeValue("green", "cyan");
  const colorEstadoNOK = useColorModeValue("red", "pink");

  return(
    <TableContainer  >
      <Table colorScheme='teal' size="sm">
          <Thead>
            <Tr bg={useColorModeValue("gray.100", "gray.600")} >
              {/* <Th></Th> */}
              <Th fontSize="10"></Th>
              <Th></Th>
              <Th>{KPNatural._PC_TH_APELLIDO}</Th>
              <Th>{KPNatural._PC_TH_NOMBRE}</Th>
              <Th>{KPNatural._PC_TH_RUT}</Th>
              {/* <Th><Tooltip label={KPNatural._PC_TT_DECL_JUR} placement='top'><Text>{KPNatural._PC_TH_DECL_JUR_1}{KPNatural._PC_TH_DECL_JUR_2}</Text></Tooltip></Th> */}
              <Th><Tooltip label={KPNatural._PC_TT_CORREO} placement='top'><Text>{KPNatural._PC_TH_CORREO}</Text></Tooltip></Th>
              <Th><Tooltip label={KPNatural._PC_TT_TELEFONO} placement='top'><Text>{KPNatural._PC_TH_TELEFONO}</Text></Tooltip></Th>
              <Th><Tooltip label={KPNatural._PC_TT_CONEXION} placement='top'><Text>{KPNatural._PC_TH_CONEXION}</Text></Tooltip></Th>
              <Th><Tooltip label={KPNatural._PC_TT_BANCO} placement='top'><Text>{KPNatural._PC_TH_BANCO}</Text></Tooltip></Th>
              <Th><Tooltip label={KPNatural._PC_TT_NRO_CUENTA} placement='top'><Text>{KPNatural._PC_TH_NRO_CUENTA_1}<br/>{KPNatural._PC_TH_NRO_CUENTA_2}</Text></Tooltip></Th>
              <Th><Tooltip label={KPNatural._PC_TT_TIPO_CUENTA} placement='top'><Text>{KPNatural._PC_TH_TIPO_CTA_1}<br/>{KPNatural._PC_TH_TIPO_CTA_2}</Text></Tooltip></Th>
              <Th><Tooltip label={KPNatural._PC_TT_PROFESION} placement='top'><Text>{KPNatural._PC_TH_PROFESION}</Text></Tooltip></Th>
              <Th><Tooltip label={KPNatural._PC_TT_GENERO} placement='top'><Text>{KPNatural._PC_TH_GENERO}</Text></Tooltip></Th>
              <Th><Tooltip label={KPNatural._PC_TT_FECHA_NAC} placement='top'><Text>{KPNatural._PC_TH_FECHA_NAC_1}<br/>{KPNatural._PC_TH_FECHA_NAC_2}</Text></Tooltip></Th>
              <Th><Tooltip label={KPNatural._PC_TT_NACIONALIDAD} placement='top'><Text>{KPNatural._PC_TH_NACIONALIDAD}</Text></Tooltip></Th>
              <Th><Tooltip label={KPNatural._PC_TT_ECIVIL} placement='top'><Text>{KPNatural._PC_TH_ECIVIL_1}<br/>{KPNatural._PC_TH_ECIVIL_2}</Text></Tooltip></Th>
              <Th><Tooltip label={KPNatural._PC_TT_DIRECCION} placement='top'><Text>{KPNatural._PC_TH_DIRECCION}</Text></Tooltip></Th>
              <Th><Tooltip label={KPNatural._PC_TT_COMUNA} placement='top'><Text>{KPNatural._PC_TH_COMUNA}</Text></Tooltip></Th>
              <Th><Tooltip label={KPNatural._PC_TT_DIRECCION} placement='top'><Text>{KPNatural._PC_TH_REGION}</Text></Tooltip></Th>

            </Tr>
          </Thead>
          <Tbody>
          {
            cargando
            ?
            (<Tr key={1}>
              <Td colSpan={5}>
                <Button isLoading={cargando} loadingText={K._CARGANDO} colorScheme='teal' variant='outline' />
              </Td>
            </Tr>)
            :
            (primeraVez ? (null) :  
            (
              ( (!datos) || (datos.length <= 0) )
              ?
              (<Tr key={1}>
                <Td colSpan={5}>
                  {K._NOHAYDATOS_F}
                </Td>
              </Tr>)
              :
              (
                datos.map((fila:IPNaturalesConsultar, index:number) => (
                  (
                    <Tr key={index}>
                        <Td>{index+1}</Td>
                        <Td><LinkRouter to={`/form-proveedor-pn/${fila.pnat_pnat_rut}`}>{iconoCampoGlobal(fila, colorEstadoOK, colorEstadoNOK)}</LinkRouter></Td>
                        <Td>{fila.pnat_pnat_apellido1}</Td>
                        <Td>{fila.pnat_pnat_nombre1}</Td>
                        <Td isNumeric={true}>{formatRUT( fila.pnat_pnat_rut)}</Td>
                        {/* <Td>{iconoCampo(fila.pnat_pnat_decljurada_fecha, colorEstadoOK, colorEstadoNOK)}</Td> */}
                        <Td>{iconoCampo(fila.pnat_pnat_correo, colorEstadoOK, colorEstadoNOK)}</Td>
                        <Td>{iconoCampo(fila.pnat_pnat_telefono, colorEstadoOK, colorEstadoNOK)}</Td>
                        <Td>{iconoCampo(fila.pnat_perf_id, colorEstadoOK, colorEstadoNOK)}</Td>
                        <Td>{iconoCampo(fila.pnat_pnat_tx_cuenta_banco, colorEstadoOK, colorEstadoNOK)}</Td>
                        <Td>{iconoCampo(fila.pnat_pnat_tx_cuenta_nro, colorEstadoOK, colorEstadoNOK)}</Td>
                        <Td>{iconoCampo(fila.pnat_pnat_tx_cuenta_tipo, colorEstadoOK, colorEstadoNOK)}</Td>
                        <Td>{iconoCampo(fila.pnat_pnat_profesion, colorEstadoOK, colorEstadoNOK)}</Td>
                        <Td>{iconoCampo(fila.pnat_pnat_genero, colorEstadoOK, colorEstadoNOK)}</Td>
                        <Td>{iconoCampo(fila.pnat_pnat_fecha_nac, colorEstadoOK, colorEstadoNOK)}</Td>
                        <Td>{iconoCampo(fila.pnat_naci_id, colorEstadoOK, colorEstadoNOK)}</Td>
                        <Td>{iconoCampo(fila.pnat_eciv_id, colorEstadoOK, colorEstadoNOK)}</Td>
                        <Td>{iconoCampo(fila.pnat_pnat_direccion, colorEstadoOK, colorEstadoNOK)}</Td>
                        <Td>{iconoCampo(fila.pnat_comu_id, colorEstadoOK, colorEstadoNOK)}</Td>
                        <Td>{iconoCampo(fila.pnat_regi_id, colorEstadoOK, colorEstadoNOK)}</Td>
                    </Tr>
                  )
                ))
              )
            )
          )}
        </Tbody>

        </Table>
      </TableContainer>
    )
}

export default PNaturalesRspta;