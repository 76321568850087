import { FC } from 'react';
import { useState } from 'react';
import { useFetch } from '../../hooks/useFetch';
import CuadroResumenRspta from './CuadroResumenRspta'
import { servResumenEjecutivo } from '../../utils/servicios';
import { IAplicacionesPermisos, IUseFetch } from '../../interfaces/iEndpoints';
import {
    Box,
    Button,
    Container,
    Heading,
    Input,
    InputGroup,
    InputLeftAddon,
    VStack,
    useColorModeValue,
    Flex,
    Spacer,
  } from '@chakra-ui/react'
import { IconRefrescar } from '../../utils/Icons';
import { K, KApps, KContratos } from '../../utils/constantes';
import { listaCGestionAut } from '../../utils/utilidades';

interface Props {
  tAplicacionesPermisos: IAplicacionesPermisos[],
}

const CuadroResumen: FC<Props> = ({ tAplicacionesPermisos }) => {
    
    let fecha = new Date()
    fecha.setDate(fecha.getDate() - 365);

    const [valueD, setValueD] = useState(fecha.toISOString().slice(0,10));
    const [ consultar, setConsultar ] = useState (1);

    servResumenEjecutivo.params = {
      ...servResumenEjecutivo.params,
      cges_id_list: listaCGestionAut(tAplicacionesPermisos, KApps._PANEL_CONTROL),
      ctto_fecha_ini: valueD,
    }

    const resumenEjecutivoServ:IUseFetch = useFetch (servResumenEjecutivo, JSON.stringify ( servResumenEjecutivo.params ), 'CuadroResumen');

    const colorTitulo = useColorModeValue(K._COLOR_TITULO_L, K._COLOR_TITULO_D);
    const bgColor = useColorModeValue("white", "gray.700");
    const bgColorInterno = useColorModeValue("gray.50", "gray.700");
    
    const handleChangeD = (e:React.FormEvent<HTMLInputElement>) => setValueD(e.currentTarget.value)

    const onClickConsultar = (e: React.SyntheticEvent) => {
      setConsultar(consultar+1);
      servResumenEjecutivo.params = {
        ...servResumenEjecutivo.params,
        ctto_fecha_ini: valueD,
        random: Math.random(),
      }
    }
    
    return (
      <Container 
        py={{ base: '1', md: '2' }} 
        px={{ base: '1', md: '1' }} 
        bg={bgColor}
        maxW='7xl' 
      >
        <VStack direction={'column'} spacing={1}>

            <Box overflowX="auto" bg={bgColor} p={3} >
              
              <VStack direction={{ base: 'column', md: 'row' }} spacing={4}>

                <Heading size='md' color={colorTitulo}>{KContratos._CR_TITULO1}</Heading>
                
                <CuadroResumenRspta respuesta={resumenEjecutivoServ} />

                <Box bg={bgColorInterno} p="5" w="100%" >
                  <Container maxW={'md'}>
                  <Flex >
                      <InputGroup>
                        <InputLeftAddon children='Fecha desde' />
                        <Input name="ctto_fecha_desde" type="date" onChange={handleChangeD} value={valueD} maxW={40} />
                        {/* <Input name="ctto_fecha_hasta" type="date" onChange={handleChangeH} value={valueH} /> */}
                      </InputGroup>
                    <Spacer />
                      <Button onClick={onClickConsultar} colorScheme='teal' variant='outline' leftIcon={<IconRefrescar/>} size="md">Consultar</Button>
                  </Flex>
                  </Container>
                </Box>

              </VStack>              
            </Box>

        </VStack>              
      </Container>
    )
  }

  export default CuadroResumen;