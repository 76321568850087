import { IContratoAdjuntos, IContratoConsultar, IContratoModificar, IEndpoint, ITipoContratos } from '../../interfaces/iEndpoints';
import { IUsuarioContext } from "../../interfaces/iUsuarioContext";
import { K, KCasosTransicion, KContratos, KEstado } from "../../utils/constantes";
import { fetchTx } from "../../utils/fetchTx";
import { servContratoAdjuntoConsultar, servContratoAutorizar, servContratoEliminar, servContratoGenerarPDF, servContratoModificar, servProveedorAdjuntoConsultar } from "../../utils/servicios";
import { consistenciaFechas, enviarCorreo, leerUsuario, limpiarRut, preparaNotificacion, validaAdjuntoDeclJuradaProv, validarDatosPersonas } from "../../utils/utilidades";
import { IParametros } from "../../interfaces/iParametros";
import { fetchQSAdjuntosProv } from "../../utils/fetchQSAdjuntosProv";
import { fetchQS } from '../../utils/fetchQS';

export const fGrabar = async ( datos:IContratoConsultar, usuario: IUsuarioContext | undefined, data: IContratoModificar, id:number, setMensajeActualizar: React.Dispatch<React.SetStateAction<string>>, setMostrarMensajeOK: React.Dispatch<React.SetStateAction<boolean>>, setMostrarMensajeError: React.Dispatch<React.SetStateAction<boolean>>, servContratosConsultar:any, toast: any, ambiente: string|undefined, setPrimeraVez: React.Dispatch<React.SetStateAction<boolean>>, tTContrato:ITipoContratos[] ) => {

  const vTContrato = tTContrato.filter (tipocontrato => ( tipocontrato.tcon_tcon_id ===  data.tcon_id ) );
  const reglasJson = vTContrato[0].tcon_tcon_reglas;
  const reglas = JSON.parse(reglasJson);

  const msjeErrorPersonas = validarDatosPersonas (datos);
  const msjeErrorFechas = consistenciaFechas (data.ctto_fecha_ppal, data.ctto_fecha_ini, data.ctto_fecha_fin);
  const msjeErrores = msjeErrorPersonas + ((msjeErrorPersonas.length > 0)?'\n':'') + msjeErrorFechas;

  const respAdjProv:IEndpoint = await fetchQSAdjuntosProv ( servProveedorAdjuntoConsultar, ambiente, K._PNPJ_ID_DEFAULT, limpiarRut( data.pnat_rut ), null);
  
  var bDeclJurada = false;

  if (respAdjProv.code === 0) {
    const datosAdjProv:any = respAdjProv.data;
    bDeclJurada = validaAdjuntoDeclJuradaProv (limpiarRut(data.pnat_rut), reglas, datosAdjProv, data.ctto_fecha_ppal);
  }

  if (!bDeclJurada) {
    toast({
      title: KContratos._F_MSJE_TITULO_AVISO,
      description: KContratos._F_MSJE_PNAT_SIN_DECL_JURADA,
      status: 'warning',
      duration: K._DURACION_TOAST_DEFAULT_LARGA,
      isClosable: true,
    })
  }

  if (msjeErrores.length > 0 ) {
    setMensajeActualizar(`${K._MSJE_ERRORES}\n${msjeErrores}`);
    setMostrarMensajeOK(false);
    setMostrarMensajeError(true);

    toast({
      title: KContratos._F_MSJE_TITULO_FRACASO,
      description: msjeErrores,
      status: 'error',
      duration: K._DURACION_TOAST_DEFAULT,
      isClosable: true,
    })

  }
  else {
    servContratoModificar.params = {
      ctto_fecha_ppal: data.ctto_fecha_ppal,
      ctto_nro_interno: data.ctto_nro_interno,
      ctto_nombre: data.ctto_nombre,
      ctto_activ_requerida: null,
      ctto_activ_fecha: null,
      ctto_descripcion: data.ctto_descripcion.trim(),
      ctto_observaciones: data.ctto_observaciones,
      ctto_objetivo: data.ctto_objetivo,
      ctto_alcance: null,
      ctto_monitoreo: null,
      ctto_texto1: null,
      ctto_texto2: null,
      ctto_texto3: null,
      ctto_texto4: null,
      ctto_texto5: null,
      ctto_monto_total: parseFloat(data.ctto_monto_total),
      mone_id: data.mone_id,
      ctto_fecha_texto_sn: K._NO,
      ctto_fecha_descripcion: null,
      ctto_fecha_ini: data.ctto_fecha_ini,
      ctto_fecha_fin: data.ctto_fecha_fin,
      ctto_pago_texto_sn: data.ctto_pago_texto_sn ? K._SI : K._NO,
      ctto_pago_descripcion: data.ctto_pago_descripcion,
      ctto_cantidad_cuotas: parseInt(data.ctto_cantidad_cuotas),
      ctto_unidad_cuotas: data.ctto_unidad_cuotas,
      ctto_monto_cuotas: parseFloat(data.ctto_monto_cuotas),
      ctto_es_renovable: K._NO,
      ctto_es_indefinido: K._NO,
      ctto_msje_interno: data.ctto_msje_interno,
      ctto_msje_a_proveedor: data.ctto_msje_a_proveedor,
      usua_rut_firma1: null,
      usua_rut_firma2: null,
      ctto_pdf_inicial: null,
      usua_id: usuario ? usuario.usua_id : '?editar',
    }

    const resp:IEndpoint = await fetchTx (servContratoModificar, id, ambiente);
    
    if (resp.code === 0) {

      servContratoGenerarPDF.params = {
        nombre_archivo: `${limpiarRut(data.pnat_rut)}_${id}_${data.ctto_nro_interno}.pdf`,
      }

      const respPDF:IEndpoint = await fetchTx (servContratoGenerarPDF, id, ambiente);

      if (respPDF.code !== 0) {
        setMensajeActualizar(`${KContratos._F_MSJE_GENERAR_PDF}\n\n${respPDF.data}`);
        setMostrarMensajeOK(false);
        setMostrarMensajeError(true);

        toast({
          title: KContratos._F_MSJE_TITULO_FRACASO,
          description: KContratos._F_MSJE_GENERAR_PDF,
          status: 'error',
          duration: K._DURACION_TOAST_DEFAULT,
          isClosable: true,
        })
      }

      const d = new Date()
      setMensajeActualizar(`${KContratos._F_MSJE_MODIFICAR_EXITO}${d.toLocaleString()}`);
      setMostrarMensajeOK(true);
      setMostrarMensajeError(false);
      setPrimeraVez(true);

      toast({
        title: KContratos._F_MSJE_TITULO_EXITO,
        description: KContratos._F_MSJE_MODIFICAR_EXITO,
        status: 'success',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })

      servContratosConsultar.params = {
        ...servContratosConsultar.params,
        pnat_rut: '',
        ctto_nro_interno: '',
        ctto_nombre: '',
        cges_id_list: '',
        esta_id_list: '',
        ctto_fecha_ini: '',
        ctto_fecha_fin: '',
        ctto_id: id.toString(),
        random: Math.random(),
      }
      return(true);
    
    }
    else {
      setMensajeActualizar(`${KContratos._F_MSJE_MODIFICAR_FRACASO}\n\n${resp.data}`);
      setMostrarMensajeOK(false);
      setMostrarMensajeError(true);

      toast({
        title: KContratos._F_MSJE_TITULO_FRACASO,
        description: KContratos._F_MSJE_MODIFICAR_FRACASO,
        status: 'error',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })
      return(false);
    }
  }
}

export const fAutorizar = async ( usuario: IUsuarioContext | undefined, data: IContratoModificar, id:number, setMensajeActualizar: React.Dispatch<React.SetStateAction<string>>, setMostrarMensajeOK: React.Dispatch<React.SetStateAction<boolean>>, setMostrarMensajeError: React.Dispatch<React.SetStateAction<boolean>>, servContratosConsultar:any, toast: any, ambiente: string|undefined, setPrimeraVez: React.Dispatch<React.SetStateAction<boolean>>, datos: IContratoConsultar, vParametros: IParametros, tTContrato:ITipoContratos[] ) => {

  const vTContrato = tTContrato.filter (tipocontrato => ( tipocontrato.tcon_tcon_id === data.tcon_id ) );
  const reglasJson = vTContrato[0].tcon_tcon_reglas;
  const reglas = JSON.parse(reglasJson);

  var qErrores = 0;
  var sErrores = '';
  
  var bDeclJurada = false;
  var bCotizacion = false;
  var bPropuesta = false;
  var bMemoConductor = false;
  var bCumplimiento = false;

  var qCotizacion = 0;
  var qPropuesta = 0;
  var qMemoConductor = 0;
  var qCumplimiento = 0;

  const usuarioF1:any = await leerUsuario (datos.tcon_usua_id_firma1, ambiente);
  const usuarioF2:any = await leerUsuario (datos.tcon_usua_id_firma2, ambiente);
  const usuarioCG:any = await leerUsuario (datos.cges_usua_id_responsable, ambiente);

  const respAdj:IEndpoint = await fetchQSAdjuntosProv ( servProveedorAdjuntoConsultar, ambiente, K._PNPJ_ID_PNATURAL, limpiarRut( data.pnat_rut ), null)
  if (respAdj.code === 0) {
    const datosAdj:any = respAdj.data;
    bDeclJurada = validaAdjuntoDeclJuradaProv (limpiarRut(data.pnat_rut), reglas, datosAdj, data.ctto_fecha_ppal);
  }

  if (!bDeclJurada) {
    qErrores += 1;
    sErrores += ((sErrores.length>0) ? ' // ' : '') + KContratos._F_MSJE_PNAT_SIN_DECL_JURADA;
 
    // setMensajeActualizar(`${KContratos._F_MSJE_PNAT_SIN_DECL_JURADA}`);
    // setMostrarMensajeOK(false);
    // setMostrarMensajeError(true);

    // toast({
    //   title: KContratos._F_MSJE_TITULO_AVISO,
    //   description: KContratos._F_MSJE_PNAT_SIN_DECL_JURADA,
    //   status: 'error',
    //   duration: K._DURACION_TOAST_DEFAULT_LARGA,
    //   isClosable: true,
    // })
  }

  const respAdjCtto:IEndpoint = await fetchQS ( servContratoAdjuntoConsultar, datos.ctto_ctto_id, ambiente );

  if (respAdjCtto.code === 0) {

    const datosAdjCtto:IContratoAdjuntos[] = respAdjCtto.data;

    const aCotizacion:IContratoAdjuntos[] = datosAdjCtto.filter (adjunto => (adjunto.adju_tadj_id === K._TADJ_ID_COTIZACION) );
    const aPropuesta:IContratoAdjuntos[] = datosAdjCtto.filter (adjunto => (adjunto.adju_tadj_id === K._TADJ_ID_PROPUESTA) );
    const aMemoConductor:IContratoAdjuntos[] = datosAdjCtto.filter (adjunto => (adjunto.adju_tadj_id === K._TADJ_ID_MEMO_CONDUCTOR) );
    const aCumplimiento:IContratoAdjuntos[] = datosAdjCtto.filter (adjunto => (adjunto.adju_tadj_id === K._TADJ_ID_CUMPLIMIENTO) );

    qCotizacion = aCotizacion.length;
    qPropuesta = aPropuesta.length;
    qMemoConductor = aMemoConductor.length;
    qCumplimiento = aCumplimiento.length;

    bCotizacion = (parseInt(reglas.cotizacion) <= qCotizacion);
    bPropuesta = (parseInt(reglas.propuesta_tallerista) <= qPropuesta);
    bMemoConductor = (parseInt(reglas.memo_conductor) <= qMemoConductor);
    bCumplimiento = (parseInt(reglas.cumplimiento) <= qCumplimiento);
   
    if (!bCotizacion) {
      qErrores += 1;
      sErrores += ((sErrores.length>0) ? ' // ' : '') + KContratos._F_MSJE_CTTO_SIN_COTIZACION;
    }

    if (!bPropuesta) {
      qErrores += 1;
      sErrores += ((sErrores.length>0) ? ' // ' : '') + KContratos._F_MSJE_CTTO_SIN_PROPUESTA;
    }

    if (!bMemoConductor) {
      qErrores += 1;
      sErrores += ((sErrores.length>0) ? ' // ' : '') + KContratos._F_MSJE_CTTO_SIN_MEMOCONDUCTOR;
    }

    if (!bCumplimiento) {
      qErrores += 1;
      sErrores += ((sErrores.length>0) ? ' // ' : '') + KContratos._F_MSJE_CTTO_SIN_MEMOCONDUCTOR;
  }
}

if (qErrores > 0) {
  setMensajeActualizar(sErrores);
  setMostrarMensajeOK(false);
  setMostrarMensajeError(true);

  toast({
    title: KContratos._F_MSJE_TITULO_AVISO,
    description: sErrores,
    status: 'error',
    duration: K._DURACION_TOAST_DEFAULT_LARGA,
    isClosable: true,
  })

}

else {

// console.log('___usuarios___', usuarioF1, usuarioF2, usuarioCG)  
    servContratoAutorizar.params = {
      ...servContratoAutorizar.params,
      esta_id_actual: KEstado._BORRADOR,
      esta_id_nuevo: KEstado._ENVIADO_A_FIRMA_1,
			usua_id: usuario ? usuario.usua_id : '?_fAutorizar',
      caso: KCasosTransicion._CASO_TRANS_ENVIAR_A_FIRMA,
      mensaje: data.ctto_msje_interno,
    }

    const resp:IEndpoint = await fetchTx (servContratoAutorizar, id, ambiente);

    if (resp.code === 0) {
      const d = new Date()
      setMensajeActualizar(`${KContratos._F_MSJE_AUTORIZAR_EXITO}${d.toLocaleString()}`);
      setMostrarMensajeOK(true);
      setMostrarMensajeError(false);
      setPrimeraVez(true);

      const correo = preparaNotificacion (datos, vParametros, usuarioF1, usuarioF2, usuarioCG);
      await enviarCorreo ( correo, ambiente );

      toast({
        title: KContratos._F_MSJE_TITULO_EXITO,
        description: KContratos._F_MSJE_AUTORIZAR_EXITO,
        status: 'success',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })

      servContratosConsultar.params = {
        ...servContratosConsultar.params,
        pnat_rut: '',
        ctto_nro_interno: '',
        ctto_nombre: '',
        cges_id_list: '',
        esta_id_list: '',
        ctto_fecha_ini: '',
        ctto_fecha_fin: '',
        ctto_id: id.toString(),
        random: Math.random(),
      }
      return(true);
    }
    else {
      setMensajeActualizar(`${KContratos._F_MSJE_AUTORIZAR_FRACASO}\n\n${resp.data}`);
      setMostrarMensajeOK(false);
      setMostrarMensajeError(true);

      toast({
        title: KContratos._F_MSJE_TITULO_FRACASO,
        description: KContratos._F_MSJE_AUTORIZAR_FRACASO,
        status: 'error',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })
      return(false);
    }
  }
}

  export const fEliminar = async ( usuario: IUsuarioContext | undefined, data: IContratoModificar, id:number, setMensajeActualizar: React.Dispatch<React.SetStateAction<string>>, setMostrarMensajeOK: React.Dispatch<React.SetStateAction<boolean>>, setMostrarMensajeError: React.Dispatch<React.SetStateAction<boolean>>, servContratosConsultar:any, toast: any, ambiente: string|undefined, setPrimeraVez: React.Dispatch<React.SetStateAction<boolean>> ) => {
    
    servContratoEliminar.params = {
      ...servContratoEliminar.params,
      esta_id_actual: data.esta_id_actual,
      ctto_observaciones: data.ctto_observaciones,
			usua_id: usuario ? usuario.usua_id : '?_fEliminar',
    }

    const resp:IEndpoint = await fetchTx (servContratoEliminar, id, ambiente);

    if (resp.code === 0) {
      const d = new Date()
      setMensajeActualizar(`${KContratos._F_MSJE_ELIMINAR_EXITO}${d.toLocaleString()}`);
      setMostrarMensajeOK(true);
      setMostrarMensajeError(false);
      setPrimeraVez(true);

      toast({
        title: KContratos._F_MSJE_TITULO_EXITO,
        description: KContratos._F_MSJE_ELIMINAR_EXITO,
        status: 'success',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })

      servContratosConsultar.params = {
        ...servContratosConsultar.params,
        pnat_rut: '',
        ctto_nro_interno: '',
        ctto_nombre: '',
        cges_id_list: '',
        esta_id_list: '',
        ctto_fecha_ini: '',
        ctto_fecha_fin: '',
        ctto_id: id.toString(),
        random: Math.random(),
      }
      return(true);
    }
    else {
      setMensajeActualizar(`${KContratos._F_MSJE_ELIMINAR_FRACASO}\n\n${resp.data}`);
      setMostrarMensajeOK(false);
      setMostrarMensajeError(true);

      toast({
        title: KContratos._F_MSJE_TITULO_FRACASO,
        description: KContratos._F_MSJE_ELIMINAR_FRACASO,
        status: 'error',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })
      return(false);
    }
  }

  export const fRechazar = async ( usuario: IUsuarioContext | undefined, data: IContratoModificar, id:number, setMensajeActualizar: React.Dispatch<React.SetStateAction<string>>, setMostrarMensajeOK: React.Dispatch<React.SetStateAction<boolean>>, setMostrarMensajeError: React.Dispatch<React.SetStateAction<boolean>>, servContratosConsultar:any, toast: any, ambiente: string|undefined ) => {

		const caso = data.esta_id_actual;

    servContratoAutorizar.params = {
      ...servContratoAutorizar.params,
      esta_id_actual: caso,
      esta_id_nuevo: KEstado._RECHAZADO,
			usua_id: usuario ? usuario.usua_id : '?_fRechazar',
      caso: 
      (
				(caso === KEstado._ENVIADO_A_FIRMA_1) 
				? 
          KCasosTransicion._CASO_TRANS_FIRMA1_RECHAZO
				: 
				(caso === KEstado._FIRMA_1_OK) 
				? 
          KCasosTransicion._CASO_TRANS_FIRMAP_RECHAZO
				: 
				(caso === KEstado._FIRMA_PROVEEDOR_OK) 
				? 
          KCasosTransicion._CASO_TRANS_FIRMA2_RECHAZO
				:
					''
      ),
    mensaje: 
      (
				(caso === KEstado._ENVIADO_A_FIRMA_1) 
				? 
					data.ctto_msje_de_firma1
				: 
				(caso === KEstado._FIRMA_1_OK) 
				? 
          data.ctto_msje_de_proveedor
				: 
				(caso === KEstado._FIRMA_PROVEEDOR_OK) 
				? 
          data.ctto_msje_de_firma2
				:
					''
      )

    }

    if (servContratoAutorizar.params.mensaje.length <= 0) {
      setMensajeActualizar(`${KContratos._F_MSJE_RECHAZAR_SIN_MENSAJE}`);
      setMostrarMensajeOK(false);
      setMostrarMensajeError(true);

      toast({
        title: KContratos._F_MSJE_TITULO_FRACASO,
        description: KContratos._F_MSJE_RECHAZAR_SIN_MENSAJE,
        status: 'error',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })
      return(false);

    }
    else {

    const resp:IEndpoint = await fetchTx (servContratoAutorizar, id, ambiente);

    if (resp.code === 0) {
      const d = new Date()
      setMensajeActualizar(`${KContratos._F_MSJE_RECHAZAR_EXITO}${d.toLocaleString()}`);
      setMostrarMensajeOK(true);
      setMostrarMensajeError(false);

      toast({
        title: KContratos._F_MSJE_TITULO_EXITO,
        description: KContratos._F_MSJE_RECHAZAR_EXITO,
        status: 'success',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })

      servContratosConsultar.params = {
        ...servContratosConsultar.params,
        pnat_rut: '',
        ctto_nro_interno: '',
        ctto_nombre: '',
        cges_id_list: '',
        esta_id_list: '',
        ctto_fecha_ini: '',
        ctto_fecha_fin: '',
        ctto_id: id.toString(),
        random: Math.random(),
      }
      return(true);
    }
    else {
      setMensajeActualizar(`${KContratos._F_MSJE_RECHAZAR_FRACASO}\n\n${resp.data}`);
      setMostrarMensajeOK(false);
      setMostrarMensajeError(true);

      toast({
        title: KContratos._F_MSJE_TITULO_FRACASO,
        description: KContratos._F_MSJE_RECHAZAR_FRACASO,
        status: 'error',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })
      return(false);
    }
  }
}

export const fRechABorrador = async ( usuario: IUsuarioContext | undefined, data: IContratoModificar, id:number, setMensajeActualizar: React.Dispatch<React.SetStateAction<string>>, setMostrarMensajeOK: React.Dispatch<React.SetStateAction<boolean>>, setMostrarMensajeError: React.Dispatch<React.SetStateAction<boolean>>, servContratosConsultar:any, toast: any, ambiente: string|undefined, setPrimeraVez: React.Dispatch<React.SetStateAction<boolean>> ) => {

  servContratoAutorizar.params = {
    ...servContratoAutorizar.params,
    esta_id_actual: KEstado._RECHAZADO,
    esta_id_nuevo: KEstado._BORRADOR,
    usua_id: usuario ? usuario.usua_id : '?_fRechazadoABorrador',
    caso: KCasosTransicion._CASO_TRANS_RECHAZO_BORRADOR,
    mensaje: '',
  }

  const resp:IEndpoint = await fetchTx (servContratoAutorizar, id, ambiente);

  if (resp.code === 0) {
    const d = new Date()
    setMensajeActualizar(`${KContratos._F_MSJE_RECH_BORRADOR_EXITO}${d.toLocaleString()}`);
    setMostrarMensajeOK(true);
    setMostrarMensajeError(false);
    setPrimeraVez(true);

    toast({
      title: KContratos._F_MSJE_TITULO_EXITO,
      description: KContratos._F_MSJE_RECH_BORRADOR_EXITO,
      status: 'success',
      duration: K._DURACION_TOAST_DEFAULT,
      isClosable: true,
    })

    servContratosConsultar.params = {
      ...servContratosConsultar.params,
      pnat_rut: '',
      ctto_nro_interno: '',
      ctto_nombre: '',
      cges_id_list: '',
      esta_id_list: '',
      ctto_fecha_ini: '',
      ctto_fecha_fin: '',
      ctto_id: id.toString(),
      random: Math.random(),
    }
    return(true);
  }
  else {
    setMensajeActualizar(`${KContratos._F_MSJE_RECH_BORRADOR_FRACASO}\n\n${resp.data}`);
    setMostrarMensajeOK(false);
    setMostrarMensajeError(true);

    toast({
      title: KContratos._F_MSJE_TITULO_FRACASO,
      description: KContratos._F_MSJE_RECH_BORRADOR_FRACASO,
      status: 'error',
      duration: K._DURACION_TOAST_DEFAULT,
      isClosable: true,
    })
    return(false);
  }
}

export const fFirmarOK = async ( usuario: IUsuarioContext | undefined, caso: string, data: IContratoModificar, id:number, setMensajeActualizar: React.Dispatch<React.SetStateAction<string>>, setMostrarMensajeOK: React.Dispatch<React.SetStateAction<boolean>>, setMostrarMensajeError: React.Dispatch<React.SetStateAction<boolean>>, servContratosConsultar:any, toast: any, ambiente: string|undefined ) => {

	servContratoAutorizar.params = {
		...servContratoAutorizar.params,
		esta_id_actual: 
			(
				(caso === KEstado._FIRMA_1_OK) 
				? 
					KEstado._ENVIADO_A_FIRMA_1
				: 
				(caso === KEstado._FIRMA_PROVEEDOR_OK) 
				? 
					KEstado._FIRMA_1_OK
				: 
				(caso === KEstado._FIRMA_2_OK) 
				? 
					KEstado._FIRMA_PROVEEDOR_OK
				:
					caso
			),
		esta_id_nuevo: caso,
		usua_id: usuario ? usuario.usua_id : '?_fFirmarOK',
    caso: 
      (
				(caso === KEstado._FIRMA_1_OK) 
				? 
          KCasosTransicion._CASO_TRANS_FIRMA1_OK
				: 
				(caso === KEstado._FIRMA_PROVEEDOR_OK) 
				? 
          KCasosTransicion._CASO_TRANS_FIRMAP_OK
				: 
				(caso === KEstado._FIRMA_2_OK) 
				? 
          KCasosTransicion._CASO_TRANS_FIRMA2_OK
				:
					''
      ),
    mensaje: 
      (
				(caso === KEstado._FIRMA_1_OK) 
				? 
					data.ctto_msje_de_firma1
				: 
				(caso === KEstado._FIRMA_PROVEEDOR_OK) 
				? 
          data.ctto_msje_de_proveedor
				: 
				(caso === KEstado._FIRMA_2_OK) 
				? 
          data.ctto_msje_de_firma2
				:
					''
      )
	}
// caso: 'firma_1_ok' | 'firma_1_rechazo' | 'firma_proveedor_ok' | 'firma_proveedor_rechazo' | 'firma_2_ok  | 'firma_2_rechazo' 
	const resp:IEndpoint = await fetchTx (servContratoAutorizar, id, ambiente);

	if (resp.code === 0) {
		const d = new Date()
		setMensajeActualizar(`${KContratos._F_MSJE_FIRMAR_EXITO}${d.toLocaleString()}`);
		setMostrarMensajeOK(true);
		setMostrarMensajeError(false);

		toast({
			title: KContratos._F_MSJE_TITULO_EXITO,
			description: KContratos._F_MSJE_FIRMAR_EXITO,
			status: 'success',
			duration: K._DURACION_TOAST_DEFAULT,
			isClosable: true,
		})

		servContratosConsultar.params = {
			...servContratosConsultar.params,
			pnat_rut: '',
			ctto_nro_interno: '',
			ctto_nombre: '',
			cges_id_list: '',
			esta_id_list: '',
			ctto_fecha_ini: '',
			ctto_fecha_fin: '',
			ctto_id: id.toString(),
			random: Math.random(),
		}
		return(true);
	}
	else {
		setMensajeActualizar(`${KContratos._F_MSJE_FIRMAR_FRACASO}\n\n${resp.data}`);
		setMostrarMensajeOK(false);
		setMostrarMensajeError(true);

		toast({
			title: KContratos._F_MSJE_TITULO_FRACASO,
			description: KContratos._F_MSJE_FIRMAR_FRACASO,
			status: 'error',
			duration: K._DURACION_TOAST_DEFAULT,
			isClosable: true,
		})
		return(false);
	}
}
