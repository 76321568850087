import { FC } from 'react';
import { useState } from 'react';
import { useFetch } from '../../hooks/useFetch';
import PendientesFirmaRspta from './PendientesFirmaRspta'
import { servContratosConsultar } from '../../utils/servicios';
import { IAplicacionesPermisos, IUseFetch } from '../../interfaces/iEndpoints';
import {
    Box,
    Button,
    Container,
    HStack,
    Heading,
    Stack,
    VStack,
    useColorModeValue,
    Text,
  } from '@chakra-ui/react'
import { IconRefrescar } from '../../utils/Icons';
import { K, KApps, KContratos, KEstado } from '../../utils/constantes';
import { listaCGestionAut } from '../../utils/utilidades';

interface Props {
  tAplicacionesPermisos: IAplicacionesPermisos[],
}

const PendientesFirma: FC<Props> = ({ tAplicacionesPermisos }) => {

  const [ consultar, setConsultar ] = useState (1);
  const colorTitulo = useColorModeValue(K._COLOR_TITULO_L, K._COLOR_TITULO_D);

  servContratosConsultar.params = {
      ...servContratosConsultar.params,
      // esta_id_list: `${KEstado._ENVIADO_A_FIRMA_1},${KEstado._FIRMA_1_OK},${KEstado._FIRMA_PROVEEDOR_OK}`,
      esta_id_list: KEstado._FIRMA_1_OK,
      pnat_rut: '',
      ctto_id: '',
      ctto_nro_interno: '',
      ctto_nombre: '',
      cges_id_list: listaCGestionAut(tAplicacionesPermisos, KApps._PANEL_CONTROL),
      ctto_fecha_ini: '',
      ctto_fecha_fin: '',
    }
    
  const onClickConsultar = (e: React.SyntheticEvent) => {
    setConsultar(consultar+1);
    servContratosConsultar.params = {
      ...servContratosConsultar.params,
      random: Math.random(),
    }
  }

  const contratosServ:IUseFetch = useFetch (servContratosConsultar, JSON.stringify ( servContratosConsultar.params ), 'PendientesFirma');

    return (
      <Container 
        py={{ base: '1', md: '2' }} 
        px={{ base: '1', md: '2' }} 
        maxW='6xl' 
        p="3" 
        bg={useColorModeValue("white", "gray.700")}
      >

        <Stack direction={'column'} spacing={5}>

            <Box overflowX="auto" bg={useColorModeValue("white", "gray.700") } p={5} >
              <VStack direction={{ base: 'column', md: 'row' }} spacing={5}>

                <HStack spacing={3}>
                  <Heading size='md' color={colorTitulo}>{KContratos._PF_TITULO1}</Heading>
                  <Text fontSize='md'>{KContratos._PF_TITULO2}</Text>
                  <Button onClick={onClickConsultar} colorScheme='teal' variant='ghost' leftIcon={<IconRefrescar/>} />
                </HStack>


                {
                  contratosServ.cargando
                  ?
                  <Button isLoading={true} loadingText={K._CARGANDO} colorScheme='teal' variant='outline' />
                  :
                  <PendientesFirmaRspta data={contratosServ.data.data} />
                }


              </VStack>              
            </Box>
        </Stack>              
      </Container>
    )
  }

  export default PendientesFirma;