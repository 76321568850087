import * as React from 'react'
import { Navbar } from './Navbar'
import Sidebar from './Sidebar'
import RoutesPortal from '../../routers/RoutesPortal';
import {TablasContext} from '../../context/TablasContext';
import {
    Box,
    Button,
    Flex,
    Stack,
    useBreakpointValue,
    useColorModeValue,
  } from '@chakra-ui/react'
import { K, KApps, KAyudaForm, KConfiguracion, KTablas } from '../../utils/constantes';
import { Config, ISAplicacionesPermisos, IUseFetch } from '../../interfaces/iEndpoints';
import { servAplicacionPermisosConsultar, servConfiguracion } from '../../utils/servicios';
import { useFetch } from '../../hooks/useFetch';
import { AuthContext } from '../../context/auth/AuthContext';
import { useContext } from 'react';
  
export const Portal = () => {
  
    const { usuario } = useContext( AuthContext );

    const isDesktop = useBreakpointValue({ base: false, lg: true })
    const bg1 = useColorModeValue('gray.200', 'gray.900');
    const bg2 = useColorModeValue('white', 'gray.800');

    const pCentrosGestion:Config = { ...servConfiguracion };
    pCentrosGestion.params = {
      ...pCentrosGestion.params,
      caso: KConfiguracion._CGESTION,
    }
    const tCentrosGestion:IUseFetch = useFetch (pCentrosGestion, JSON.stringify ( servConfiguracion.params ), 'pCentrosGestion', false);
  
    const pTContrato:Config = { ...servConfiguracion };
    pTContrato.params = {
      ...pTContrato.params,
      caso: KConfiguracion._TCONTRATOS,
    }
    const tTContrato:IUseFetch = useFetch (pTContrato, JSON.stringify ( pTContrato.params ), 'pTContrato', false);
  
    const pTCTransiciones:Config = { ...servConfiguracion };
    pTCTransiciones.params = {
      ...pTCTransiciones.params,
      caso: KConfiguracion._TRANSICIONES_X_TCONTRATO,
      tcon_id: '', //K._TCON_ID_DEFAULT,//???
    }
    const tTCTransiciones:IUseFetch = useFetch (pTCTransiciones, JSON.stringify ( pTCTransiciones.params ), 'pTCTransiciones', false);
  
    const pTCTransicionesPerfiles:Config = { ...servConfiguracion };
    pTCTransicionesPerfiles.params = {
      ...pTCTransicionesPerfiles.params,
      caso: KConfiguracion._TRANSICIONES_X_PARAM,
      tcon_id: '', //K._TCON_ID_DEFAULT,//???
    }
    const tTCTransicionesPerfiles:IUseFetch = useFetch (pTCTransicionesPerfiles, JSON.stringify ( pTCTransicionesPerfiles.params ), 'Perfiles', false);

    const pMonedas:Config = { ...servConfiguracion };
    pMonedas.params = {
      ...pMonedas.params,
      caso: KConfiguracion._TABLAS,
      tabl_id: KTablas._MONEDAS,
    }
    const tMonedas:IUseFetch = useFetch (pMonedas, JSON.stringify ( pMonedas.params ), 'tMonedas', false);
  
    const pAyuda:Config = { ...servConfiguracion };
    pAyuda.params = {
      ...pAyuda.params,
      caso: KConfiguracion._TABLAS_REL,
      tabp_id: KTablas._AYUDA_FORM,
      tabp_elemento: KAyudaForm._CONTRATO_MANT,
      tabh_id: KTablas._AYUDA_CAMPO,
    }
    const tAyuda:IUseFetch = useFetch (pAyuda, JSON.stringify ( pAyuda.params ), 'tAyuda', false);
  
    const pTipoPagoCuotas:Config = { ...servConfiguracion };
    pTipoPagoCuotas.params = {
      ...pTipoPagoCuotas.params,
      caso: KConfiguracion._TABLAS,                                                             
      tabl_id: KTablas._TIPO_PAGO_CUOTAS,
    }
    const tTipoPagoCuotas:IUseFetch = useFetch (pTipoPagoCuotas, JSON.stringify ( pTipoPagoCuotas.params ), 'pTipoPagoCuotas', false);
  
    const pEstadoCivil:Config = { ...servConfiguracion };
    pEstadoCivil.params = {
      ...pEstadoCivil.params,
      caso: KConfiguracion._TABLAS,
      tabl_id: KTablas._ESTADO_CIVIL,
    }
    const tEstadoCivil:IUseFetch = useFetch (pEstadoCivil, JSON.stringify ( pEstadoCivil.params ), 'pEstadoCivil', false);
  
    const pGenero:Config = { ...servConfiguracion };
    pGenero.params = {
      ...pGenero.params,
      caso: KConfiguracion._TABLAS,
      tabl_id: KTablas._GENERO,
    }
    const tGenero:IUseFetch = useFetch (pGenero, JSON.stringify ( pGenero.params ), 'pGenero', false);
  
    const pBancos:Config = { ...servConfiguracion };
    pBancos.params = {
      ...pBancos.params,
      caso: KConfiguracion._TABLAS,
      tabl_id: KTablas._BANCOS,
    }
    const tBancos:IUseFetch = useFetch (pBancos, JSON.stringify ( pBancos.params ), 'pBancos', false);
  
    const pTipoCuenta:Config = { ...servConfiguracion };
    pTipoCuenta.params = {
      ...pTipoCuenta.params,
      caso: KConfiguracion._TABLAS,
      tabl_id: KTablas._TIPO_CUENTA,
    }
    const tTipoCuenta:IUseFetch = useFetch (pTipoCuenta, JSON.stringify ( pTipoCuenta.params ), 'pTipoCuenta', false);
  
    const pTipoAdjunto:Config = { ...servConfiguracion };
    pTipoAdjunto.params = {
      ...pTipoCuenta.params,
      caso: KConfiguracion._TABLAS,
      tabl_id: KTablas._TIPO_ADJUNTO,
    }
    const tTipoAdjunto:IUseFetch = useFetch (pTipoAdjunto, JSON.stringify ( pTipoAdjunto.params ), 'pTipoAdjunto', false);

    const pParametros:Config = { ...servConfiguracion };
    pParametros.params = {
      ...pParametros.params,
      caso: KConfiguracion._TABLAS,
      tabl_id: KTablas._PARAMETROS,
    }
    const tParametros:IUseFetch = useFetch (pParametros, JSON.stringify ( pParametros.params ), 'pParametros', false);

    const pPaises:Config = { ...servConfiguracion };
    pPaises.params = {
      ...pPaises.params,
      caso: KConfiguracion._TABLAS,
      tabl_id: KTablas._PAISES,
    }
    const tPaises:IUseFetch = useFetch (pPaises, JSON.stringify ( pPaises.params ), 'pPaises', false);
     
    const pRegiones:Config = { ...servConfiguracion };
    pRegiones.params = {
      ...pRegiones.params,
      caso: KConfiguracion._TABLAS,
      tabl_id: KTablas._REGIONES,
    }
    const tRegiones:IUseFetch = useFetch (pRegiones, JSON.stringify ( pRegiones.params ), 'pRegiones', false);
  
    const pComunas:Config = { ...servConfiguracion };
    pComunas.params = {
      ...pComunas.params,
      caso: KConfiguracion._TABLAS,                                                             
      tabl_id: KTablas._COMUNAS,
    }
    const tComunas:IUseFetch = useFetch (pComunas, JSON.stringify ( pComunas.params ), 'pComunas', false);

    const pAplicacionesPermisos:ISAplicacionesPermisos = { ...servAplicacionPermisosConsultar };
    pAplicacionesPermisos.params = {
      ...pAplicacionesPermisos.params,
      apps_id: KApps._PANEL_CONTROL,                                                             
      perf_id: usuario?.perf_id,
    }
    const tAplicacionesPermisos:IUseFetch = useFetch (pAplicacionesPermisos, JSON.stringify ( pAplicacionesPermisos.params ), 'pAplicacionesPermiso', false);

    return (
      (
        tCentrosGestion.cargando ||
        tMonedas.cargando ||
        tTContrato.cargando ||
        tAyuda.cargando ||
        tTipoPagoCuotas.cargando ||
        tEstadoCivil.cargando ||
        tGenero.cargando ||
        tBancos.cargando ||
        tTipoCuenta.cargando ||
        tTipoAdjunto.cargando ||
        tPaises.cargando ||
        tRegiones.cargando ||
        tComunas.cargando ||
        tParametros.cargando ||
        tTCTransiciones.cargando ||
        tTCTransicionesPerfiles.cargando ||
        tAplicacionesPermisos.cargando
      )
      ?
        <Button isLoading={true} loadingText={K._CARGANDO} colorScheme='teal' variant='outline' />
      :
      <TablasContext.Provider
        value={{
            'tCentrosGestion': tCentrosGestion.data.data,
            'tMonedas': tMonedas.data.data,
            'tTContrato': tTContrato.data.data,
            'tAyuda': tAyuda.data.data,
            'tTipoPagoCuotas': tTipoPagoCuotas.data.data,
            'tEstadoCivil': tEstadoCivil.data.data,
            'tGenero': tGenero.data.data,
            'tBancos': tBancos.data.data,
            'tTipoCuenta': tTipoCuenta.data.data,
            'tPaises': tPaises.data.data,
            'tRegiones': tRegiones.data.data,
            'tComunas': tComunas.data.data,
            'tAplicacionesPermisos': tAplicacionesPermisos.data.data,
            'tTCTransiciones': tTCTransiciones.data.data,
            'tTCTransicionesPerfiles': tTCTransicionesPerfiles.data.data,
            'tTipoAdjunto': tTipoAdjunto.data.data,
            'tParametros': tParametros.data.data,
        }}
      >
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          height="100vh"
          overflowY="auto"
          bg={bg1}
        >
          <Box bg={bg2} flex="0" p={0}>
            {isDesktop ? <Sidebar /> : <Navbar />}
          </Box>
    
          <Box bg={bg1} flex="0" p={0}>
            <Stack spacing={{ base: '0', lg: '0' }} >
              <RoutesPortal />
            </Stack>
          </Box>
        </Flex>
      </TablasContext.Provider>
    )
  }
     