import Cookies from "js-cookie";
import { K } from "./constantes";

export const fetchSubirAdjunto = async ( data, servicio, ambiente ) => {

    const d = new Date();
    const d_str = d.toJSON();

    const formData = new FormData();

    const nameFile = data.adju_path.name;
    const extFile = nameFile ? nameFile.split('.').pop() : null;

    formData.append("tadj_id", data.tadj_id);
    formData.append("adju_nombre", `${data.ctto_id}_${d_str}.${extFile}`);
    formData.append("adju_descripcion", data.adju_descripcion);
    formData.append("adjunto", data.adju_path);
    formData.append("usua_id", data.usua_id);

    const url = (data.ctto_id) ? `${ambiente}${servicio.endPoint}/${data.ctto_id}` : `${ambiente}${servicio.endPoint}`;

    const headers = new Headers();

    var respServicio = {
        code: 0,
        data: ''
    } 

    if ( servicio.usaToken ) {
        const jwt = Cookies.get ("jwt");
        if (!jwt) {
            respServicio = {
                code: -1,
                data: '!JWT'
            } 
            return respServicio;
        }
        headers.append ( 'Authorization', `Bearer ${jwt}`);
    }

    const header = {
        method: servicio.metodo,
        body: formData,
        headers
    }

    await fetch ( url, header )

    .then ( async (resp) => {
        if (resp.ok) {
            const data = await resp.json();
            respServicio = {
                code: 0,
                data: data.data
            } 
        } else {
            return await resp.text().then ( text => { throw new Error(text) })
        }
    })

    .catch ( (error) => {
        const { code, message } = JSON.parse(error.message);
        respServicio = {
            code:((code===0)?K._CODE_ERROR_500_API:code),
            data:message,
        } 
    })

    return respServicio;
} 
