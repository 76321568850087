import { FC, useState } from 'react';
import {
  Box,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { servContratoBitacoraConsultar, servContratoDescargar } from '../../utils/servicios';
import { IContratoBitacora } from '../../interfaces/iEndpoints';
import { K, KContratos } from '../../utils/constantes';
import { formatFecha, nombreUsuario, truncarTexto } from '../../utils/utilidades';
import { fetchQS } from '../../utils/fetchQS';
import { fetchDescargarContrato } from '../../utils/fetchDescargarContrato';

interface Props {
  id: number;
  ambiente: string | undefined;
}

const ContratoBitacora: FC<Props> = ({ id, ambiente }) => {

  const [ primeraVez, setPrimeravez ] = useState(true);
  const [ cargando, setCargando ] = useState(true);
  const [ datos, setDatos ] = useState([]);
  const [ errorDetectado, setErrorDetectado ] = useState('');
  const toast       = useToast();

  const colorBG   = useColorModeValue("gray.50", "gray.700");
  const colorBG2  = useColorModeValue("gray.100", "gray.600")

  if (primeraVez) {
    fetchQS (servContratoBitacoraConsultar, id, ambiente)
    .then ( resp => {
      if (resp.code !== 0) {
        setErrorDetectado(KContratos._F_MSJE_ERROR_BITACORA);
      }
      else if (resp.data.length > 0) {
        const datosR:any = resp.data;
        setDatos(datosR);
      } 
      setCargando(false);
    })

    .catch ( (error) => {
        const { message } = JSON.parse(error.message);
        setErrorDetectado(message);
        setCargando(false);
    })    
  
    setPrimeravez(false);
  }

  const botonDescarga = (fila:IContratoBitacora ) => {
    if (!fila.bita_bita_pdf) return null;

    return(
      <Button 
        variant='outline' 
        onClick={handleOnClick} 
        size={'xs'} 
        id={`${fila.bita_bita_id}|${fila.bita_bita_pdf}`} 
        value={fila.bita_bita_id} 
        title={fila.bita_bita_id}
      >
        {KContratos._BI_BOTON_DESCARGA}
      </Button>
    )
  }

  const handleOnClick = (e: React.SyntheticEvent):void => {

    const [bita_id, bita_path] = e.currentTarget.id.split('|');

    fetchDescargarContrato (servContratoDescargar, id, bita_id, bita_path, ambiente)
    .then ( resp => {
      if (resp.code !== 0) {
        toast({
          title: KContratos._SC_MSJE_TITULO_FRACASO,
          description: `${KContratos._SC_MSJE_DESCARGAR_FRACASO} [1]`,
          status: 'error',
          duration: K._DURACION_TOAST_DEFAULT,
          isClosable: true,
        })
      }
      else {
        toast({
          title: KContratos._SC_MSJE_TITULO_EXITO,
          description: KContratos._SC_MSJE_DESCARGAR_EXITO,
          status: 'success',
          duration: K._DURACION_TOAST_DEFAULT,
          isClosable: true,
        })
      }
    })

    .catch ( (error) => {
      setErrorDetectado(error);
      toast({
        title: KContratos._SC_MSJE_TITULO_FRACASO,
        description: `${KContratos._SC_MSJE_DESCARGAR_FRACASO} [b]`,
        status: 'error',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })
    })    
  }

  if (errorDetectado) {
    return (    <Text>{errorDetectado}</Text>    )
  }
  else if (cargando) {
    return (    <Button isLoading={cargando} loadingText={K._CARGANDO} colorScheme='teal' variant='outline' />    )
  }
  else {
    return(
          <Box
            bg={colorBG}
            w='3xl' 
            p='0'
          >
            <TableContainer>
              <Table colorScheme='teal' size="sm">
                  <Thead>
                    <Tr bg={colorBG2} >
                      <Th></Th>
                      <Th>{KContratos._BI_FECHA}</Th>
                      <Th>{KContratos._BI_ESTADO}</Th>
                      <Th>{KContratos._BI_MENSAJE}</Th>
                      <Th>{KContratos._BI_DESCARGA}</Th>
                      <Th>{KContratos._BI_USUARIO}</Th>
                      <Th>{KContratos._BI_ID}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>

                  {
                    ( (!datos) || (datos.length <= 0) )
                    ?
                    (<Tr key={1}>
                      <Td colSpan={5}>
                        <h1>{K._NOHAYDATOS}</h1>
                      </Td>
                    </Tr>)
                    :
                    (
                      datos.map((fila:IContratoBitacora, index:number) => (
                        (
                          <Tr key={index}>
                            <Td>{index+1}</Td>
                            <Td>{formatFecha(fila.bita_bita_fecha, true, true)}</Td>
                            <Td>{fila.esta_esta_nombre}</Td>
                            <Td>{truncarTexto(fila.bita_bita_mensaje, K._LARGO_TEXT_CONSULTAS)} {truncarTexto(fila.bita_bita_error_mensaj, K._LARGO_TEXT_CONSULTAS_ERROR)}</Td>
                            <Td>{botonDescarga(fila)}</Td>
                            <Td>{nombreUsuario(fila.usua_usua_nombre1, fila.usua_usua_apellido1, fila.usua_usua_apellido2, fila.usua_usua_alias)}</Td>
                            <Td>{fila.bita_bita_id}</Td>
                          </Tr>
                        )
                      )
                    )
                    )
                  }

                  </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )
}
}

export default ContratoBitacora;