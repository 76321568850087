import Cookies from "js-cookie";

export const fetchQS = async ( servicio, id, ambiente ) => {

    const data = encodeURI ( Object.keys(servicio.params).map ( (key) => (key + "=" + servicio.params[key])).join('&') );

    const cola = (id) ? `/${id}` : '';
    const url = servicio.esApi ? `${ambiente}${servicio.endPoint}${cola}?${data}` : `${ambiente}${servicio.endPoint}&${data}`;

    const headers = new Headers();

    headers.append ( 'content-type', servicio.contentType);

    var respServicio = {
        code: 0,
        data: ''
    } 

    if ( servicio.usaToken ) {
        const jwt = Cookies.get ("jwt");
        if (!jwt) {
// console.log('___fetchQS__!jwt_____')            
            respServicio = {
                code: -1,
                data: '!JWT'
            } 
            return respServicio;
        }
        headers.append ( 'Authorization', `Bearer ${jwt}`);
    }

    const header = {
        method: servicio.metodo,
        headers
    }

    await fetch ( url, header )

    .then ( async (resp) => {
        if (resp.ok) {
            const data = await resp.json();
// console.log('__fetchQS___resp_data______', data)
            respServicio = {
                code: 0,
                data: data.data
            } 
        } else {
            return await resp.text().then ( text => { throw new Error(text) })
        }
    })

    .catch ( (error) => {
// console.log('___fetchQS__error___', error)
        const { code, message } = JSON.parse(error.message);
        respServicio = {
            code:code,
            data:message,
        } 
    })

    return respServicio;
} 