import { FC } from 'react';
import { AlertDialog, 
  AlertDialogBody, 
  AlertDialogCloseButton, 
  AlertDialogContent, 
  AlertDialogFooter, 
  AlertDialogHeader, 
  AlertDialogOverlay, 
  Button, 
  Stack } from "@chakra-ui/react"
import { UseFormHandleSubmit } from "react-hook-form";
import { IContratoModificar, ITCTransiciones } from "../../interfaces/iEndpoints";
import { K, KContratos, KEstado } from "../../utils/constantes"

interface Props {
  accion:string,
  cancelRef:React.MutableRefObject<null>,
  onClose:()=>void, 
  isOpen:boolean,
  handleSubmit:UseFormHandleSubmit<IContratoModificar>,
  onEliminar:(data: IContratoModificar) => Promise<void>, 
  onAutorizar:(data: IContratoModificar) => Promise<void>, 
  onRechazar:(data: IContratoModificar) => Promise<void>, 
  onRechABorrador:(data: IContratoModificar) => Promise<void>, 
  onFirmar1ok:(data: IContratoModificar) => Promise<void>, 
  onFirmarPok:(data: IContratoModificar) => Promise<void>, 
  onFirmar2ok:(data: IContratoModificar) => Promise<void>, 
  onClickEliminar: () => void,
  onClickAutorizar: () => void,
  onClickFirmar1ok: () => void,
  onClickFirmarPok: () => void,
  onClickFirmar2ok: () => void,
  onClickRechazar: () => void,
  onClickRechABorrador: () => void,
  tTCTranscionesCtto: ITCTransiciones[],
  isSubmitting:boolean,
  isValid:boolean,
  esContingencia:string,
}

export const ContratoFlujoAcciones: FC<Props> = ({ 
                                              accion, 
                                              cancelRef, 
                                              onClose, 
                                              isOpen, 
                                              handleSubmit, 
                                              onEliminar, 
                                              onAutorizar, 
                                              onRechazar, 
                                              onRechABorrador, 
                                              onFirmar1ok, 
                                              onFirmarPok, 
                                              onFirmar2ok, 
                                              onClickEliminar,
                                              onClickAutorizar,
                                              onClickFirmar1ok,
                                              onClickFirmarPok,
                                              onClickFirmar2ok,
                                              onClickRechazar,
                                              onClickRechABorrador,
                                              tTCTranscionesCtto,
                                              isSubmitting,
                                              isValid,
                                              esContingencia
                                          }) => {

    let titulo = '';
    let cuerpo = '';

    switch (accion) {
      case KContratos._F_ACCION_ELIMINAR:
        titulo = KContratos._F_ACCION_ELIMINAR_MSJE_TITULO;
        cuerpo = KContratos._F_ACCION_ELIMINAR_MSJE_CUERPO;
        break;
      case KContratos._F_ACCION_AUTORIZAR:
        titulo = KContratos._F_ACCION_AUTORIZAR_MSJE_TITULO;
        cuerpo = KContratos._F_ACCION_AUTORIZAR_MSJE_CUERPO;
        break;
      case KContratos._F_ACCION_FIRMAR1OK:
        titulo = KContratos._F_ACCION_FIRMAR_MSJE_TITULO;
        cuerpo = KContratos._F_ACCION_FIRMAR_MSJE_CUERPO;
        break;
      case KContratos._F_ACCION_FIRMARPOK:
        titulo = KContratos._F_ACCION_FIRMAR_MSJE_TITULO;
        cuerpo = KContratos._F_ACCION_FIRMAR_MSJE_CUERPO;
        break;
      case KContratos._F_ACCION_FIRMAR2OK:
        titulo = KContratos._F_ACCION_FIRMAR_MSJE_TITULO;
        cuerpo = KContratos._F_ACCION_FIRMAR_MSJE_CUERPO;
        break;
      case KContratos._F_ACCION_RECHAZAR:
        titulo = KContratos._F_ACCION_RECHAZAR_MSJE_TITULO;
        cuerpo = KContratos._F_ACCION_RECHAZAR_MSJE_CUERPO;
        break;
      case KContratos._F_ACCION_RECHBORR:
        titulo = KContratos._F_ACCION_RECH_BORRADOR_MSJE_TITULO;
        cuerpo = KContratos._F_ACCION_RECH_BORRADOR_MSJE_CUERPO;
        break;
      default:
        break;
    }

    return (
      <>
        <Stack  direction={{ base: 'column', md: 'row' }}>
        {
          tTCTranscionesCtto.map( transicion => {
            switch (transicion.trsn_esta_id_fin) {

              case KEstado._ELIMINADO:

                return (
                  <Button 
                    colorScheme='green' 
                    variant='outline' 
                    onClick={ onClickEliminar }
                    type="button"
                    isLoading={isSubmitting} 
                    value={`${transicion.trsn_esta_id_ini}|${transicion.trsn_esta_id_fin}`}
                    key={`${transicion.trsn_esta_id_ini}|${transicion.trsn_esta_id_fin}`}
                  >
                    {transicion.trsn_tran_nombre}
                  </Button>
                );

              case KEstado._BORRADOR:

                return (
                  (transicion.trsn_esta_id_ini === KEstado._BORRADOR)
                  ?
                    <Button 
                      colorScheme='green' 
                      variant='outline' 
                      type="submit"
                      isLoading={isSubmitting} 
                      value={`${transicion.trsn_esta_id_ini}|${transicion.trsn_esta_id_fin}`}
                      key={`${transicion.trsn_esta_id_ini}|${transicion.trsn_esta_id_fin}`}
                    >
                      {transicion.trsn_tran_nombre}
                    </Button>
                  :
                    <Button 
                      colorScheme='green' 
                      variant='outline' 
                      onClick={ onClickRechABorrador }
                      type="button"
                      isLoading={isSubmitting} 
                      value={`${transicion.trsn_esta_id_ini}|${transicion.trsn_esta_id_fin}`}
                      key={`${transicion.trsn_esta_id_ini}|${transicion.trsn_esta_id_fin}`}
                    >
                      {transicion.trsn_tran_nombre}
                    </Button>
                );

              case KEstado._ENVIADO_A_FIRMA_1:

                return(
                  <Button 
                    colorScheme='green' 
                    variant='outline' 
                    onClick={ onClickAutorizar }
                    type="button"
                    isLoading={isSubmitting} 
                    value={`${transicion.trsn_esta_id_ini}|${transicion.trsn_esta_id_fin}`}
                    key={`${transicion.trsn_esta_id_ini}|${transicion.trsn_esta_id_fin}`}
                  >
                    {transicion.trsn_tran_nombre}
                  </Button>
                );

              case KEstado._FIRMA_1_OK:

                return (
                  ( (esContingencia === K._CONTINGENCIA_TODO) || (esContingencia === K._CONTINGENCIA_FIRMA_1_OK) )
                  ?
                    <Button 
                      colorScheme='green' 
                      variant='outline' 
                      onClick={ onClickFirmar1ok }
                      type="button"
                      isLoading={isSubmitting} 
                      value={`${transicion.trsn_esta_id_ini}|${transicion.trsn_esta_id_fin}`}
                      key={`${transicion.trsn_esta_id_ini}|${transicion.trsn_esta_id_fin}`}
                    >
                      {transicion.trsn_tran_nombre}
                    </Button>
                  :
                    null
                );

              case KEstado._FIRMA_PROVEEDOR_OK:

                  return (
                    ( (esContingencia === K._CONTINGENCIA_TODO) || (esContingencia === K._CONTINGENCIA_FIRMA_PROVEEDOR_OK) )
                    ?
                      <Button 
                        colorScheme='green' 
                        variant='outline' 
                        onClick={ onClickFirmarPok }
                        type="button"
                        isLoading={isSubmitting} 
                        value={`${transicion.trsn_esta_id_ini}|${transicion.trsn_esta_id_fin}`}
                        key={`${transicion.trsn_esta_id_ini}|${transicion.trsn_esta_id_fin}`}
                      >
                        {transicion.trsn_tran_nombre}
                      </Button>
                    :
                      null
                  );

              case KEstado._FIRMA_2_OK:
                  return (
                    ( (esContingencia === K._CONTINGENCIA_TODO) || (esContingencia === K._CONTINGENCIA_FIRMA_2_OK) )
                    ?
                      <Button 
                        colorScheme='green' 
                        variant='outline' 
                        onClick={ onClickFirmar2ok }
                        type="button"
                        isLoading={isSubmitting} 
                        value={`${transicion.trsn_esta_id_ini}|${transicion.trsn_esta_id_fin}`}
                        key={`${transicion.trsn_esta_id_ini}|${transicion.trsn_esta_id_fin}`}
                      >
                        {transicion.trsn_tran_nombre}
                      </Button>
                    :
                      null
                  );

              case KEstado._RECHAZADO:

                return (
                  (esContingencia === K._CONTINGENCIA_TODO)
                  ?
                    <Button 
                      colorScheme='green' 
                      variant='outline' 
                      onClick={ onClickRechazar }
                      type="button"
                      isLoading={isSubmitting} 
                      value={`${transicion.trsn_esta_id_ini}|${transicion.trsn_esta_id_fin}`}
                      key={`${transicion.trsn_esta_id_ini}|${transicion.trsn_esta_id_fin}`}
                    >
                      {transicion.trsn_tran_nombre}
                    </Button>
                  :
                    null
                );

              default:
                return null;
                
              }
          })
        }  
      </Stack>

      <AlertDialog
          motionPreset='slideInBottom'
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isOpen={isOpen}
          isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>
            {titulo}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
             {
              !isValid 
              ? 
                KContratos._F_MSJE_ERROR_FORM
              :
                cuerpo
              }
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              {KContratos._F_BOTON_CONFIRMAR_CANCELAR}
            </Button>
            {
              !isValid 
              ?
              null
              :

            <Button 
              colorScheme='red' 
              ml={3} 
              onClick={  
                (accion === KContratos._F_ACCION_ELIMINAR) 
                ? 
                  handleSubmit(onEliminar) 
                :
                (accion === KContratos._F_ACCION_AUTORIZAR) 
                ?
                  handleSubmit(onAutorizar)  
                :
                (accion === KContratos._F_ACCION_RECHAZAR) 
                ?
                  handleSubmit(onRechazar)  
                :
                (accion === KContratos._F_ACCION_RECHBORR) 
                ?
                  handleSubmit(onRechABorrador)  
                :
                (accion === KContratos._F_ACCION_FIRMAR1OK) 
                ?
                  handleSubmit(onFirmar1ok)  
                :
                (accion === KContratos._F_ACCION_FIRMARPOK) 
                ?
                  handleSubmit(onFirmarPok)  
                :
                (accion === KContratos._F_ACCION_FIRMAR2OK) 
                ?
                  handleSubmit(onFirmar2ok)  
                :
                  undefined
              }
              >
              {KContratos._F_BOTON_CONFIRMAR_ACEPTAR}
            </Button>
            }
            </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>  
    </>
  )
}