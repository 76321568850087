import { Button, Container, Divider, useColorModeValue, VStack, Text, HStack, useToast } from '@chakra-ui/react';
import { FC, useContext } from 'react';
import { AuthContext } from '../../context';
import { TablasContext } from '../../context/TablasContext';
import { useFetch } from '../../hooks/useFetch';
import { IContratoConsultar, IUseFetch } from '../../interfaces/iEndpoints';
import { K, KContratos, KV } from '../../utils/constantes';
import { fetchDescargarContrato } from '../../utils/fetchDescargarContrato';
import { IconDescargar4 } from '../../utils/Icons';
import { servContratoDescargar, servMumeroALetras } from '../../utils/servicios';
import { elementoTabla, errorSiNulo, fechaTexto, formatMonto, formatRUT, nombrePersona } from '../../utils/utilidades';

interface Props {
    datos: IContratoConsultar;
}
  
const ContratoPreliminar: FC<Props> = ({ datos }) => {

  const { ambiente } = useContext( AuthContext );
  const { tComunas, tRegiones, tPaises, tBancos, tTipoCuenta, tMonedas } = useContext( TablasContext );

  const colorBG    = useColorModeValue("white", "gray.700");
  const toast      = useToast();

  servMumeroALetras.params = {
    ...servMumeroALetras.params,
    numero: datos.ctto_ctto_monto_total,
    genero: elementoTabla (datos.ctto_mone_id, tMonedas, K._TABLA_CAMPO_STR2 ),
  }

  let plantilla = datos.tcon_tcon_plantilla;

  const pNumeroALetras:IUseFetch = useFetch (servMumeroALetras, JSON.stringify ( servMumeroALetras.params ), 'ContratoPreliminar', false);
  const { cargando, data } = pNumeroALetras;
  const montoEscrito = data.data ? data.data : '*** ERROR ***';

  // {{
  plantilla = plantilla.replace(/{{/g, "<span style=\"background-color:yellow; color:black\">{{" );

  // }}
  plantilla = plantilla.replace(/}}/g, "}}</span>");

  // insert br entre parrafos
  plantilla = plantilla.replace(/<p align="justify" data="parrafo">/g, "<br/><p align=\"justify\" data=\"parrafo\">")

  //  {{ctto_monto_total.escrito}}
  plantilla = plantilla.replace(/{{ctto_monto_total.escrito}}/g, montoEscrito.toLowerCase() + ' ' + elementoTabla (datos.ctto_mone_id, tMonedas, K._TABLA_CAMPO_STR1 ) );

  //  {{ctto_fecha_ppal.texto}}
  plantilla = plantilla.replace(/{{ctto_fecha_ppal.texto}}/g, fechaTexto ( datos.ctto_ctto_fecha_ppal, '', false) );

  //  {{ctto_fecha_ini.texto}}
  plantilla = plantilla.replace(/{{ctto_fecha_ini.texto}}/g, fechaTexto ( datos.ctto_ctto_fecha_ini, '', false) );

  //  {{ctto_fecha_fin.texto}}
  plantilla = plantilla.replace(/{{ctto_fecha_fin.texto}}/g, fechaTexto ( datos.ctto_ctto_fecha_fin, '', false) );

  // {{ctto_nombre}}
  plantilla = plantilla.replace(/{{ctto_nombre}}/g, errorSiNulo( datos.ctto_ctto_nombre ) );

  // {{ctto_monto_total.format}}
  plantilla = plantilla.replace(/{{ctto_monto_total.format}}/g, formatMonto (datos.ctto_mone_id, parseFloat(datos.ctto_ctto_monto_total), 0, true) );

  //{{ctto_objetivo}}
  plantilla = plantilla.replace(/{{ctto_objetivo}}/g, datos.ctto_ctto_objetivo );

  //{{ctto_descripcion.list}}
  let descripcion = datos.ctto_ctto_descripcion.replace(/\n/g, '</li><li>');
  descripcion = '<ul style="padding:15px; background-color:yellow; color:black"><li>' + descripcion + '</li></ul>';

  plantilla = plantilla.replace(/{{ctto_descripcion.list}}/g, descripcion );
  
  // {{pnat_rut.format}}
  plantilla = plantilla.replace(/{{pnat_rut.format}}/g, formatRUT (datos.pnat_pnat_rut) );

  // {{pnat_nombre_completo}}
  plantilla = plantilla.replace(/{{pnat_nombre_completo}}/g, nombrePersona ( datos.pnat_pnat_nombre1, datos.pnat_pnat_nombre2, datos.pnat_pnat_apellido1, datos.pnat_pnat_apellido2, '', false, false ))

  // {{pnat_nombre_completo.may}}
  plantilla = plantilla.replace(/{{pnat_nombre_completo.may}}/g, nombrePersona ( datos.pnat_pnat_nombre1, datos.pnat_pnat_nombre2, datos.pnat_pnat_apellido1, datos.pnat_pnat_apellido2, datos.pnat_pnat_genero, true, false ))

  // {{pnat_nombre_completo.presentacion}}
  plantilla = plantilla.replace(/{{pnat_nombre_completo.presentacion}}/g, nombrePersona ( datos.pnat_pnat_nombre1, datos.pnat_pnat_nombre2, datos.pnat_pnat_apellido1, datos.pnat_pnat_apellido2, datos.pnat_pnat_genero, false, true ))

  // {{pnat_correo}}
  plantilla = plantilla.replace(/{{pnat_correo}}/g, errorSiNulo( datos.pnat_pnat_correo ) );

  // {{naci_id.nacionalidad}}
  plantilla = plantilla.replace(/{{naci_id.nacionalidad}}/g, elementoTabla (datos.pnat_naci_id, tPaises, K._TABLA_CAMPO_STR1 )  );

  // {{pnat_profesion}}
  plantilla = plantilla.replace(/{{pnat_profesion}}/g, errorSiNulo( datos.pnat_pnat_profesion ) );

  // {{pnat_direccion}}
  plantilla = plantilla.replace(/{{pnat_direccion}}/g, errorSiNulo( datos.pnat_pnat_direccion ) );

  // {{comu_id.nombre}}
  plantilla = plantilla.replace(/{{comu_id.nombre}}/g, elementoTabla (datos.pnat_comu_id, tComunas, K._TABLA_CAMPO_NOMBRE ) );

  // {{comu_id.ciudad}}
  plantilla = plantilla.replace(/{{comu_id.ciudad}}/g, elementoTabla (datos.pnat_comu_id, tComunas, K._TABLA_CAMPO_STR1 ) );

  // {{regi_id.nombre}}
  plantilla = plantilla.replace(/{{regi_id.nombre}}/g, elementoTabla (datos.pnat_regi_id, tRegiones, K._TABLA_CAMPO_NOMBRE ) );

  //{{pnat_fecha_nac.texto.genero}}
  plantilla = plantilla.replace(/{{pnat_fecha_nac.texto.genero}}/g, fechaTexto ( datos.pnat_pnat_fecha_nac, datos.pnat_pnat_genero, true)  );

// {{pnat_tx_cuenta_banco.nombre}}
plantilla = plantilla.replace(/{{pnat_tx_cuenta_banco.nombre}}/g, elementoTabla (datos.pnat_pnat_tx_cuenta_banco, tBancos, K._TABLA_CAMPO_DESCRIPCION ) );

// {{pnat_tx_cuenta_tipo.nombre}} 
plantilla = plantilla.replace(/{{pnat_tx_cuenta_tipo.nombre}}/g, elementoTabla (datos.pnat_pnat_tx_cuenta_tipo, tTipoCuenta, K._TABLA_CAMPO_NOMBRE ) ) ;

  //  {{pnat_tx_cuenta_nro}} 
  plantilla = plantilla.replace(/{{pnat_tx_cuenta_nro}}/g, errorSiNulo( datos.pnat_pnat_tx_cuenta_nro ) );

  // {{cges_texto1}}
  plantilla = plantilla.replace(/{{cges_texto1}}/g, errorSiNulo( datos.cges_cges_texto1 ) );
  
  // {{ctto_id}}
  plantilla = plantilla.replace(/{{ctto_id}}/g, datos.ctto_ctto_id);

  // {{ctto_nro_interno}}
  plantilla = plantilla.replace(/{{ctto_nro_interno}}/g, datos.ctto_ctto_nro_interno);

  //{{ctto_cuotas.texto}}
  /*
  let texto_pago = ''
  if (datos.ctto_ctto_pago_texto_sn === K._SI) {
    texto_pago = datos.ctto_ctto_pago_descripcion;
  }
  else {
    texto_pago = ' en ' + datos.ctto_ctto_cantidad_cuotas + ' cuota' + (parseInt(datos.ctto_ctto_cantidad_cuotas) > 1 ? 's ' : ' ') + ((datos.ctto_ctto_unidad_cuotas && (datos.ctto_ctto_unidad_cuotas !== K._IGNORAR_ID) ) ? datos.ctto_ctto_unidad_cuotas : '')  + ' de ' + formatMonto ( datos.ctto_mone_id, parseFloat(datos.ctto_ctto_monto_cuotas), 0, true ) + ' bruto.';
  }
  plantilla = plantilla.replace(/{{ctto_cuotas.texto}}/g, texto_pago );
  */
  plantilla = plantilla.replace(/{{ctto_cuotas.texto}}/g, errorSiNulo( datos.ctto_ctto_pago_descripcion ) );

  // <ol type="a" data="lista_ol">
  plantilla = plantilla.replace(/<ol type="a" data="lista_ol">/g, `<ol type="a" key=${Math.floor(Math.random() * 100)} style="padding-left:20px;">` );

  const onClickDescargar = ():void => {

      const archivo = `${datos.ctto_pnat_rut}_${datos.ctto_ctto_id}_${datos.ctto_ctto_nro_interno}.${KV._CTTO_ADJUNTO_EXTE_CTTO}`;
  
      fetchDescargarContrato (servContratoDescargar, datos.ctto_ctto_id, 0, archivo, ambiente)
      .then ( resp => { 
        if (resp.code !== 0) { 
          toast({
            title: KContratos._SC_MSJE_TITULO_FRACASO,
            description: `${KContratos._SC_MSJE_DESCARGAR_FRACASO} [a]`,
            status: 'error',
            duration: K._DURACION_TOAST_DEFAULT,
            isClosable: true,
          })
        }
        else {
          toast({
            title: KContratos._SC_MSJE_TITULO_EXITO,
            description: `${KContratos._SC_MSJE_DESCARGAR_EXITO} [${archivo}]`,
            status: 'success',
            duration: K._DURACION_TOAST_DEFAULT,
            isClosable: true,
          })
        }
    })
    .catch ( (error) => {
      toast({
        title: KContratos._SC_MSJE_TITULO_FRACASO,
        description: `${KContratos._SC_MSJE_DESCARGAR_FRACASO} [b]`,
        status: 'error',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })
    })    
  }

  return(
    cargando
    ?
    <Button isLoading={cargando} loadingText={K._CARGANDO} colorScheme='teal' variant='outline' />
    :
    <Container 
        py={{ base: '1', md: '2' }} 
        px={{ base: '1', md: '4' }} 
        maxW='4xl' 
        bg={colorBG}
    >
      <VStack spacing="3">

        <HStack spacing={'3'}>
          <Text color="blue" fontSize={'lg'}>{KContratos._F_LINK_DESCARGA_GLOSA}</Text>

          <Button 
            colorScheme='blue' 
            variant='outline' 
            type="button"
            fontSize={'sm'}
            leftIcon={<IconDescargar4 />}
            onClick={onClickDescargar}
            id={`${datos.ctto_ctto_id}|${datos.ctto_ctto_id}`} 
          >
            {KContratos._SC_BOTON_DESCARGAR_REVISION}
          </Button>
        </HStack>

      <Divider h={'4'}/>

      <div key='planilla'
        dangerouslySetInnerHTML={{__html: plantilla }} 
      />
      </VStack>
    </Container>

    )
}

export default ContratoPreliminar;
